<template>
      <div class="project-card-container"
           :class="{
              'software' : servicesInfo.url === 'software',
              'protocols' : servicesInfo.url === 'protocols',
              'db' : servicesInfo.url === 'databases',
              'wallets' : servicesInfo.url === 'wallets',
              'smart-contracts' : servicesInfo.url === 'smart-contracts',
              'hardware' : servicesInfo.url === 'hardware',
              'frameworks' : servicesInfo.url === 'frameworks',
              'language' : servicesInfo.url === 'languages'
           }" v-if="servicesInfo.url !== 'dlt'">
        <div class="image-container" v-for="item in servicesInfo.description" :key="item.id">
          <transition name="fade-opacity-1">
          <vue-load-image  v-if="isVisible(item.id)">
            <template v-slot:image>
              <img class="project-img" v-if="!item.activeTheme || !activeColor" :src="getUrlProgramming(item.img + '.svg')" alt="project-img"/>
              <img class="project-img" v-if="item.activeTheme && activeColor" :src="getUrlProgramming(item.img + '-dark.svg')" alt="project-img"/>
            </template>
            <template v-slot:preloader>
              <img class="loader-img" :src="getUrl('preloader.gif')" alt="load"/>
            </template>
            <template v-slot:error>Image load fails</template>
          </vue-load-image>
          </transition>
        </div>
      </div>

      <div class="project-card-container dlt" v-else>
        <div class="content one">
          <div class="image-container one" v-for="item in servicesInfo.description.slice(0,3)" :key="item.id">
            <transition name="fade-opacity-1">
              <vue-load-image v-if="isVisible(item.id)">
                <template v-slot:image>
                  <img class="project-img" v-if="!item.activeTheme || !activeColor" :src="getUrlProgramming(item.img + '.svg')" alt="project-img"/>
                  <img class="project-img" v-if="item.activeTheme && activeColor" :src="getUrlProgramming(item.img + '-dark.svg')" alt="project-img"/>
  <!--                <img class="project-img" :class="item.img === 'Hyperledger.svg' || item.img === 'Corda.svg' ? 'width' : ''"  :src="getUrlProgramming(item.img)" alt="project-img"/>-->
                </template>
                <template v-slot:preloader>
                  <img class="loader-img" :src="getUrl('preloader.gif')" alt="load"/>
                </template>
                <template v-slot:error>Image load fails</template>
              </vue-load-image>
            </transition>
          </div>
        </div>
        <div class="content two">
          <div class="image-container two" v-for="item in servicesInfo.description.slice(3,servicesInfo.description.length)" :key="item.id">
            <transition name="fade-opacity-1">
              <vue-load-image v-if="isVisible(item.id)">
                <template v-slot:image>
                  <img class="project-img" v-if="!item.activeTheme || !activeColor" :src="getUrlProgramming(item.img + '.svg')" alt="project-img"/>
                  <img class="project-img" v-if="item.activeTheme && activeColor" :src="getUrlProgramming(item.img + '-dark.svg')" alt="project-img"/>
                </template>
                <template v-slot:preloader>
                  <img class="loader-img" :src="getUrl('preloader.gif')" alt="load"/>
                </template>
                <template v-slot:error>Image load fails</template>
              </vue-load-image>
            </transition>
          </div>
        </div>
      </div>
</template>

<script>
import VueLoadImage from "vue-load-image";

export default {
  name: "CardTechnologies",
  components: {VueLoadImage},
  props: {
    servicesInfo: {
      type: Object,
      required: true
    },
    typeDivision: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentItem: -1
    }
  },

  mounted() {
    this.showNextItem();
  },
  watch: {
    // servicesInfo() {
    //   setTimeout(() => {
    //     this.currentItem = -1;
    //     this.showNextItem();
    //   }, 500)
    // }
  },
  methods: {
    getUrlProgramming(picture) {
      return require('@/assets/division-page/' + this.typeDivision + '-division/' + this.servicesInfo.id + '/' + picture)
    },
    showNextItem() {
      setTimeout(() => {
        if (this.currentItem < this.servicesInfo.description.length) {
          this.currentItem++
          this.showNextItem()
        }
      }, 200)
    },

    isVisible(index) {
      return index <= this.currentItem
    }
  }
}
</script>

<style scoped lang="scss">


  .project-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;

    .image-container {
      display: flex;
      position: relative;
      margin: auto;
      justify-content: center;
      .loader-img {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        margin: auto;
      }
    }

    &.language {
      .image-container {
        &:nth-child(3) {
          .vue-load-image {
            margin: 0 0 auto;
          }
        }

        &:nth-last-child(-n + 3) {
          .vue-load-image {
            margin: auto 0 0;
          }
        }
      }
    }

    &.hardware {
      .image-container {
        &:nth-child(-n + 2) {
          .vue-load-image {
            margin: 0 0 auto;
          }
        }

        &:nth-last-child(-n + 2) {
          .vue-load-image {
            margin: auto 0 0;
          }
        }
      }
    }


    &.frameworks {
      .image-container {
        &:nth-child(-n + 2) {
          .vue-load-image {
            margin: 0 0 auto;
          }
        }

        &:nth-last-child(-n + 3) {
          .vue-load-image {
            margin: auto 0 0;
          }
        }
      }
    }

    &.db {
      justify-content: center;
      .image-container {
        &:first-child {
          .vue-load-image {
            margin: 0 0 auto;
          }
        }

        &:last-child {
          .vue-load-image {
            margin: auto 0 0;
          }
        }
      }
    }

    &.protocols {
      justify-content: center;
      .image-container {

        &:first-child {
          .vue-load-image {
            margin: 0 0 auto;
          }
        }

        &:nth-child(3) {
          .vue-load-image {
            margin: auto 0 0;
          }
        }

        &:last-child {
          .vue-load-image {
            margin: auto 0 0;
          }
        }
      }
    }

    &.software {
      justify-content: center;
      .image-container {

        &:first-child {
          .vue-load-image {
            margin: 0 0 auto;
          }
        }
        &:last-child {
          .vue-load-image {
            margin: auto 0 0;
          }
        }
      }
    }

    &.wallets {
      justify-content: center;
      .image-container {
        &:first-child {
          .vue-load-image {
            margin: 0 0 auto;
          }
        }

        &:last-child {
          .vue-load-image {
            margin: auto 0 0;
          }
        }
      }
    }
    &.smart-contracts {
      justify-content: center;

      .image-container {
        &:first-child {
          .vue-load-image {
            margin: 0 0 auto;
          }
        }

        &:nth-child(2) {
          .vue-load-image {
            margin: 0 0 auto;
          }
        }

        &:last-child {
          .vue-load-image {
            margin: auto 0 0;
          }
        }
      }
    }




    &.dlt {
      flex-wrap: nowrap;
      flex-direction: row;
      align-content: space-between;
      justify-content: center;

      .content {
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        align-content: center;
        flex-direction: column;

        .image-container {
          &:first-child {
            .vue-load-image {
              margin: 0 0 auto;
            }
          }
          &:last-child {
            .vue-load-image {
              margin: auto 0 0;
            }
          }
        }

        .image-container {
          flex-basis: auto;

          &.two {
            justify-content: flex-end;
          }

          .project-img {
            margin: auto;
            top: 0; left: 0; right: 0;
          }
        }
      }
    }
  }

  @media (max-width: 3024px) {
    .project-card-container {
      max-width: 900px;
      flex-grow: 2;
      padding: 64px 128px 64px 0;

      .vue-load-image {
        margin: auto 0;
      }

      .image-container {
        flex-basis: 33.33%;
        height: 25%;

        .loader-img {
          width: 50px;
          height: 50px;
        }

        .project-img {
          width: 400px;
          max-height: 309px;
        }

        &.width {
          flex-basis: 50%;

          .project-img {
            width: 340px;
          }
        }
      }

      &.frameworks {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;

          .loader-img {
            width: 50px;
            height: 50px;
          }

          &:nth-child(-n + 2) {
            .project-img {
              width: 320px;
              max-height: 135px;
            }
          }

          .project-img {
            width: 160px;
            max-height: 202px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.language {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;

          .loader-img {
            width: 50px;
            height: 50px;
          }

          .project-img {
            width: 130px;
            max-height: 240px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.db {

        .image-container {
          flex-basis: 100%;

          .project-img {
            width: 485px;
          }
        }
      }

      &.protocols {
        .image-container {
          flex-basis: 100%;
          height: 25%;

          .project-img {
            max-height: 170px;
            max-width: 290px;
            width: 100%;
          }
        }
      }

      &.software {
        .image-container {
          flex-basis: 100%;
          height: 15%;

          .project-img {
            width: 325px
          }
        }
      }

      &.wallets {

        .image-container {
          flex-basis: 100%;

          .project-img {
            max-height: 168px;
            max-width: 150px;
            width: 100%;
          }
        }
      }

      &.smart-contracts {

        .image-container {
          margin: 0;
          flex-basis: 50%;
          height: 33.33%;

          .project-img {
            max-height: 150px;
            max-width: 560px;
            width: 100%;
          }

          &:nth-child(n+3) {
            flex-basis: 33.33%;
          }

          &:nth-child(n+6) {
            flex-basis: 100%;
          }
        }
      }
      &.dlt {

        .content {

          &.two {
            width: 40%;
          }

          .image-container {
            flex-basis: auto;
            height: 33%;

            &.two {
              flex-basis: 33%;
            }

            .project-img {
              max-height: 130px;
              width: 100%;
              max-width: 300px;
            }
          }
        }
      }

      &.hardware {
        .image-container {
          flex-basis: 50%;
          height: 25%;

          .project-img {
            max-width: 250px;
            max-height: 160px;
          }

          & img {
            object-fit: contain;
            height: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 1920px) {
    .project-card-container {
      max-width: 800px;
      flex-grow: 2;
      padding: 32px 64px 16px 0;

      .vue-load-image {
        margin: auto;
      }

      .image-container {
        flex-basis: 33.33%;
        height: 18%;

        .loader-img {
          width: 50px;
          height: 50px;
        }

        .project-img {
          max-width: 130px;
          max-height: 250px;
        }

        &.width {
          flex-basis: 50%;

          .project-img {
            max-width: 250px;
          }
        }
      }

      &.language {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;

          .loader-img {
            width: 50px;
            height: 50px;
          }

          .project-img {
            width: 115px;
            max-height: 240px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.frameworks {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;

          .loader-img {
            width: 50px;
            height: 50px;
          }

          &:nth-child(-n + 2) {
            .project-img {
              max-width: 250px;
              max-height: 135px;
            }
          }

          .project-img {
            width: 115px;
            max-height: 202px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.db {

        .image-container {
          flex-basis: 100%;
          .project-img {
            max-width: 390px;
          }
        }
      }

      &.protocols {
        .image-container {
          flex-basis: 100%;
          height: 25%;
          .project-img {
            max-height: 150px;
            max-width: 270px;
          }
        }
      }

      &.software {
        .image-container {
          flex-basis: 100%;
          height: 20%;
          .project-img {
            max-width: 300px;
            width: 100%;
          }
        }
      }

      &.wallets {

        .image-container {
          flex-basis: 100%;
          .project-img {
            max-height: 125px;
            max-width: 150px;
          }
        }
      }
      &.smart-contracts {

        .image-container {
          margin: 0;
          flex-basis: 50%;
          height: 33.33%;

          .project-img {
            max-height: 120px;
            max-width: 540px;
            width: 100%;
          }

          &:nth-child(n+3) {
            flex-basis: 33.33%;
          }

          &:nth-child(n+6) {
            flex-basis: 100%;

            .vue-load-image {
              margin-top: auto;
            }
          }
        }
      }

      &.hardware {
        .image-container {
          flex-basis: 50%;
          height: 25%;

          .project-img {
            max-width: 200px;
            max-height: 120px;
          }

          & img {
            object-fit: contain;
            height: 100%;
          }
        }
      }


      &.dlt {

        .content {

          &.two {
            width: 40%;
          }

          .image-container {
            flex-basis: auto;
            height: 33%;

            &.two {
              flex-basis: 33%;
            }

            &.one {
              &:first-child {
                .project-img {
                  max-width: 250px;
                }
              }
            }

            .project-img {
              max-height: 108px;
              max-width: 300px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .project-card-container {
      max-width: 560px;
      flex-grow: 2;
      padding: 32px 64px 32px 0;

      .vue-load-image {
        margin: auto;
      }

      .image-container {
        flex-basis: 33.33%;
        height: 18%;

        .loader-img {
          width: 50px;
          height: 50px;
        }

        .project-img {
          width: 100px;
          max-height: 180px;
        }

        &.width {
          flex-basis: 50%;

          .project-img {
            max-width: 200px;
          }
        }
      }

      &.language {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;
          &:last-child {
            .project-img {
              width: 100px;
            }
          }

          .loader-img {
            width: 50px;
            height: 50px;
          }

          .project-img {
            width: 85px;
            max-height: 150px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.frameworks {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;

          .loader-img {
            width: 50px;
            height: 50px;
          }

          &:nth-child(-n + 2) {
            .project-img {
              max-width: 215px;
              max-height: 75px;
            }
          }

          .project-img {
            width: 96px;
            max-height: 150px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }


      &.db {

        .image-container {
          flex-basis: 100%;
          .project-img {
            max-width: 355px;
          }
        }
      }

      &.protocols {
        .image-container {
          flex-basis: 100%;
          height: 25%;
          .project-img {
            max-height: 125px;
            max-width: 200px;
          }
        }
      }

      &.software {
        .image-container {
          flex-basis: 100%;
          height: 20%;
          .project-img {
            max-width: 275px
          }
        }
      }

      &.wallets {
        .image-container {
          flex-basis: 100%;
          .project-img {
            max-height: 110px;
            max-width: 110px;
          }
        }
      }

      &.smart-contracts {

        .image-container {
          margin: 0;
          flex-basis: 50%;
          height: 33.33%;

          .project-img {
            max-height: 95px;
            max-width: 393px;
            width: 100%;
          }

          &:nth-child(n+3) {
            flex-basis: 33.33%;
          }

          &:nth-child(n+6) {
            flex-basis: 100%;
          }
        }
      }

      &.hardware {
        .image-container {
          flex-basis: 50%;
          height: 25%;

          .project-img {
            width: 100%;
            max-width: 200px;
            max-height: 100px;
          }

          & img {
            object-fit: contain;
            height: 100%;
          }
        }
      }


      &.dlt {

        .content {

          &.two {
            width: 40%;
          }

          .image-container {
            flex-basis: auto;
            height: 33%;

            &.two {
              flex-basis: 33%;
            }

            &.one {
              &:first-child {
                .project-img {
                  max-width: 200px;
                }
              }
            }

            .project-img {
              max-height: 85px;
              width: 100%;
              height: 100%;
              max-width: 295px
            }
          }
        }
      }
    }
  }

  @media (max-height: 800px) {
    .project-card-container {
      max-width: 500px;
      flex-grow: 2;
      padding: 24px 48px 24px 0;


      .vue-load-image {
        margin: auto;
      }

      .image-container {
        flex-basis: 33.33%;
        height: 18%;

        .loader-img {
          width: 30px;
          height: 30px;
        }

        .project-img {
          width: 60px;
          max-height: 110px;
        }

        &.width {
          flex-basis: 50%;

          .project-img {
            max-width: 140px;
          }
        }
      }

      &.language {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;
          &:last-child {
            .project-img {
              width: 100px;
            }
          }

          .loader-img {
            width: 50px;
            height: 50px;
          }

          .project-img {
            width: 68px;
            max-height: 120px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.frameworks {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;

          .loader-img {
            width: 50px;
            height: 50px;
          }

          &:nth-child(-n + 2) {
            .project-img {
              max-width: 180px;
              max-height: 70px;
            }
          }

          .project-img {
            width: 75px;
            max-height: 150px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.db {

        .image-container {
          flex-basis: 100%;
          .project-img {
            max-width: 250px;
          }
        }
      }

      &.protocols {
        .image-container {
          flex-basis: 100%;
          height: 25%;
          .project-img {
            max-height: 85px;
            max-width: 180px;
          }
        }
      }

      &.software {
        .image-container {
          flex-basis: 100%;
          height: 20%;
          .project-img {
            max-width: 250px
          }
        }
      }

      &.wallets {

        .image-container {
          flex-basis: 100%;
          .project-img {
            max-height: 90px;
            max-width: 90px;
          }
        }
      }
      &.smart-contracts {

        .image-container {
          margin: 0;
          flex-basis: 50%;
          height: 35%;

          .project-img {
            max-height: 76px;
            max-width: 300px;
            width: 100%;
          }

          &:nth-child(n+3) {
            flex-basis: 33.33%;
          }

          &:nth-child(n+6) {
            height: 25%;
            flex-basis: 100%;
          }
        }
      }

      &.hardware {
        .image-container {
          flex-basis: 50%;
          height: 25%;

          .project-img {
            width: 100%;
            max-width: 150px;
            max-height: 75px;
          }

          & img {
            object-fit: contain;
            height: 100%;
          }
        }
      }


      &.dlt {

        .content {

          &.two {
            width: 40%;
          }

          .image-container {
            flex-basis: auto;
            height: 33%;

            &.two {
              flex-basis: 33%;
            }

            &.one {
              &:first-child {
                .project-img {
                  max-width: 170px;
                }
              }
            }

            .project-img {
              max-height: 64px;
              width: 100%;
              height: 100%;
              max-width: 195px;

              &.width {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .project-card-container {
      max-width: 560px;
      flex-grow: 2;
      padding: 32px 64px 32px 0;

      .vue-load-image {
        margin: auto;
      }

      .image-container {
        flex-basis: 33.33%;
        height: 18%;

        .loader-img {
          width: 50px;
          height: 50px;
        }

        .project-img {
          width: 100px;
          max-height: 180px;
        }

        &.width {
          flex-basis: 50%;

          .project-img {
            max-width: 200px;
          }
        }
      }

      &.language {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;
          &:last-child {
            .project-img {
              width: 100px;
            }
          }

          .loader-img {
            width: 50px;
            height: 50px;
          }

          .project-img {
            width: 85px;
            max-height: 150px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.frameworks {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;

          .loader-img {
            width: 50px;
            height: 50px;
          }

          &:nth-child(-n + 2) {
            .project-img {
              max-width: 215px;
              max-height: 75px;
            }
          }

          .project-img {
            width: 96px;
            max-height: 150px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }


      &.db {

        .image-container {
          flex-basis: 100%;
          .project-img {
            max-width: 355px;
          }
        }
      }

      &.protocols {
        .image-container {
          flex-basis: 100%;
          height: 25%;
          .project-img {
            max-height: 125px;
            max-width: 200px;
          }
        }
      }

      &.software {
        .image-container {
          flex-basis: 100%;
          height: 20%;
          .project-img {
            max-width: 275px
          }
        }
      }

      &.wallets {
        .image-container {
          flex-basis: 100%;
          .project-img {
            max-height: 110px;
            max-width: 110px;
          }
        }
      }

      &.smart-contracts {

        .image-container {
          margin: 0;
          flex-basis: 50%;
          height: 33.33%;

          .project-img {
            max-height: 95px;
            max-width: 393px;
            width: 100%;
          }

          &:nth-child(n+3) {
            flex-basis: 33.33%;
          }

          &:nth-child(n+6) {
            flex-basis: 100%;
          }
        }
      }

      &.hardware {
        .image-container {
          flex-basis: 50%;
          height: 25%;

          .project-img {
            width: 100%;
            max-width: 200px;
            max-height: 100px;
          }

          & img {
            object-fit: contain;
            height: 100%;
          }
        }
      }


      &.dlt {

        .content {

          &.two {
            width: 40%;
          }

          .image-container {
            flex-basis: auto;
            height: 33%;

            &.two {
              flex-basis: 33%;
            }

            &.one {
              &:first-child {
                .project-img {
                  max-width: 200px;
                }
              }
            }

            .project-img {
              max-height: 85px;
              width: 100%;
              height: 100%;
              max-width: 295px
            }
          }
        }
      }
    }
}

  @media (max-width: 1133px) {
    .project-card-container {
      max-width: 500px;
      flex-grow: 2;
      padding: 24px 48px 24px 0;


      .vue-load-image {
        margin: auto;
      }

      .image-container {
        flex-basis: 33.33%;
        height: 18%;

        .loader-img {
          width: 30px;
          height: 30px;
        }

        .project-img {
          width: 60px;
          max-height: 110px;
        }

        &.width {
          flex-basis: 50%;

          .project-img {
            max-width: 140px;
          }
        }
      }

      &.language {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;
          &:last-child {
            .project-img {
              width: 100px;
            }
          }

          .loader-img {
            width: 50px;
            height: 50px;
          }

          .project-img {
            width: 68px;
            max-height: 120px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.frameworks {
        .image-container {
          flex-basis: 33.33%;
          height: 25%;

          .loader-img {
            width: 50px;
            height: 50px;
          }

          &:nth-child(-n + 2) {
            .project-img {
              max-width: 180px;
              max-height: 70px;
            }
          }

          .project-img {
            width: 75px;
            max-height: 150px;
          }

          &.width {
            flex-basis: 50%;

            .project-img {
              width: 340px;
            }
          }
        }
      }

      &.db {

        .image-container {
          flex-basis: 100%;
          .project-img {
            max-width: 250px;
          }
        }
      }

      &.protocols {
        .image-container {
          flex-basis: 100%;
          height: 25%;
          .project-img {
            max-height: 85px;
            max-width: 180px;
          }
        }
      }

      &.software {
        .image-container {
          flex-basis: 100%;
          height: 20%;
          .project-img {
            max-width: 250px
          }
        }
      }

      &.wallets {

        .image-container {
          flex-basis: 100%;
          .project-img {
            max-height: 90px;
            max-width: 90px;
          }
        }
      }
      &.smart-contracts {

        .image-container {
          margin: 0;
          flex-basis: 50%;
          height: 35%;

          .project-img {
            max-height: 76px;
            max-width: 300px;
            width: 100%;
          }

          &:nth-child(n+3) {
            flex-basis: 33.33%;
          }

          &:nth-child(n+6) {
            height: 25%;
            flex-basis: 100%;
          }
        }
      }

      &.hardware {
        .image-container {
          flex-basis: 50%;
          height: 25%;

          .project-img {
            width: 100%;
            max-width: 150px;
            max-height: 75px;
          }

          & img {
            object-fit: contain;
            height: 100%;
          }
        }
      }


      &.dlt {

        .content {

          &.two {
            width: 40%;
          }

          .image-container {
            flex-basis: auto;
            height: 33%;

            &.two {
              flex-basis: 33%;
            }

            &.one {
              &:first-child {
                .project-img {
                  max-width: 170px;
                }
              }
            }

            .project-img {
              max-height: 64px;
              width: 100%;
              height: 100%;
              max-width: 195px;

              &.width {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    //iPhone 14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //iPhone Plus
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    //iPhone SE
  }



  @media (min-width: 3025px) {
    .project-card-container {
      max-width: 1500px;
      flex-grow: 2;
      padding: 128px 256px 128px 0;

      .vue-load-image {
        margin: auto 0;
      }

      .image-container {
        flex-basis: 33.33%;
        height: 25%;

        .loader-img {
          width: 50px;
          height: 50px;
        }

        .project-img {
          max-height: 587px;
        }

        &.width {
          flex-basis: 50%;
        }
      }

      &.db {

        .image-container {
          flex-basis: 100%;

          .project-img {
            width: 830px;
          }
        }
      }

      &.protocols {
        .image-container {
          flex-basis: 100%;
          height: 25%;

          .project-img {
            max-width: 587px;
            max-height: 650px;
            width: 100%;
          }
        }
      }

      &.software {
        .image-container {
          flex-basis: 100%;
          height: 20%;

          .project-img {
            width: 750px;
          }
        }
      }

      &.wallets {

        .image-container {
          flex-basis: 100%;

          .project-img {
            max-height: 300px;
            max-width: 300px;
          }
        }
      }

      &.smart-contracts {

        .image-container {
          margin: 0;
          flex-basis: 50%;
          height: 33.33%;

          .project-img {
            max-height: 525px;
            max-width: 950px;
            width: 100%;
          }

          &:nth-child(n+3) {
            flex-basis: 33.33%;
          }

          &:nth-child(n+6) {
            flex-basis: 100%;
          }
        }
      }

      &.hardware {
        .image-container {
          flex-basis: 50%;
          height: 25%;

          .project-img {
            max-height: 319px;
          }

          & img {
            height: 100%;
          }


        }
      }


      &.dlt {

        .content {

          &.two {
            width: 40%;
          }

          .image-container {
            flex-basis: auto;
            height: 33%;

            &.two {
              flex-basis: 33%;
            }

            .project-img {
              max-height: 290px;
              width: 100%;
              max-width: 720px;
            }
          }
        }
      }
    }
  }


</style>