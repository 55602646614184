<template>
      <transition name="fade-translate-x" mode="out-in">
        <div class="project-card-container" v-if="activeAnimation" :class="
        {
          'software' : activeInfo.url === 'software',
          'protocol' : activeInfo.url === 'protocols',
          'db' : activeInfo.url === 'databases',
          'fraim-pay' : activeInfo.url === 'wallets',
          'smart-contract' : activeInfo.url === 'smart-contracts',
          'platform' : activeInfo.url === 'platform',
          'equipment' : activeInfo.url === 'hardware',
          'fraim' : activeInfo.url === 'frameworks'
        }">
          <swiper @slide-change="onSwiperMain"
                  @swiper="changeSettingSwiper"
                  :slides-per-view="activeView"
                  :allow-slide-next="true"
                  :autoplay="{
                     delay: 1500,
                     disableOnInteraction: false
                  }"
                  :modules="modules"
                  class="image-container"
                  direction="horizontal"
                  :space-between="32"
                  :speed="2000"
                  :loop="true"
                  :allow-touch-move="true">
            <swiper-slide style="display: flex; align-items: center; justify-content: center"
                          class="logo-content"
                          v-for="item in activeInfo.description" :key="item.id">
              <transition name="fade-opacity-lazy">
                <vue-load-image class="project-img"  v-if="isVisible(item.id)">
                  <template v-slot:image>
                    <img class="project-img" v-if="!item.activeTheme || !activeColor" :src="getUrlProgramming(item.img + '.svg')" alt="project-img"/>
                    <img class="project-img" v-if="item.activeTheme && activeColor" :src="getUrlProgramming(item.img + '-dark.svg')" alt="project-img"/>
                  </template>
                  <template v-slot:preloader>
                    <img class="loader-img" :src="getUrl('preloader.gif')" alt="load"/>
                  </template>
                  <template v-slot:error>Image load fails</template>
                </vue-load-image>
              </transition>
            </swiper-slide>
        </swiper>
        </div>
    </transition>
</template>

<script>
import VueLoadImage from "vue-load-image";
import {Autoplay} from "swiper";

export default {
  name: "CardTechnologiesMobile",
  components: {VueLoadImage},
  props: {
    activeInfo: {
      type: Object,
      required: true
    },
    typeDivision: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentItem: -1,
      mainSwiper: '',
      activeMainSlide: 0,
      activeAnimation: false,
      modules: [Autoplay]
    }
  },

  mounted() {
    this.activeAnimation = true
    this.showNextItem();
  },
  computed: {

    activeView() {
      if(this.activeInfo.url === 'software') {
        return 2;
      } else if (this.activeInfo.url === 'protocols') {
        return 2;
      } else if (this.activeInfo.url === 'databases') {
        return 1;
      } else if (this.activeInfo.url === 'wallets') {
        return 2;
      } else if (this.activeInfo.url === 'smart-contracts') {
        return 2;
      } else if (this.activeInfo.url === 'dlt') {
        return 2;
      } else if (this.activeInfo.url === 'hardware') {
        return 2;
      } else if (this.activeInfo.url === 'frameworks' ) {
          return 2;
      } else {
        return 2;
      }
    }
  },
  methods: {
    getUrlProgramming(picture) {
      return require('@/assets/division-page/' + this.typeDivision + '-division/' + this.activeInfo.id + '/' + picture)
    },

    changeSettingSwiper(swiper) {
      this.mainSwiper = swiper
    },
    onSwiperMain(swiper) {
      this.activeMainSlide = swiper.activeIndex;
    },

    showNextItem() {
      let time;
      if(this.activeMobile) {
        time = 500;
      } else {
        time = 200;
      }
      setTimeout(() => {
        if (this.currentItem < this.activeInfo.description.length) {
          this.currentItem++
          this.showNextItem()
        }
      }, time)
    },

    isVisible(index) {
      return index <= this.currentItem
    }
  }
}
</script>

<style scoped lang="scss">

.swiper {
  width: 100%;
}

.swiper-slide {
  width: 100%;
}




img {
  object-fit: fill;
}

.project-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 100%;
  justify-items: center;


  &::-webkit-scrollbar {
    display: none;
  }

  .image-container {
    padding: 0 var(--controller-padding);
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;

    &:after {
      visibility: visible;
      content: "";
      position: absolute;
      width: 10%;
      height: 100%;
      z-index: 2;
      bottom: 0;
      right: 0;
      pointer-events: none;
      background: linear-gradient(90deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 90%);
    }

    &:before {
      visibility: visible;
      content: "";
      position: absolute;
      width: 10%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
      pointer-events: none;
      background: linear-gradient(270deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 90%);
    }

    .project-img {
      width: 100%;
    }

    .loader-img {
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      margin: auto;
    }

  }
}

@media (max-width: 769px){
  .swiper-slide {
    height: 132px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .project-card-container {
    height: 132px;
    .vue-load-image {
      //margin: auto;
      width: 100%;
    }

    .image-container {
      height: 125px;
      .loader-img {
        width: 25px;
        height: 25px;
      }
      .project-img {
        max-width: 120px;
        max-height: 96px
      }
    }

    &.db {

      .image-container {
        .project-img {
          max-width: 232px;
          max-height: 100px;
        }
      }
    }

    &.protocol {
      .image-container {
        .project-img {
          max-height: 100px;
        }
      }
    }

    &.software {
      .image-container {
        .project-img {
        }
      }
    }

    &.fraim-pay {

      .image-container {
        .project-img {
          max-height: 100px;
        }
      }
    }

    &.smart-contract {

      .image-container {

        .project-img {
          max-height: 100px;
          max-width: 150px;
        }
      }
    }

    &.equipment {
      .image-container {

        .project-img {
          max-height: 100px;
        }
      }
    }

    &.fraim {
      .image-container {

        .project-img {
          max-height: 100px;
        }
      }
    }


    &.platform {
      .image-container {

        .project-img {
          max-height: 100px;
          //max-width: 450px;

          &.width {
            //height: 50px;
          }
        }
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}


</style>