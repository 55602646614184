<template>
  <transition name="fade-opacity-lazy">
    <div class="navigation-screen-container" v-if="activeScreen">
      <transition name="fade-opacity-lazy">
        <modal-login v-if="activeModal"
                     :active-title="{
                      type: 'library',
                      mainTitle: $t('modalLoginLibrary.mainTitle'),
                      inputInfo: $tm('modalLoginLibrary.inputInfo'),
                   }"
                     @closeModal="changeActiveModal"/>
      </transition>
      <div class="left-info-container">
        <div class="top-info-container">
          <medium-title>
            {{ $t('navigationScreen.mainTitle') }}
          </medium-title>
          <main-paragraph :class="{'eng' : activeEng}">
            {{ $t('navigationScreen.subTitle') }}
          </main-paragraph>
        </div>


        <div class="bottom-info-container">
          <button-with-icon v-for="item in $tm('navigationScreen.buttonNavigationInfo')"
                            @click="changeActivePageButton(item.id)"
                            :info-icon="'navigation/' + item.icon"
                            :info-text="item.mainTitle"
                            :key="item.id"/>
        </div>
      </div>
      <div class="right-info-container">
        <card-navigation v-for="item in $tm('navigationScreen.navigationInfo')"
                         @changeActivePage="changeActivePage"
                         :card-info="item"/>
      </div>
    </div>
  </transition>
</template>

<script>
import CardNavigation from "@/components/entities/CardNavigation.vue";
import MediumTitle from "@/components/shared/Text/MediumTitle.vue";
import ButtonWithIcon from "@/components/shared/ButtonWithIcon.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import {mapActions} from "vuex";
import ModalLogin from "@/components/entities/ModalLogin.vue";

export default {
  name: "NavigationScreen",
  components: {ModalLogin, MainParagraph, ButtonWithIcon, MediumTitle, CardNavigation},
  data() {
    return {
      activeModal: false,
      activeScreen: false
    }
  },
  mounted() {
    this.updateBlock();
    setTimeout(() => {
      this.activeScreen = true
    }, 500)
  },
  methods: {
    ...mapActions(['ACTIVE_INDEX_PAGE', 'ACTIVE_MAIN_INDEX', 'ACTIVE_SUB_INDEX']),
    changeActiveModal() {
      this.activeModal = !this.activeModal
    },

    updateBlock() {
      this.$emit('blockNextSlide', false)
      this.$emit('blockPrevSlide', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .navigation-screen-container {
    display: flex;
    flex-direction: row;
    width: var(--calc-width);
    height: var(--calc-height);
    align-items: center;
    padding-left: var(--controller-padding);
    justify-content: space-between;

    .right-info-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .left-info-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 100%;

      .top-info-container {
        display: flex;
        flex-direction: column;

        .medium-title-container {
          color: var(--color-main-title)
        }
      }

      .bottom-info-container {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (max-width: 3024px){
    .navigation-screen-container {
      padding-right: 160px;
      column-gap: 56px;

      .right-info-container {
        max-width: 900px;
        row-gap: 32px;
      }

      .left-info-container {
        margin-left: 128px;
        max-width: 590px;

        .top-info-container {
          row-gap: 48px;
        }

        .bottom-info-container {
          row-gap: 24px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .navigation-screen-container {
      padding-right: 128px;
      column-gap: 32px;

      .right-info-container {
        max-width: 740px;
        row-gap: 32px;
      }

      .left-info-container {
        max-width: 505px;

        .top-info-container {
          row-gap: 40px;
        }

        .bottom-info-container {
          row-gap: 18px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .navigation-screen-container {
      padding-right: 80px;

      .right-info-container {
        max-width: 570px;
        row-gap: 24px;
      }

      .left-info-container {
        margin-left: 64px;
        max-width: 338px;

        .top-info-container {
          row-gap: 32px;
        }

        .bottom-info-container {
          row-gap: 16px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .navigation-screen-container {
      padding-right: 80px;

      .right-info-container {
        max-width: 480px;
        row-gap: 16px;
      }

      .left-info-container {
        max-width: 295px;
        margin-left: 56px;

        .top-info-container {
          row-gap: 20px;
        }

        .bottom-info-container {
          row-gap: 12px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .navigation-screen-container {
      padding-right: 80px;

      .right-info-container {
        max-width: 570px;
        row-gap: 24px;
      }

      .left-info-container {
        max-width: 338px;
        margin-left: 64px;

        .top-info-container {
          row-gap: 32px;
        }

        .bottom-info-container {
          row-gap: 16px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .navigation-screen-container {
      padding-right: 80px;

      .right-info-container {
        max-width: 480px;
        row-gap: 16px;
      }

      .left-info-container {
        max-width: 295px;
        margin-left: 56px;

        .top-info-container {
          row-gap: 20px;
        }

        .bottom-info-container {
          row-gap: 12px;
        }
      }
    }
  }
  @media (max-width: 769px) {
    //iPhone 14
  }

  @media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
    //iPhone Plus
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .navigation-screen-container {
      padding-right: 256px;
      column-gap: 64px;

      .right-info-container {
        max-width: 1500px;
        row-gap: 64px;
      }

      .left-info-container {
        margin-left: 256px;
        max-width: 1010px;

        .top-info-container {
          row-gap: 84px;
        }

        .bottom-info-container {
          row-gap: 32px;
        }
      }
    }
  }

</style>