import {mapActions} from "vuex";
import i18n from "@/locales/i18n-config";

export default {
    methods: {

        ...mapActions(['ACTIVE_INDEX_PAGE', 'ACTIVE_MAIN_INDEX', 'ACTIVE_SUB_INDEX', 'ACTIVE_HOME_INDEX', 'ACTIVE_BLOCK_SWIPER']),

        getUrl(picture) {
            return require('../assets/' + picture)
        },
        getUrlIcons(picture) {
            return require('../assets/icons/' + picture)
        },

        getUrlProject(picture) {
            return require('../assets/project-screen/' + picture)
        },

        getUrlPartners(picture) {
            return require('../assets/partners-screen/' + picture)
        },

        getUrlLogo(picture) {
            return require('../assets/logo/' + picture)
        },

        getUrlFooter(picture) {
            return require('../assets/footer/' + picture)
        },

        getUrlDivision(picture) {
            return require('../assets/division-screen/' + picture)
        },

        getUrlDivisionPage(picture) {
            return require('../assets/division-page/' + picture)
        },

        getUrlTeam(picture) {
            return require('../assets/team/' + picture)
        },

        getUrlHistory(picture) {
            return require('../assets/history-screen/' + picture)
        },

        getUrlMission(picture) {
            return require('../assets/mission-screen/' + picture)
        },

        getUrlPhilosophy(picture) {
            return require('../assets/philosophy-screen/' + picture)
        },

        getUrlSubbrands(picture) {
            return require('../assets/subbrands-screen/' + picture)
        },

        getUrlStrategy(picture) {
            return require('../assets/strategy-screen/' + picture)
        },
        getUrlValues(picture) {
            return require('../assets/values-screen/' + picture)
        },

        getUrlLogoBrand(picture) {
            return require('../assets/logo-brand/' + picture)
        },

        focusTrue() {
            if(!this.activeAllowTouch) {
                this.focusParam = true;
            } else {
                this.focusParam = false;
            }
        },
        focusFalse() {
            this.focusParam = false;
        },


        changeMainPageSlide(idScreen) {
            if(!this.activeMobile) {
                switch (idScreen) {
                    case 0:
                        this.ACTIVE_HOME_INDEX(0)
                        this.$router.push({name: 'home-logo-page'})
                        break;
                    case 1:
                        this.ACTIVE_HOME_INDEX(1)
                        this.$router.push({name: 'home-navigation-page'})
                        break;
                    case 2:
                        this.$router.push({name: 'main-business-page'})
                        break;
                    case 3:
                        this.$router.push({name: 'main-division-page'})
                        break;
                    case 4:
                        this.$router.push({name: 'main-management-page', params: {typeManagement: 'founders'}})
                        break;
                    case 5:
                        this.$router.push({name: 'main-project-page', params: {typeProject: 'project-division'}})
                        break;
                    case 6:
                        this.$router.push({name: 'main-partners-page'})
                        break;
                    case 7:
                        this.$router.push({name: 'main-contacts-page'})
                        break;
                }
            } else {
                switch (idScreen) {
                    case 0:
                        this.$router.push({name: 'home-logo-page'})
                        break;
                    case 1:
                        this.$router.push({name: 'main-business-page'})
                        break;
                    case 2:
                        this.$router.push({name: 'main-division-page'})
                        break;
                    case 3:
                        this.$router.push({name: 'main-management-page', params: {typeManagement: 'founders'}})
                        break;
                    case 4:
                        this.$router.push({name: 'main-project-page', params: {typeProject: 'project-division'}})
                        break;
                    case 5:
                        this.$router.push({name: 'main-partners-page'})
                        break;
                    case 6:
                        this.$router.push({name: 'main-contacts-page'})
                        break;
                }
            }

        },

        changeSubPageSlide(idScreen) {
            switch (idScreen) {
                case 0:
                    this.$router.push({name: 'brand-philosophy-page'})
                    break;
                case 1:
                    this.$router.push({name: 'brand-mission-page'})
                    break;
                case 2:
                    this.$router.push({name: 'brand-strategy-page'})
                    break;
                case 3:
                    this.$router.push({name: 'brand-values-page'})
                    break;
                case 4:
                    if(this.$route.params.typeHistory !== 'vision') {
                        this.$router.push({name: 'brand-history-page', params: {typeHistory: 'past'}})
                    }
                    break;
                case 5:
                    this.$router.push({name: 'brand-subbrands-page'})
                    break;
            }
        },
        changeInnovationPageSlide(idScreen) {
            switch (idScreen) {
                case 0:
                    this.$router.push({name: 'static-sub-innovation-page', params: {typeInnovation: 'sop'}})
                    break;
                case 1:
                    this.$router.push({name: 'static-sub-innovation-page', params: {typeInnovation: 'zet'}})
                    break;
                case 2:
                    this.$router.push({name: 'static-sub-innovation-page', params: {typeInnovation: 'vit'}})
                    break;
            }
        },


        changeActivePageButton(id) {
            if(id !== 5) {
                this.ACTIVE_INDEX_PAGE(3)
            }
            switch (id) {
                case 1:
                    this.$router.push({name: 'static-compliance-page'});
                    break;
                case 2:
                    this.$router.push({name: 'static-hr-page'});
                    break;
                case 3:
                    if(this.activeMobile) {
                        this.$router.push({name: 'static-rp-page', params: {typeNews: 'news'}});
                    } else {
                        this.$router.push({name: 'static-rp-page', params: {typeNews: 'all'}});
                    }

                    break;
                case 4:
                    this.$router.push({name: 'static-helper-ai-page'});
                    break;
                case 5:
                    if(!this.activeMobile) {
                        this.changeActiveModal();
                    } else {
                        setTimeout(() => {
                            this.changeStateModalLogin();
                        }, 500)
                    }
                    break;
            }
            this.$emit('closeBurgerMenu')
        },

        changeActivePage(idPage, idScreen) {
            switch (idPage) {
                case 0:
                    this.ACTIVE_INDEX_PAGE(0)
                    this.ACTIVE_MAIN_INDEX(idScreen)
                    this.changeMainPageSlide(idScreen)
                    break
                case 1:
                    this.ACTIVE_INDEX_PAGE(1)
                    this.ACTIVE_SUB_INDEX(idScreen)
                    this.changeSubPageSlide(idScreen)
                    break
                case 2:
                    this.ACTIVE_INDEX_PAGE(2)
                    this.changeInnovationPageSlide(idScreen)
                    break
            }
            this.$emit('closeBurgerMenu')
        },


        createMetaTag(name, content) {
            const metaTag = document.createElement('meta');
            metaTag.setAttribute('name', name);
            metaTag.setAttribute('content', content);
            document.head.appendChild(metaTag);
        },

        addInfoMeta(tag, content) {
            tag.setAttribute('content', content);
        },

        updateMetaTag(name, content) {
            const existingMetaTag = document.querySelector(`meta[name="${name}"]`);
            if (existingMetaTag) {
                if(!content) {
                    this.addInfoMeta(existingMetaTag, i18n.global.tm('defaultMetaTag')[name])
                } else {
                    this.addInfoMeta(existingMetaTag, content)
                }
            } else {
                if(!content) {
                    this.createMetaTag(name, i18n.global.tm('defaultMetaTag')[name])
                } else {
                    this.createMetaTag(name, content)
                }
            }
        },


        mouseEnterBlockSwiper() {
            if(!this.activeMobile) {
                this.ACTIVE_BLOCK_SWIPER(true)
            }
        },

        mouseEnterUnblockSwiper() {
            if(localStorage.getItem('cookieActive')) {
                this.ACTIVE_BLOCK_SWIPER(false)
            }
        }
    },

    computed: {
        activeEng() {
            return String(this.$i18n.locale) === 'en';
        },

        activeColor() {
            return this.$store.state.activeColor;
        },

        activeBlockSwiper() {
            return this.$store.state.activeBlockSwiper;
        },
        activeAllowTouch() {
            return this.$store.state.activeTouchAllow;
        },
        activeMobile() {
            return this.$store.state.activeMobile;
        }
    }
}