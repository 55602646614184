<template>
  <transition name="fade-opacity-lazy">
    <div class="secretary-screen-container sub-container" v-if="activeScreen && !activeMobile">
      <div class="left-info-content" v-if="!activeMobile">
        <div class="top-info-content">
          <transition name="fade-opacity-1">
            <main-title :active-static-eng="false" v-show="isVisible(0)">
              {{ $t('secretaryScreen.mainTitle') }}
            </main-title>
          </transition>

          <transition name="fade-opacity-1">
            <sub-title :active-static-eng="false" v-show="isVisible(1)">
              {{ $t('secretaryScreen.mainParagraph') }}
            </sub-title>
          </transition>
        </div>
        <transition name="fade-opacity-lazy">
          <div class="bottom-info-content" v-if="!activeChat">
              <card-secretary-button v-for="item in $tm('secretaryScreen.buttonInfo')"
                                     :class="{'is-visible' : isVisible(item.id + 2)}"
                                     :info-card="item"/>
          </div>
        </transition>
      </div>

      <div class="right-info-content" :class="{'active-chat' : activeChat}">
        <transition name="fade-opacity-1">
          <block-chat v-show="isVisible(5)" @startChat="startChat"/>
        </transition>
      </div>
    </div>

    <div class="secretary-screen-container sub-container" v-else-if="activeScreen && activeMobile">
      <transition name="fade-opacity-lazy">
        <button-title-mobile v-if="activeTitle"
                             :active-button="true"
                             @returnTo="routToHome"
                             :main-title="$t('secretaryScreen.mainTitle')"/>
      </transition>
      <transition name="fade-opacity-lazy" mode="out-in">
        <div class="left-info-content" v-if="activeMobile && !activeChat">
          <div class="bottom-info-content">
            <card-secretary-button v-for="item in $tm('secretaryScreen.buttonInfo')"
                                   :class="{'is-visible' : isVisible(item.id)}"
                                   :info-card="item"/>
          </div>
        </div>
      </transition>

      <div class="right-info-content mobile" :class="{'active-chat' : activeChatClass}">
          <block-chat @startChat="startChat"
                      :class="{'is-visible' : isVisible(5)}"/>
      </div>
    </div>
  </transition>
</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import CardSecretaryButton from "@/components/entities/CardSecretaryButton.vue";
import BlockChat from "@/components/entities/BlockChat.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";

export default {
  name: "SecretaryScreen",
  components: {ButtonTitleMobile, BlockChat, CardSecretaryButton, SubTitle, MainTitle},
  data() {
    return {
      activeChat: false,
      activeChatClass: false,
      activeScreen: false,
      activeTitle: false,
      activeItem: -1
    }
  },
  mounted() {
    this.activeTitle = true
    setTimeout(() => {
      this.activeScreen = true
      this.showNextItem();
    }, 500)
  },
  methods: {
    startChat() {
        this.activeChat = true
      if(!this.activeChatClass) {
        setTimeout(() => {
          this.activeChatClass = true
        }, 500)
      }
    },
    routToHome() {
      this.$router.push({name: 'home-logo-page'});
    },

    showNextItem() {
      if (this.activeItem < 5) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 350)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
  .secretary-screen-container {
    flex-direction: row;
    background: url('@/assets/1920x1080.png') 0 0/cover no-repeat;
    .left-info-content {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;

      .card-secretary-button-container {
        opacity: 0;
        transition: opacity 1s ease;

        &.is-visible {
          opacity: 1;
        }
      }

      .top-info-content {
        display: flex;
        flex-direction: column;

        .sub-title-container {
          color: var(--color-main-text);
        }
      }

      .bottom-info-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }

    .right-info-content {
      width: 100%;

      .chat-block-container {
        &.mobile {
          opacity: 0;
          transition: opacity 1s ease;

          &.is-visible {
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: 3024px){
    .secretary-screen-container {
      padding-left: 90px;
      padding-right: 64px;

      .left-info-content {

        padding-top: 64px;
        .top-info-content {
          row-gap: 8px;
          margin-bottom: 64px;
        }

        .bottom-info-content {
          max-height: 930px;
        }
      }

      .right-info-content {
        max-width: 914px;
      }
    }
  }

  @media (max-width: 1920px) {
    .secretary-screen-container {
      padding-left: 66px;
      padding-right: 48px;

      .left-info-content {

        padding-top: 48px;
        .top-info-content {
          row-gap: 8px;
          margin-bottom: 64px;
        }

        .bottom-info-content {
          max-height: 700px;
        }
      }

      .right-info-content {
        max-width: 686px;
      }
    }
  }

  @media (max-width: 1600px) {
    .secretary-screen-container {
      padding-left: 53px;
      padding-right: 32px;

      .left-info-content {
        padding-top: 32px;

        .top-info-content {
          row-gap: 8px;
          margin-bottom: 64px;
        }

        .bottom-info-content {
          max-height: 585px;
        }
      }

      .right-info-content {
        max-width: 546px;
      }
    }
  }
  @media (max-height: 800px) {
    .secretary-screen-container {
      padding-left: 38px;
      padding-right: 32px;

      .left-info-content {
        padding-top: 24px;

        .top-info-content {
          row-gap: 8px;
          margin-bottom: 64px;
        }

        .bottom-info-content {
          max-height: 475px;
        }
      }

      .right-info-content {
        max-width: 472px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .secretary-screen-container {
      padding-left: 53px;
      padding-right: 32px;

      .left-info-content {
        padding-top: 32px;

        .top-info-content {
          row-gap: 8px;
          margin-bottom: 64px;
        }

        .bottom-info-content {
          max-height: 585px;
        }
      }

      .right-info-content {
        max-width: 546px;
      }
    }
  }

  @media (max-width: 1133px) {
    .secretary-screen-container {
      padding-left: 38px;
      padding-right: 32px;

      .left-info-content {
        padding-top: 24px;

        .top-info-content {
          row-gap: 8px;
          margin-bottom: 64px;
        }

        .bottom-info-content {
          max-height: 475px;
        }
      }

      .right-info-content {
        max-width: 472px;
      }
    }
  }
  @media (max-width: 769px) {
    .secretary-screen-container {
      background: none;
      --main-padding-bottom: 16px;
      padding: var(--burger-height) 0 var(--main-padding-bottom);
      flex-direction: column;

      .main-title-container {
        padding-left: var(--controller-padding);
      }

      .left-info-content {
        position: relative;
        height: 100%;
        padding: 16px var(--controller-padding);

        .top-info-content {
          position: relative;
          height: 100%;
          row-gap: 8px;
          margin-bottom: 0;
        }

        .bottom-info-content {
          max-height: 100%;
          height: 100%;
        }
      }

      .right-info-content {
        max-width: 472px;

        &.active-chat {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .secretary-screen-container {
      --main-padding-bottom: 16px;

      .left-info-content {
        padding: 32px var(--controller-padding);

        .top-info-content {
          row-gap: 8px;
        }

      }

      .right-info-content {
        max-width: 472px;
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .secretary-screen-container {
      background: none;
      --main-padding-bottom: 8px;
      padding: var(--burger-height) 0 var(--main-padding-bottom);
      flex-direction: column;

      .main-title-container {
        padding-left: var(--controller-padding);
      }

      .left-info-content {
        position: relative;
        padding: 8px var(--controller-padding);

        .top-info-content {
          position: relative;
          row-gap: 8px;
          margin-bottom: 0;
        }

        .bottom-info-content {
          position: relative;
          max-height: 100%;
        }
      }

      .right-info-content {
        max-width: 472px;

        &.active-chat {
          height: 100%;
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .secretary-screen-container {
      background: none;
      --main-padding-bottom: 16px;
      padding: var(--burger-height) 0 var(--main-padding-bottom);
      flex-direction: column;

      .main-title-container {
        padding-left: var(--controller-padding);
      }

      .left-info-content {
        position: relative;
        padding: 16px var(--controller-padding);

        .top-info-content {
          position: relative;
          row-gap: 8px;
          margin-bottom: 0;
        }

        .bottom-info-content {
          position: relative;
          max-height: 100%;
        }
      }

      .right-info-content {
        max-width: 472px;

        &.active-chat {
          height: 100%;
        }
      }
    }
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .secretary-screen-container {
      padding-left: 124px;
      padding-right: 128px;

      .left-info-content {
        padding-top: 64px;
        .top-info-content {
          row-gap: 8px;
          margin-bottom: 180px;
        }

        .bottom-info-content {
          max-height: 1546px;
        }
      }

      .right-info-content {
        max-width: 1560px;
      }
    }
  }

</style>