<template>
  <div class="subbrand-icon-container">
      <div class="subbrand-icon-content" v-if="activeColor">
        <transition name="fade-opacity-lazy">
          <img v-if="!activeHover" :src="getUrlSubbrands(iconText.icon + '-dark.svg')" alt="icon">
          <img v-else :src="getUrlSubbrands(iconText.icon + '-dark-hover.svg')" alt="icon">
        </transition>
      </div>
    <div class="subbrand-icon-content" v-else>
      <transition name="fade-opacity-lazy">
        <img v-if="!activeHover" :src="getUrlSubbrands(iconText.icon + '-light.svg')" alt="icon">
        <img v-else :src="getUrlSubbrands(iconText.icon + '-light-hover.svg')" alt="icon">
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubbrandIcon",
  props: {
    iconText: {
      type: Object,
      required: true
    },
    focusParam: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    activeHover() {
      if(this.activeMobile) {
        return true
      } else {
        return this.focusParam;
      }
    }
   }
}
</script>

<style scoped lang="scss">
  .subbrand-icon-container {
    width: fit-content;

    .subbrand-icon-content {
      width: inherit;
      height: inherit;
      & img {
        object-position: left;
        object-fit: contain;
        width: inherit;
        height: inherit;
      }
    }


  }

  @media (max-width: 3024px){
    .subbrand-icon-container {
      height: 40px;
    }
  }


  @media (max-width: 1920px) {
    .subbrand-icon-container {
      height: 32px;
    }
  }

  @media (max-width: 1600px) {
    .subbrand-icon-container {
      width: 100%;
      max-width: 160px;
      height: 28px;
    }
  }
  @media (height: 800px) {
    .subbrand-icon-container {
      max-width: 138px;
      height: 24px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .subbrand-icon-container {
      max-width: 160px;
      height: 28px;
    }
  }

  @media (max-width: 1133px) {
    .subbrand-icon-container {
      max-width: 138px;
      height: 24px;
    }
  }
  @media (max-width: 769px) {
    .subbrand-icon-container {
      max-width: 115px;
      height: 20px;
    }
  }

  @media (min-height: 600px) and (height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .subbrand-icon-container {
      max-width: 92px;
      height: 16px;
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .subbrand-icon-container {
      max-width: 115px;
      height: 20px;
    }
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .subbrand-icon-container {
      height: 70px;
    }
  }

</style>