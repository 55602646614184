<template>
    <div class="card-services-container">
    <div class="top-info-content">
      <main-paragraph class="main-title" :class="{'eng' : activeEng}">
        {{ infoCard.mainTitle }}
      </main-paragraph>

      <main-paragraph>
        {{ infoCard.description }}
      </main-paragraph>
    </div>

    <button-arrow :active-text="$t('divisionPage.contactButton')"
                  @click="changeStateModal"
                  :direction-active="'right'"/>
  </div>

</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import IconArrow from "@/components/shared/IconArrow.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import ButtonArrow from "@/components/shared/ButtonArrow.vue";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";

export default {
  name: "CardServices",
  components: {ModalConsulting, ButtonArrow, SubParagraph, IconArrow, MainParagraph},
  props: {
    infoCard: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeStateModal() {
      this.$emit('changeStateModal')
      this.activeModal = !this.activeModal
    }
  }
}
</script>

<style scoped lang="scss">
.card-services-container {
  width: 100%;
  height: calc(100% + var(--main-padding-bottom));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: var(--color-card-division);

  .top-info-content {
    display: flex;
    flex-direction: column;

    .main-title {
      color: var(--color-main-title)
    }

    .main-paragraph-container {
      white-space: pre-wrap;
    }
  }
}

@media (max-width: 3024px){
  .card-services-container {
    max-width: 800px;
    padding: 64px;
    border-radius: 64px 0 0 0;

    .top-info-content {
      row-gap: 32px;
    }
  }
}


@media (max-width: 1920px) {
  .card-services-container {
    max-width: 600px;
    padding: 48px;
    border-radius: 48px 0 0 0;

    .top-info-content {
      row-gap: 24px;
    }
  }
}

@media (max-width: 1600px) {
  .card-services-container {
    max-width: 500px;
    padding: 32px;
    border-radius: 32px 0 0 0;

    .top-info-content {
      row-gap: 16px;
    }
  }
}
@media (max-height: 800px) {
  .card-services-container {
    max-width: 440px;
    padding: 24px;
    border-radius: 24px 0 0 0;

    .top-info-content {
      row-gap: 12px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .card-services-container {
    max-width: 500px;
    padding: 32px;
    border-radius: 32px 0 0 0;

    .top-info-content {
      row-gap: 16px;
    }
  }
}

@media (max-width: 1133px) {
  .card-services-container {
    max-width: 440px;
    padding: 24px;
    border-radius: 24px 0 0 0;

    .top-info-content {
      row-gap: 12px;
    }
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .card-services-container {
    max-width: 1400px;
    padding: 128px;
    border-radius: 128px 0 0 0;

    .top-info-content {
      row-gap: 48px;
    }
  }
}

</style>