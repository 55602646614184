
import store from '@/store/index';

(function () {
    let theme = localStorage.getItem('theme')
    if(theme) {
        if (localStorage.getItem('theme') === 'light-theme'){
            localStorage.setItem('theme', 'light-theme');
            store.dispatch('ACTIVE_COLOR', false)
            document.body.className = 'light-theme'
        } else {
            localStorage.setItem('theme', 'dark-theme');
            document.body.className = 'dark-theme';
            store.dispatch('ACTIVE_COLOR', true)
        }
    } else {
        localStorage.setItem('theme', 'light-theme');
        store.dispatch('ACTIVE_COLOR', false)
        document.body.className = 'light-theme'
    }
})();



export  default  {
    methods: {
            setTheme(themeName) {
                this.$store.dispatch('ACTIVE_ANIMATION_COLOR', true)
                setTimeout(() => {
                    this.$store.dispatch('ACTIVE_ANIMATION_COLOR', false)
                    localStorage.setItem('theme', themeName);
                    document.body.className = themeName;
                    this.changeActiveColor();
                }, 500)
        },

        changeActiveColor() {
                if(localStorage.getItem('theme') === 'light-theme') {
                    this.$store.dispatch('ACTIVE_COLOR', false)
                } else {
                    this.$store.dispatch('ACTIVE_COLOR', true)
                }
        },

        toggleTheme() {
            if (localStorage.getItem('theme') === 'light-theme'){
                this.setTheme('dark-theme');
            } else {
                this.setTheme('light-theme');
            }
        },

    },

}
