<template>
  <div class="top-info-container">
    <transition name="fade-opacity-1">
      <button-title-mobile v-if="isVisible(0)"
                           @returnTo="returnTo"
                           :main-title="mainTitle"
                           :active-circle="activeTitleCircle"/>
    </transition>
    <transition name="fade-opacity-1">
      <div class="description-info-container" v-if="isVisible(1)">
        <main-paragraph v-if="typeof mainDescription === 'string'">
          {{ mainDescription }}
        </main-paragraph>
        <main-paragraph v-else v-for="(item, index) in mainDescription" :key="index">
          {{ item }}
        </main-paragraph>
      </div>
    </transition>
    <transition name="fade-opacity-1">
      <div class="scroll-title-container" v-if="isVisible(2)">
        <sub-paragraph>
          {{ subTitle }}
        </sub-paragraph>
        <scroll-mobile-division :active-title-info="scrollInfo"
                                :active-index-scroll="activeIndexScroll"
                                @changeIndex="changeIndex"/>
      </div>
    </transition>
  </div>
</template>

<script>
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import ScrollMobileDivision from "@/components/entities/ScrollMobileDivision.vue";

export default {
  name: "ScrollServiceTechnologiesMobile",
  props: {
    activeTitleCircle: {
      type: Boolean,
      default: false
    },
    activeIndexScroll: {
      type: Number,
      required: true
    },
    scrollInfo: {
      type: Array,
      required: true
    },
    mainTitle: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    mainDescription: {
      required: true
    },
    currentItem: {
      type: Number,
      required: true
    }
  },
  components: {ScrollMobileDivision, SubParagraph, MainParagraph, ButtonTitleMobile},
  methods: {
    isVisible(index) {
      return index <= this.currentItem
    },

    changeIndex(index) {
      this.$emit('changeIndex', index)
    },
    returnTo() {
      this.$emit('returnTo')
    }
  }
}
</script>

<style scoped lang="scss">

.top-info-container {
  .scroll-title-container {
    background-color: var(--color-button-cookie);
    height: 100%;
    position: relative;

    .sub-paragraph-container {
      z-index: 3;
      top: var(--controller-padding);
      left: calc(20px + var(--controller-padding));
      position: absolute;
    }
    .swiper {
      height: 100%;
    }
  }
}

@media (max-width: 769px) {
    .top-info-container {
      display: flex;
      flex-direction: column;

      .main-title-container {
        max-height: 64px;
        min-height: 64px;
        height: 100%;
        padding: 0 var(--controller-padding);
      }

      .description-info-container {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        text-align: justify;
        padding: var(--controller-padding);

        .main-paragraph-container {
          line-height: 130% !important;
        }
      }
      .scroll-title-container {
        max-height: 202px;
      }
    }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .top-info-container {
      .main-title-container {
        max-height: 72px;
        min-height: 72px;
      }
      .scroll-title-container {
        max-height: 206px;
      }
    }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
    .top-info-container {
      .scroll-title-container {
        max-height: 128px;
      }
    }
}


@media (max-width: 375px) and (min-height: 630px) {
    .top-info-container {
      .scroll-title-container {
        max-height: 178px;
      }
    }
}


</style>