<template>
  <div class="sub-button-container"
       @mouseenter="focusTrue"
       @mouseleave="focusFalse"
       :class="{'active-icon' : infoIcon !== undefined, 'disabled' : disabledButton,
       'active-mobile' : activeMobile,
       'active-sub-button' : activeMobile && activeSubButton}">
    <p class="text-info-container" :class="{'dark-theme' : activeColor, 'eng': activeEng}">
      {{ infoText }}
    </p>

    <button-icon v-if="infoIcon !== undefined" :infoIcon="infoIcon" :focus-param="focusParam"/>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "ButtonWithIcon",
  components: {ButtonIcon},
  props: {
    activeSubButton: {
      type: Boolean,
      default: false
    },
    infoText: {
      type: String
    },
    infoIcon: {
      type: String,
      default: undefined
    },

    disabledButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focusParam: false
    }
  }
}
</script>

<style scoped lang="scss">
.sub-button-container {
  background-color: var(--color-button-cookie);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-button-text);
  transition: color .6s ease;

  &.disabled {
    cursor: default;
    &:hover {
      & p {
        opacity: .4;
      }
      .icon-img-container {
        opacity: .4;
      }
    }
  }


  &.active-icon {
    justify-content: space-between;
  }

  &:hover {
    & p {
      opacity: 1;
    }
    .icon-img-container {
      opacity: 1;
    }
  }

  &:active {
    & p {
      color: var(--color-main-visioners);
    }
  }

  & p {
    line-height: 100%;
    opacity: .4;
    font-weight: 200;
    transition: all .6s ease;
  }
}

@media (max-width: 3024px){
    .sub-button-container {
      border-radius: 22px;
      height: 66px;
      padding: 22px 42px;

      & p {
        font-size: 22px;
      }
    }
}



@media (max-width: 1920px) {
    .sub-button-container {
      border-radius: 12px;
      height: 54px;
      padding: 18px 34px;

      & p {
        font-size: 18px;
      }

    }
}

@media (max-width: 1600px) {
    .sub-button-container {
      border-radius: 8px;
      height: 42px;
      padding: 14px 24px;

      & p {
        font-size: 14px;
      }


    }
}
@media (max-height: 800px) {

    .sub-button-container {
      border-radius: 8px;
      height: 34px;
      padding: 11px 20px;


      & p {
        font-size: 12px;
      }
    }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .sub-button-container {
    border-radius: 8px;
    height: 42px;
    padding: 14px 24px;

    & p {
      font-size: 14px;
    }


  }
}

@media (max-width: 1133px) {

  .sub-button-container {
    border-radius: 8px;
    height: 34px;
    padding: 11px 20px;


    & p {
      font-size: 12px;
    }
  }
}
@media (max-width: 769px) {
  .sub-button-container {
  border-radius: 8px;
  height: fit-content;
  padding: 10px 20px;

    & p {
      opacity: 1;
    }
    .icon-img-container {
      opacity: 1;
    }

    &.active-mobile {

      & p {
        font-size: 12px;
      }

      & p {
        opacity: 1;
      }
      .icon-img-container {
        opacity: 1;
      }
    }

    &.active-sub-button {
      & p {
        font-size: 11px;
      }
    }

  & p {
    font-weight: 200;
    font-size: 10px;
  }
}
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .sub-button-container {
    border-radius: 8px;
    padding: 12px 24px;

    &.active-mobile {
      & p {
        font-size: 12px;
      }
    }

    &.active-sub-button {
      & p {
        font-size: 12px;
      }
    }

    & p {
      font-size: 12px;
    }
  }
}

@media (max-width: 375px) {
  .sub-button-container {

    &.active-mobile {
      & p {
        font-size: 11px;
      }
    }
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  .sub-button-container {

    &.active-mobile {
      & p {
        font-size: 12px;
      }
    }
  }
}


@media (min-width: 3025px) {
    .sub-button-container {
      border-radius: 24px;
      height: 96px;
      padding: 32px 64px;



      & p {
        font-size: 32px;
      }
    }
}
</style>