<template>
  <transition name="fade-opacity-lazy" mode="out-in">
  <div class="business-screen-container main-container" v-if="!activeMobile || activeContentMobile">
<!--    <line-controller-container :active-screen="true"-->
<!--                               @endAnimation="endAnimation"-->
<!--                               @changeActiveLine="changeActiveLineController"-->
<!--                               :active-index-line="activeIndex"-->
<!--                               :line-controller="$tm('businessScreen.controllerInfo')"/>-->
    <transition name="fade-opacity-lazy">
      <div class="bottom-info-content" v-if="bottomContent">
        <div class="img-container" v-if="activeColor" :class="{'active ': activeAnimation}">
          <video class="video" loop playsinline preload="auto" autoplay  muted>
            <source :src="getUrl(imgContentDark)" type="video/webm"/>
            <source :src="getUrl(imgContentDarkMP4)" type='video/mp4'/>
          </video>
        </div>
        <div class="img-container" v-else :class="{'active ': activeAnimation}">
          <video class="video" loop playsinline preload="auto" autoplay  muted>
            <source :src="getUrl(imgContentLight)" type="video/webm"/>
            <source :src="getUrl(imgContentLightMP4)" type='video/mp4'/>
          </video>
        </div>
        <swiper class="swiper"
                @slide-change="onSwiperMain"
                @swiper="changeSettingSwiper"
                :slides-per-view="1"
                :modules="modules"
                :speed="1000"
                :breakpoints="
                  {
                    0: {
                      allowTouchMove: true,
                    },
                    1600: {
                      allowTouchMove: false
                    }
                  }"
                :mousewheel="true"
                direction="vertical"
                :effect="'fade'">
          <swiper-slide>
            <div class="left-info-container start">
              <transition name="fade-translate-x" mode="out-in">
                <main-title v-if="activeIndex === 0" style="position: relative">
                  {{ $t('businessScreen.mainTitle') }}
                </main-title>
              </transition>
            </div>
            <div class="right-info-container">
            </div>
          </swiper-slide>
          <swiper-slide v-for="item in $tm('businessScreen.controllerInfo').length">
            <div class="left-info-container" v-if="activeIndex !== 0">
              <transition name="fade-translate-x" mode="out-in">
                <div class="text-info-content" :key="activeIndex">
                  <main-title>
                    {{ activeInfo.mainTitle }}
                  </main-title>
                  <main-paragraph>
                    {{ activeInfo.subTitle }}
                  </main-paragraph>
                </div>
              </transition>
            </div>
            <div class="right-info-container">
            </div>
          </swiper-slide>
        </swiper>
      </div>
  </transition>
  </div>

    <group-screen v-else :active-index="0" @changeContent="changeContent"/>
  </transition>
</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import LineControllerContainer from "@/components/entities/LineControllerContainer.vue";
import {EffectFade, Mousewheel} from "swiper";
import GroupScreen from "@/components/features/GroupScreen.vue";

export default {
  name: "BusinessScreen",
  components: {GroupScreen, LineControllerContainer, MainParagraph, MainTitle},
  data() {
    return {
      //White_rgba_VP9_16-9
      //WHITE_WEBM_1
      imgContentLight: 'White_16-9_3840х2160_30fps.webm',
      imgContentLightMP4: 'White_16-9_3840х2160_30fps.mp4',
      imgContentDark: 'Dark_16-9_3840х2160_30fps.webm',
      imgContentDarkMP4: 'Dark_16-9_3840х2160_30fps.mp4',
      activeAnimation: false,
      activeMainTitle: true,
      typeBusiness: undefined,
      businessSwiper: '',
      modules: [EffectFade, Mousewheel],
      blockBreadCrumbSwiper: false,
      bottomContent: false,
      activeContentMobile: false
    }
  },
  created() {
    this.typeBusiness = this.$route.params.typeBusiness
    this.$watch(
        () => this.$route.params,
        (toParams) => {
          this.typeBusiness = toParams?.typeBusiness
        }
    )
  },
  mounted() {
    this.endAnimation();
  },


  watch: {
    '$route'() {
      if(!this.activeMobile) {
        if(this.$route.name.includes('main-business-page')) {
          this.businessSwiper.slideTo(0, 500, false)
          this.checkBlock();
        }
      }

    }
  },

  computed: {
    activeFirstContent() {
      return this.activeIndex === 0;
    },

    activeInfo() {
      if (this.activeIndex !== -1) {
        return this.$tm('businessScreen.descriptionInfo')[this.activeIndex - 1]
      } else {
        return undefined
      }
    },
    activeIndex() {
      if(this.typeBusiness !== undefined) {
        switch (this.typeBusiness) {
          case 'consulting':
            return 1;
          case 'design':
            return 2;
          case 'programming':
            return 3;
          case 'engineering':
            return 4;
          case 'result':
            return 5;
        }
      } else {
        return 0
      }
    },
  },
  methods: {

    changeContent() {
      // this.activeContentMobile = !this.activeContentMobile
      // this.$emit('changeActiveContent', this.activeContentMobile)
    },

    endAnimation() {
      this.bottomContent = true;
      setTimeout(() => {
        this.activeAnimation = true;
        if(this.businessSwiper !== '') {
          this.businessSwiper.slideTo(this.activeIndex, 0, false)
        }
        this.checkBlock();
      }, 50)
    },

    changeActiveLineController(id) {
      this.businessSwiper.slideTo(id, 500, false)
      this.changeActiveLine(id)
    },

    changeActiveLine(id) {
      switch (id) {
        case 0:
          this.$router.push({name: 'main-business-page'})
          break;
        case 1:
          this.$router.push({name: 'main-business-type-page', params: {typeBusiness: 'consulting'}})
          break;
        case 2:
          this.$router.push({name: 'main-business-type-page', params: {typeBusiness: 'design'}})
          break;
        case 3:
          this.$router.push({name: 'main-business-type-page', params: {typeBusiness: 'programming'}})
          break;
        case 4:
          this.$router.push({name: 'main-business-type-page', params: {typeBusiness: 'engineering'}})
          break;
        case 5:
          this.$router.push({name: 'main-business-type-page', params: {typeBusiness: 'result'}})
          break;
      }
    },

    onSwiperMain(swiper) {
      this.businessSwiper.disable();
      this.changeActiveLine(swiper.activeIndex)
      this.blockBreadCrumbSwiper = false
      this.checkBlock();
      this.$nextTick(() => {
        this.businessSwiper.enable();
      })
    },


    checkBlock() {
      if(this.businessSwiper.isBeginning) {
        this.$emit('blockNextSlide', true)
        setTimeout(() => {
          this.$emit('blockPrevSlide', false)
        },100)
      } else if(this.businessSwiper.isEnd){
        this.$emit('blockPrevSlide', true)
        setTimeout(() => {
          this.$emit('blockNextSlide', false)
        },100)
      } else if(this.businessSwiper.activeIndex >= 1 && this.businessSwiper.activeIndex < 5) {
        this.$emit('blockPrevSlide', true)
        this.$emit('blockNextSlide', true)
      }
    },

    changeSettingSwiper(swiper) {
      this.businessSwiper = swiper
    },
  }
}
</script>

<style scoped lang="scss">
.business-screen-container {
  padding: 0;
}
.business-screen-container {
  .bottom-info-content {
    position: relative;
    display: flex;
    align-items: center;
    //height: 80%;
    height: 100%;
    width: 100%;

    .swiper {
      padding: var(--main-padding-top) 0 var(--main-padding-bottom) var(--controller-padding);
      width: 100%;
      height: 100%;
    }

    .left-info-container {
      position: relative;
      margin: auto 0;
      .text-info-content {
        display: flex;
        position: relative;
        flex-direction: column;
      }
    }

    .right-info-container {
      width: 50%;
    }
  }
}

.img-container {
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;

  &.active {
    & video {
      opacity: 1;
    }
  }

  & video {
    object-fit: cover;
    transition: opacity .6s ease;
    //will-change: mix-blend-mode;
    //mix-blend-mode: screen;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.swiper-slide {
  background-color: rgba(0,0,0,0) !important;
}

@media (max-width: 3024px){
  .business-screen-container {
    .bottom-info-content {
      .left-info-container {
        max-width: 940px;
        padding: 0 128px;

        &.start {
          padding: 0 80px;
        }

        .text-info-content {
          row-gap: 48px;
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  .business-screen-container {
    .bottom-info-content {
      .left-info-container {
        max-width: 868px;
        padding: 0 128px;

        .text-info-content {
          row-gap: 40px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .business-screen-container {
    .bottom-info-content {
      .left-info-container {
        max-width: 568px;
        padding: 0 96px;

        &.start {
          padding: 0 60px;
        }

        .text-info-content {
          row-gap: 32px;
        }
      }
    }
  }

  .img-container {
    width: 110%;
    //& video {
    //  height: 80%;
    //}
  }
}
@media (max-height: 800px) {
  .business-screen-container {
    .bottom-info-content {
      .left-info-container {
        max-width: 478px;
        padding: 0 64px;

        &.start {
          padding: 0 40px;
        }

        .text-info-content {
          row-gap: 20px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .business-screen-container {
    .bottom-info-content {
      .left-info-container {
        max-width: 568px;
        padding: 0 96px;

        &.start {
          padding: 0 60px;
        }

        .text-info-content {
          row-gap: 32px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .business-screen-container {
    .bottom-info-content {
      .left-info-container {
        max-width: 478px;
        padding: 0 64px;

        &.start {
          padding: 0 40px;
        }

        .text-info-content {
          row-gap: 20px;
        }
      }
    }
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .business-screen-container {
    .bottom-info-content {
      .left-info-container {
        max-width: 1790px;
        padding: 0 160px;

        &.start {
          padding: 0 120px;
        }

        .text-info-content {
          row-gap: 84px;
        }
      }
    }
  }
}

</style>