<template>
  <p class="small-title-container" :class="{'eng' : activeEng && activeStaticEng}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "SmallTitle",
  props: {
    activeStaticEng: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">

.small-title-container {
  line-height: 120%;
  color: var(--color-main-grey);
  font-size: 8px;
}


</style>