<template>
  <div class="slider-scroll-container">
    <swiper @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :slides-per-view="1"
            :modules="modules"
            class="team"
            :scrollbar="{
                enabled: true,
                draggable: true,
                dragClass: 'swiper-scrollbar-drag-custom'
             }"
            :speed="800"
            direction="horizontal"
            :effect="'fade'">
      <swiper-slide v-for="(item, index) in activeLength">
        <transition name="fade-opacity-1" mode="out-in">
          <div class="team-info-container" :key="activeInfoTeam">
                <card-team v-for="item in activeInfoTeam" :key="item.id" :team-info="item"/>
          </div>
        </transition>
      </swiper-slide>
    </swiper>
  </div>


</template>

<script>
import {EffectFade, Navigation, Scrollbar, Virtual} from "swiper";
import CardProject from "@/components/entities/CardProject.vue";
import IconArrow from "@/components/shared/IconArrow.vue";
import CardTeam from "@/components/entities/CardTeam.vue";

export default {
  name: "SliderScrollTeamMobile",
  components: {CardTeam, IconArrow, CardProject},
  props: {
    activeInfo: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      scrollSwiper: '',
      activeIndex: 0,
      modules: [Scrollbar, EffectFade]
    }
  },
  computed: {
    activeLength() {
      return Math.ceil(this.activeInfo.length / 2.0);
    },
    activeInfoTeam() {
      let activeCurrent = this.activeIndex * 2
      return this.activeInfo.slice(activeCurrent, activeCurrent + 2)
    }
  },
  methods: {
    onSwiperMain(swiper) {
        this.activeIndex = swiper.activeIndex;
    },

    changeSettingSwiper(swiper) {
      this.scrollSwiper = swiper
    },
  }
}
</script>

<style scoped lang="scss">


.swiper {
  &.team {
    .swiper-scrollbar {
      opacity: 1 !important;
    }
  }
}

.slider-scroll-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
  position: relative;
  margin-left: calc(-1 * var(--controller-padding));
  margin-bottom: calc(-1 * var(--main-padding-bottom));
  padding: var(--padding-team-slider) var(--controller-padding);
  width: var(--calc-width);
  background-color: var(--color-button-cookie);
  .swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      background-color: var(--color-button-cookie);
    }

    .team-info-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
}

@media (max-width: 769px) {
  .slider-scroll-container {
    max-height: 420px;
    --padding-team-slider: 24px;
    .swiper {
      padding-bottom: 5px;
      .team-info-container {
        padding-bottom: 30px;;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .slider-scroll-container {
    max-height: 466px;
    --padding-team-slider: 32px;
    .swiper {
      .team-info-container {
        padding-bottom: 46px;
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .slider-scroll-container {
    max-height: 329px;
    --padding-team-slider: 16px;
    .swiper {
      .team-info-container {
        padding-bottom: 16px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .slider-scroll-container {
    max-height: 394px;
    --padding-team-slider: 24px;
    .swiper {
      .team-info-container {
        padding-bottom: 18px;;
      }
    }
  }
}


@media (max-width: 320px) {
  .slider-scroll-container {
    max-height: 260px;
    --padding-team-slider: 12px;
    .swiper {
      .team-info-container {
        padding-bottom: 12px;
      }
    }
  }
}

</style>