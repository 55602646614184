<template>
  <modal-frame :active-title="$t('cookieModal.cookieTitle') "
               :blur-active="false"
               @closeModalCross="changeModalCookieSetting">
    <div class="check-box-container">
      <div class="check-box-content">
        <p class="check-box-text left">
          {{ $t('cookieModal.checkOne') }}
        </p>
        <span class="check-box-text right">
               {{ $t('cookieModal.checkTwo') }}
            </span>
      </div>
      <div class="check-box-content" v-for="(item, index) in $tm('cookieModal.checkActive')" :key="item.id">
        <p class="check-box-text">
          {{ item.textTitle }}
        </p>
        <main-button @click="changeActive(index)"
                     :active-check-box="true"
                     :icon-active="'check-mark'"
                     :active-button="isActiveButton[index]"/>
      </div>
    </div>
    <div class="cookie-paragraph">
      {{ $t('cookieModal.cookieParagraph') }}
    </div>
    <button-with-icon @click="closeCookie" :info-text="$t('cookieModal.saveSetting')"/>
  </modal-frame>
</template>

<script>

import MainButton from "@/components/shared/MainButton.vue";
import ButtonWithIcon from "@/components/shared/ButtonWithIcon.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";

export default {
  name: "CookieModal",
  components: {ModalFrame, ButtonWithIcon, MainButton},
  data() {
    return {
      isActiveButton: [true, true, true]
    }
  },
  methods: {
    changeModalCookieSetting() {
      this.$emit('changeStateCookie')
    },
    closeCookie() {
      this.$emit('closeCookie', false)
    },

    changeActive(index) {
      console.log(index)
      this.isActiveButton[index] = !this.isActiveButton[index]
    }
  }
}
</script>

<style scoped lang="scss">

  .frame-modal-container {

    .cookie-paragraph {
      text-align: justify;
      line-height: 120%;
    }


    .check-box-container {
      display: flex;
      flex-direction: column;

      .check-box-content {
        align-items: center;
        justify-content: space-between;
        display: flex;
        flex-direction: row;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 3024px){
    .frame-modal-container {
      max-width: 1020px;
      .cookie-paragraph {
        font-size: 22px;
        line-height: 120%;
        margin-bottom: 32px;
      }

      .check-box-container {
        font-size: 22px;

        .check-box-content {
          height: 66px;
          margin-bottom: 30px;
        }
      }
    }
  }



    @media (max-width: 1920px) {
      .frame-modal-container {
        max-width: 816px;
        .cookie-paragraph {
          font-size: 18px;
          line-height: 120%;
          margin-bottom: 36px;
        }


        .check-box-container {
          font-size: 18px;

          .check-box-content {
            height: 54px;
            margin-bottom: 22px;
          }
        }
      }
    }

  @media (max-width: 1600px) {
    .frame-modal-container {
      max-width: 636px;

      .cookie-paragraph {
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 16px;
      }

      .check-box-container {
        font-size: 14px;

        .check-box-content {
          height: 42px;
          margin-bottom: 16px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .frame-modal-container {
      max-width: 532px;
      .cookie-paragraph {
        font-size: 12px;
        line-height: 120%;
        margin-bottom: 12px;
      }


      .check-box-container {
        font-size: 12px;

        .check-box-content {
          height: 34px;
          margin-bottom: 12px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .frame-modal-container {
      max-width: 636px;
      .cookie-paragraph {
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 16px;
      }


      .check-box-container {
        font-size: 14px;

        .check-box-content {
          height: 42px;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .frame-modal-container {
      max-width: 532px;
      .cookie-paragraph {
        font-size: 12px;
        line-height: 120%;
        margin-bottom: 12px;
      }


      .check-box-container {
        font-size: 12px;

        .check-box-content {
          height: 34px;
          margin-bottom: 12px;
        }
      }
    }
  }
  @media (max-width: 769px) {
    .frame-modal-container {
      max-width: 362px;
      .cookie-paragraph {
        font-size: 10px;
        line-height: 120%;
        margin-bottom: 12px;
      }


      .check-box-container {
        font-size: 10px;

        .check-box-content {
          height: 34px;
          margin-bottom: 12px;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
    .frame-modal-container {
      max-width: 396px;
      .cookie-paragraph {
        font-size: 12px;
        line-height: 120%;
        margin-bottom: 12px;
      }


      .check-box-container {
        font-size: 12px;

        .check-box-content {
          height: 34px;
          margin-bottom: 12px;
        }
      }
    }
  }

  @media (max-width: 375px){
    .frame-modal-container {
      max-width: 347px;
    }
  }

  @media (max-width: 320px){
    .frame-modal-container {
      max-width: 300px;
    }
  }


  @media (min-width: 3025px) {
    .frame-modal-container {
      max-width: 1440px;
      .cookie-paragraph {
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 40px;
      }

      .check-box-container {
        font-size: 32px;

        .check-box-content {
          height: 96px;
          margin-bottom: 40px;
        }
      }
    }
  }


</style>