import {language, defaultLocale} from "@/static/language";
import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler.js';
const messages = Object.assign(language)

const i18n = createI18n({
    legacy: false,
    locale: defaultLocale, // set locale
    fallbackLocale: 'ru', // set fallback locale
    messages: messages, // set locale messages
})
export default i18n