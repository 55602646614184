<template>
    <swiper class="slider-team-container"
            @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :slides-per-view="1"
            :modules="modules"
            :speed="1000"
            :breakpoints="
            {
              0: {
                allowTouchMove: true,
              },
              1600: {
                allowTouchMove: false
              }
            }"
            :mousewheel="true"
            direction="vertical"
            :effect="'fade'">
      <swiper-slide class="slider-team-slide" v-for="item in 4">
        <transition name="fade-opacity-lazy" mode="out-in">
        <div class="team-info-container" v-if="isActive" :key="activeInfo">
          <div class="main-title-container" v-if="activeIndex === 0" :class="{'eng' : activeEng}">
            {{ $t('teamScreen.mainTitle') }}
          </div>
          <div class="team-info-content">
            <card-team v-for="itemTwo in activeInfo.slice(0,2)" :team-info="itemTwo"/>
          </div>
          <div class="team-info-content" v-if="activeInfo.length > 2">
            <card-team v-for="itemTwo in activeInfo.slice(2,4)" :team-info="itemTwo"/>
          </div>
        </div>
        </transition>
          <transition name="fade-opacity-lazy" mode="out-in">
        <div class="controller-slider" v-if="lengthInfo > 4 && isActive">
          <icon-arrow direction="left" @click="changePageMinus"/>
          <div class="pagination-container">
            <icon-circle v-for="(item, index) in counterPage"
                         :class="{'active' : index === activePage}"
                         @click="changePage(index)"/>
          </div>
          <icon-arrow direction="right" @click="changePagePlus"/>
        </div>
        </transition>
      </swiper-slide>
    </swiper>
</template>

<script>
import {EffectFade, Mousewheel, Navigation, Pagination, Scrollbar, Virtual} from "swiper";
import CardTeam from "@/components/entities/CardTeam.vue";
import IconArrow from "@/components/shared/IconArrow.vue";
import IconCircle from "@/components/shared/IconCircle.vue";

export default {
  name: "SliderTeam",
  components: {IconCircle, IconArrow, CardTeam},
  props: {
    teamInfo: {
      type: Array,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    },
    counterPage: {
      type: Number,
      required: true
    },
    lengthInfo: {
      type: Number,
      required: true
    },
    activeLineChange: {
      type: Boolean
    }
  },
  data() {
    return {
      activePage: 0,
      modules: [EffectFade, Mousewheel],
      teamSwiper: '',
      isActive: false,
      blockActiveIndex: false
    }
  },
  mounted() {
    this.teamSwiper.slideTo(this.activeIndex, 0, false)
    this.checkBlock();
    setTimeout(() => {
      this.isActive = true
    }, 500)
  },
  watch: {
    teamInfo() {
      this.blockActiveIndex = true
      this.isActive = false
      this.activePage = 0
      this.teamSwiper.slideTo(this.activeIndex)
      this.checkBlock();
      setTimeout(() => {
        this.blockActiveIndex = false
        this.isActive = true
      }, 500)
    },
  },
  computed: {
    activeInfo() {
      let activePageCounter = this.activePage * 4;
      return this.teamInfo.slice(activePageCounter, activePageCounter + 4)
    },
  },
  methods: {

    changePage(id) {
      this.activePage = id
    },

    changePageMinus() {
      if(this.activePage === 0) {
        this.activePage = this.counterPage - 1
      } else {
        this.activePage = this.activePage - 1;
      }
    },
    changePagePlus() {
      if(this.activePage === this.counterPage - 1) {
        this.activePage = 0
      } else {
        this.activePage = this.activePage + 1;
      }
    },

    onSwiperMain(swiper) {
      if(!this.blockActiveIndex) {
        this.$emit('changeSlide', swiper.activeIndex, true);
      }
      this.checkBlock();
    },


    checkBlock() {
      if(this.teamSwiper.isBeginning) {
        this.$emit('blockNextSlide', true)
        setTimeout(() => {
          this.$emit('blockPrevSlide', false)
        },100)
      } else if(this.teamSwiper.isEnd){
        this.$emit('blockPrevSlide', true)
        setTimeout(() => {
          this.$emit('blockNextSlide', false)
        },100)
      } else  {
        this.$emit('blockPrevSlide', true)
        this.$emit('blockNextSlide', true)
      }
    },

    changeSettingSwiper(swiper) {
      this.teamSwiper = swiper
    },
  }
}
</script>

<style scoped lang="scss">
  .slider-team-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-self: stretch;
    align-items: center;
    position: relative;

    .slider-team-slide {
      flex-direction: column;
    }

    .team-info-container {
      position: relative;;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      flex-wrap: wrap;

      .main-title-container {
        align-self: center;
        width: 50%;
        color: var(--color-main-text);
      }

      .team-info-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
    .controller-slider {
      position: relative;;
      display: flex;
      flex-direction: row;
      .pagination-container {
        display: flex;
        flex-direction: row;
        .icon-circle-container {
          cursor: pointer;
          opacity: .2;
          transition: opacity .6s ease;

          &:hover {
            opacity: 1;
          }

          &.active {
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: 3024px){
    .slider-team-container {
      .team-info-container {
        padding: 80px 128px;
        max-height: 1150px;

        .main-title-container {
          font-size: 56px;
        }
      }
      .controller-slider {
        column-gap: 64px;
        .pagination-container {
          column-gap: 32px;
        }
      }
    }
  }

  @media (max-width: 1920px) {
    .slider-team-container {
      .team-info-container {
        padding: 80px 128px;
        max-height: 827px;

        .main-title-container {
          font-size: 48px;
        }
      }
      .controller-slider {
        column-gap: 48px;
        .pagination-container {
          column-gap: 24px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .slider-team-container {
      .team-info-container {
        padding: 80px 96px;
        max-height: 717px;

        .main-title-container {
          font-size: 32px;
        }
      }
      .controller-slider {
        column-gap: 32px;
        .pagination-container {
          column-gap: 16px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .slider-team-container {
      .team-info-container {
        padding: 96px;
        max-height: 570px;

        .main-title-container {
          font-size: 28px;
        }
      }
      .controller-slider {
        column-gap: 24px;
        .pagination-container {
          column-gap: 12px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .slider-team-container {
      .team-info-container {
        padding: 80px 96px;
        max-height: 841px;

        .main-title-container {
          font-size: 32px;
        }
      }
      .controller-slider {
        column-gap: 32px;
        .pagination-container {
          column-gap: 16px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .slider-team-container {
      .team-info-container {
        padding: 96px;
        max-height: 594px;

        .main-title-container {
          font-size: 28px;
        }
      }
      .controller-slider {
        column-gap: 24px;
        .pagination-container {
          column-gap: 12px;
        }
      }
    }
  }
  @media (max-width: 769px) {
    //iPhone 14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //iPhone Plus
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .slider-team-container {
      .team-info-container {
        padding: 160px;
        max-height: 2014px;

        .main-title-container {
          font-size: 96px;
        }
      }
      .controller-slider {
        column-gap: 128px;
        .pagination-container {
          column-gap: 64px;
        }
      }
    }
  }

</style>