<template>
  <div class="technologies-screen-container main-container" v-if="!activeMobile">
    <div class="left-info-content">
      <transition name="fade-opacity-1">
      <div class="top-info-content" v-if="isVisible(0)">
        <div class="title-info-container">
          <main-title>
            {{ activeDivisionTechnologies.mainTitle }}
          </main-title>
        </div>


        <div class="description-info-container">
          <main-paragraph v-for="(item, index) in activeDivisionTechnologies.description"
                          :key="index">
            {{ item }}
          </main-paragraph>
        </div>
      </div>
      </transition>

          <div class="bottom-info-content">
            <div class="complex-title-container">
                  <text-with-circle v-for="item in this.activeDivisionTechnologies.subInfo"
                                    :class="{'active-hover' : isVisible(item.id + 1)}"
                                    @click="changeIndex(item.id)"
                                    :active-index="activeIndex"
                                    :item-info="item"
                                    :key="item.id">
                    <main-paragraph :class="{'eng' : activeEng}">
                      {{ item.mainTitle }}
                    </main-paragraph>
                  </text-with-circle>
            </div>
          </div>
  </div>

    <transition name="fade-opacity-1">
       <card-consulting v-if="typeDivision === 'consulting'" :services-info="activeTechnologiesTitle"/>
    </transition>
      <transition name="fade-translate-x" mode="out-in">
      <card-technologies v-if="typeDivision !== 'consulting'  && isVisible(2)"
                         :key="activeTechnologiesTitle"
                         :services-info="activeTechnologiesTitle"
                         :type-division="typeDivision"/>
    </transition>
  </div>

  <div class="technologies-screen-container main-container" v-else>

    <scroll-service-technologies-mobile :current-item="currentItem"
                                        @returnTo="returnTo"
                                        :active-title-circle="true"
                                        :active-index-scroll="activeTechnologiesTitle.id"
                                        :main-description="activeDivisionTechnologies.descriptionMobile"
                                        :sub-title="activeDivisionTechnologies.mainTitle"
                                        :main-title="activeMainTitle"
                                        :scroll-info="activeDivisionTechnologies.subInfo"
                                        @changeIndex="changeIndex"/>


    <transition name="fade-opacity-1">
    <div class="technologies-info-container consulting"  v-if="typeDivision === 'consulting'  && isVisible(5)">
      <transition name="fade-opacity-1" mode="out-in">
      <div class="technologies-info-content" :key="activeTechnologiesTitle">
        <transition-group name="fade-opacity-1">
          <main-paragraph v-for="item in activeTechnologiesTitle.description" :key="item.id">
            {{ item.description }}
          </main-paragraph>
        </transition-group>
      </div>
      </transition>
    </div>
      <div class="technologies-info-container"  v-else-if="(typeDivision === 'engineering' || typeDivision === 'project') && isVisible(3)">
        <transition name="fade-opacity-1" mode="out-in">
          <card-technologies-mobile :active-info="activeTechnologiesTitle"
                                    :key="activeTechnologiesTitle"
                                    :type-division="typeDivision"/>
        </transition>
      </div>
    </transition>
  </div>

</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import TextWithCircle from "@/components/shared/TextWithCircle.vue";
import CardConsulting from "@/components/entities/CardConsulting.vue";
import CardTechnologies from "@/components/entities/CardTechnologies.vue";
import {mapActions} from "vuex";
import ScrollServiceTechnologiesMobile from "@/components/entities/ScrollServiceTechnologiesMobile.vue";
import CardTechnologiesMobile from "@/components/entities/CardTechnologiesMobile.vue";

export default {
  name: "TechnologiesScreen",
  components: {
    CardTechnologiesMobile,
    ScrollServiceTechnologiesMobile,
    CardTechnologies, CardConsulting, TextWithCircle, SubParagraph, MainTitle, MainParagraph},
  props: ['typeDivision', 'typeTechnologies'],
  computed: {

    activeMainTitle() {
      return this.$tm('divisionPage')[this.typeDivision]?.mainTitle
    },

    activeDivisionTechnologies() {
      return this.$tm('divisionPage')[this.typeDivision].technologiesInfo;
    },

    activeTechnologiesTitle() {
      return this.activeDivisionTechnologies.subInfo.find(item => item.url === this.typeTechnologies);
    },
    activeIndex() {
      return this.activeTechnologiesTitle?.id;
    },
  },

  data() {
    return {
      currentItem: -1
    }
  },
  mounted() {
    this.checkSlugTitle();
    if(this.activeMobile){
      this.showNextItem();
    } else {
      setTimeout(() => {
        this.showNextItem();
      }, 750)
    }
  },
  watch: {
    activeTechnologiesTitle() {
      this.checkSlugTitle();
    }
  },
  methods: {
    ...mapActions(['ACTIVE_SLUG_TITLE']),

    returnTo() {
      this.$router.push({name: 'main-type-division-page', params: {typeDivision: this.typeDivision}})
    },

    showNextItem() {
      let time;
      if(this.activeMobile) {
        time = 500;
      } else {
        time = 150;
      }
      setTimeout(() => {
        if (this.currentItem < this.activeDivisionTechnologies.subInfo.length + 2) {
          this.currentItem++
          this.showNextItem()
        }
      }, time)
    },
    isVisible(index) {
      return index <= this.currentItem
    },


    checkSlugTitle() {
        this.ACTIVE_SLUG_TITLE(this.activeTechnologiesTitle.mainTitle)
    },

    changeIndex(id) {
      this.$router.push({name: "main-type-division-technologies-page", params:
            {
              typeDivision: this.typeDivision,
              typeTechnologies: this.activeDivisionTechnologies.subInfo.find(item => item.id === id).url
            }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.technologies-screen-container {
  flex-direction: row;

  .left-info-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .top-info-content {
      display: flex;
      flex-direction: column;

      .title-info-container {
        display: flex;
        flex-direction: column;
      }

      .description-info-container {
        display: flex;
        flex-direction: column;
        white-space: pre-wrap;

      }
    }

    .bottom-info-content {
      display: flex;
      flex-direction: column;

      .complex-title-container {
        display: flex;
        flex-direction: column;

        .title-with-circle-container {
          opacity: 0;
          transition: opacity .6s ease;

          &.active-hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 3024px){
  .technologies-screen-container {

    .left-info-content {
      padding: 128px 0 0 64px;
      max-width: 828px;

      .top-info-content {
        row-gap: 48px;

        .title-info-container {
          row-gap: 10px;
        }

        .description-info-container {
          row-gap: 24px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 42px;

        .complex-title-container {
          row-gap: 20px;
        }
      }
    }
  }
}



@media (max-width: 1920px) {
  .technologies-screen-container {

    .left-info-content {
      padding: 96px 0 0 48px;
      max-width: 676px;

      .top-info-content {
        row-gap: 40px;

        .title-info-container {
          row-gap: 8px;
        }

        .description-info-container {
          row-gap: 20px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 36px;

        .complex-title-container {
          row-gap: 16px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .technologies-screen-container {

    .left-info-content {
      padding: 64px 0 0 32px;
      max-width: 520px;

      .top-info-content {
        row-gap: 32px;

        .title-info-container {
          row-gap: 6px;
        }

        .description-info-container {
          row-gap: 16px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 30px;

        .complex-title-container {
          row-gap: 12px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .technologies-screen-container {

    .left-info-content {
      padding: 48px 0 0 24px;
      max-width: 440px;

      .top-info-content {
        row-gap: 20px;

        .title-info-container {
          row-gap: 4px;
        }

        .description-info-container {
          row-gap: 10px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 22px;

        .complex-title-container {
          row-gap: 10px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .technologies-screen-container {

    .left-info-content {
      padding: 64px 0 0 32px;
      max-width: 520px;

      .top-info-content {
        row-gap: 32px;

        .title-info-container {
          row-gap: 6px;
        }

        .description-info-container {
          row-gap: 16px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 30px;

        .complex-title-container {
          row-gap: 12px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .technologies-screen-container {

    .left-info-content {
      padding: 48px 0 0 24px;
      max-width: 440px;

      .top-info-content {
        row-gap: 20px;

        .title-info-container {
          row-gap: 4px;
        }

        .description-info-container {
          row-gap: 10px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 22px;

        .complex-title-container {
          row-gap: 10px;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .technologies-screen-container {
    padding: var(--burger-height) 0 var(--main-padding-bottom) 0;
    justify-content: flex-start;
    flex-direction: column;

    .technologies-info-container {
      padding: 24px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      &.consulting {
        padding: 24px 24px 0;
      }

      .technologies-info-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .main-paragraph-container {
          color: var(--color-main-title)
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .technologies-screen-container {

    .technologies-info-container {
      padding: 24px 0 0;
      &.consulting {
        padding: 24px 24px 0;
      }


      .info-text-container {
        row-gap: 12px;
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .main-paragraph-container {
    font-size: 10px;
  }
  .technologies-screen-container {
    padding: 16px 0 0;

    &.consulting {
      padding: 16px 16px 0;
    }


    .info-text-container {
      row-gap: 8px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .main-paragraph-container {
    font-size: 12px;
  }
  .technologies-screen-container {
    padding: 24px 0 0;

    &.consulting {
      padding: 24px 24px 0;
    }

    .info-text-container {
      row-gap: 12px;
    }
  }
}

@media (min-width: 3025px) {
  .technologies-screen-container {

    .left-info-content {
      padding: 320px 0 0 160px;
      max-width: 1604px;

      .top-info-content {
        row-gap: 84px;

        .title-info-container {
          row-gap: 12px;
        }

        .description-info-container {
          row-gap: 42px;

        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 80px;

        .complex-title-container {
          row-gap: 24px;
        }
      }
    }
  }

}

</style>