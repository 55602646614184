<template>
  <swiper @slide-change="onSwiperMain"
          v-if="!activeMobile"
          @swiper="changeSettingSwiper"
          :slides-per-view="1"
          :modules="modules"
          class="swiper"
          :speed="1000"
          :breakpoints="
            {
              0: {
                allowTouchMove: true,
              },
              1600: {
                allowTouchMove: false
              }
            }"
          :mousewheel="true"
          direction="vertical"
          :effect="'fade'">

    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <start-screen
            @blockNextSlide="blockNextSlide"
            @blockPrevSlide="blockPrevSlide"
            @slideTo="slideNext" v-if="activeStart"/>
      </transition>
    </swiper-slide>

    <swiper-slide>
      <transition name="fade-opacity-lazy">
      <navigation-screen
          @blockNextSlide="blockNextSlide"
          @blockPrevSlide="blockPrevSlide"
          v-if="activeNavigation"/>
      </transition>
    </swiper-slide>

    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <business-screen v-if="activeBusiness"
                         @blockNextSlide="blockNextSlide"
                         @blockPrevSlide="blockPrevSlide"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
      <structure-screen v-if="activeStructure"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
      <team-screen v-if="activeTeam"
                   @blockNextSlide="blockNextSlide"
                   @blockPrevSlide="blockPrevSlide"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
      <project-screen v-if="activeProject"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
      <partners-screen v-if="activePartners"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
      <footer-screen v-if="activeFooter"/>
      </transition>
    </swiper-slide>
  </swiper>


  <swiper @slide-change="onSwiperMain"
          v-else
          @swiper="changeSettingSwiper"
          :slides-per-view="1"
          :modules="modules"
          class="swiper mobile"
          :speed="1000"
          :allowTouchMove="true"
          :mousewheel="true"
          direction="vertical"
          :effect="activeAnimation">
    <transition name="fade-opacity-lazy">
      <icon-arrow v-if="activeArrowMobile"
                  @click="mainSwiper.slideNext()"
                  direction="bottom"/>
    </transition>

    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <start-screen v-show="activeStart"/>
      </transition>
    </swiper-slide>

    <template v-slot:wrapper-end>
      <div class="background-container" :class="{'active' : !activeContentSlider && (activeBusiness || activeStructure || activeTeam)}">
                <div class="img-container" v-if="activeColor">
                  <video class="video" loop playsinline preload="auto" autoplay  muted>
                    <source :src="getUrl(imgContentDark)" type="video/webm"/>
                    <source :src="getUrl(imgContentDarkMP4)" type='video/mp4'/>
                  </video>
                </div>
                <div class="img-container" v-else>
                  <video class="video" loop playsinline preload="auto" autoplay  muted>
                    <source :src="getUrl(imgContentLight)" type="video/webm"/>
                    <source :src="getUrl(imgContentLightMP4)" type='video/mp4'/>
                  </video>
                </div>
              </div>
    </template>
    <swiper-slide :style="{'transition': 'all .5s ease','background-color': activeColorBackground, opacity: activeOpacity, 'z-index': activeZIndex }">
      <transition name="fade-opacity-lazy">
        <business-screen v-if="activeBusiness"
                         @changeActiveContent="changeActiveContent"
                         @blockNextSlide="blockNextSlide"
                         @blockPrevSlide="blockPrevSlide"/>
      </transition>
    </swiper-slide>
    <swiper-slide :style="{'transition': 'all .5s ease','background-color': activeColorBackground, opacity: activeOpacity, 'z-index': activeZIndex }">
      <transition name="fade-opacity-lazy">
        <structure-screen v-if="activeStructure"
                          @changeActiveContentRout="changeActiveContentRout"
                          @changeActiveContent="changeActiveContent"/>
      </transition>
    </swiper-slide>
    <swiper-slide :style="{'transition': 'all .5s ease','background-color': activeColorBackground, opacity: activeOpacity, 'z-index': activeZIndex }">
      <transition name="fade-opacity-lazy">
        <team-screen v-if="activeTeam"
                     @changeActiveContentRout="changeActiveContentRout"
                     @changeActiveContent="changeActiveContent"
                     @blockNextSlide="blockNextSlide"
                     @blockPrevSlide="blockPrevSlide"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-1">
        <project-screen v-if="activeProject"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <partners-screen v-if="activePartners"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <footer-screen v-if="activeFooter"/>
      </transition>
    </swiper-slide>
  </swiper>
</template>

<script>

import NavigationScreen from "@/components/features/NavigationScreen.vue";
import StartScreen from "@/components/features/main-page/StartScreen.vue";
import {EffectFade, Mousewheel} from "swiper";
import {mapActions} from "vuex";
import BusinessScreen from "@/components/features/main-page/BusinessScreen.vue";
import StructureScreen from "@/components/features/main-page/StructureScreen.vue";
import TeamScreen from "@/components/features/main-page/TeamScreen.vue";
import ProjectScreen from "@/components/features/main-page/ProjectScreen.vue";
import FooterScreen from "@/components/features/main-page/FooterScreen.vue";
import PartnersScreen from "@/components/features/main-page/PartnersScreen.vue";
import ButtonArrow from "@/components/shared/ButtonArrow.vue";
import IconArrow from "@/components/shared/IconArrow.vue";
import {set} from "vue-load-image";

export default {
  name: "home-page",
  components: {
    IconArrow,
    ButtonArrow,
    PartnersScreen,
    FooterScreen,
    ProjectScreen,
    TeamScreen,
    StructureScreen,
    BusinessScreen,
    StartScreen,
    NavigationScreen},
  data() {
    return {
      activeAnimation: "fade",
      activeColorBackground: 'rgba(0,0,0,0)',
      imgContentLight: 'White_9-16_1080х1920_30fps.webm',
      imgContentLightMP4: 'White_9-16_1080х1920_30fps.mp4',
      imgContentDark: 'Dark_9-16_1080х1920_30fps.webm',
      imgContentDarkMP4: 'Dark_9-16_1080х1920_30fps.mp4',
      mainSwiper: '',
      activeIndex: 0,
      modules: [EffectFade, Mousewheel],
      activeZIndex: 0,
      activeOpacity: 0,
      timerZ: null,
      blockSwiperPrev: false,
      blockSwiperNext:false,

      activeNavigation: false,
      activeBusiness: false,
      activeStructure: false,
      activeTeam: false,
      activeProject: false,
      activePartners: false,
      activeFooter: false,
      activeStart: false,

      activeContentSlider: false,

      blockMountSwiper: true
    }
  },
  mounted() {
    if(window.innerWidth < 769) {
      setTimeout(() => {
        this.checkRoute();
        this.blockMountSwiper = false
        this.checkActiveScreen();
        this.checkBlockSwiper();
      }, 250)
    } else {
      this.checkRoute();
      this.blockMountSwiper = false
      this.checkActiveScreen();
      this.checkBlockSwiper();
    }

  },

  computed: {
    activeSlideController() {
      return this.$store.state.activeSlideController;
    },

    activeArrowMobile() {
      if(this.activeMobile) {
        if(!Boolean(localStorage.getItem('cookieActive'))) {
          return false
        }
        if(this.activeIndex === 6) {
          return false
        }
        return !this.activeContentSlider;
      } else {
        return false
      }
    }
  },


  watch: {
    activeBlockSwiper() {
      this.checkBlockSwiper();
    },

    '$route'(){
      if(this.$route.name.includes('main') || this.$route.name.includes('home')) {
        if(!this.$route.name.includes('type-division')) {
          if(!this.$route.name.includes('business') && !this.$route.name.includes('management')) {
            this.blockSwiperPrev = false;
            this.blockSwiperNext = false;
            this.checkBlockSwiperAllowSlideNext();
          }
          if(this.activeSlideController) {
            this.blockSwiperPrev = false;
            this.blockSwiperNext = false;
            this.checkBlockSwiperAllowSlideNext();
          }
          this.checkRouteSpeed(500)
          this.checkActiveScreen();
      }
      } else {
        this.checkRouteSpeed(500)
        this.checkActiveScreen();
      }
    }
  },
  methods: {
    ...mapActions(['ACTIVE_MAIN_INDEX', 'ACTIVE_BLOCK_SWIPER']),
    checkRoute() {
      this.checkRouteSpeed(0)
    },

    changeActiveContent(state) {
      this.activeContentSlider = state
      this.blockSwiperPrev = state;
      this.blockSwiperNext = state;
      this.checkBlockSwiperAllowSlideNext();
    },

    changeActiveContentRout(state) {
      this.changeActiveContent(state)
      this.checkRouteSpeed(500)
      this.checkActiveScreen();
    },

    checkRouteSpeed(speed) {
      if(window.innerWidth < 769) {
        this.mainSwiper.slideTo(this.$route.meta.idSlideMobile, speed, false)
      } else {
        this.mainSwiper.slideTo(this.$route.meta.idSlide, speed, false)
      }
    },

    checkBlockSwiper() {
      if(this.activeBlockSwiper) {
        this.mainSwiper.disable();
      } else {
        this.mainSwiper.enable();
      }
    },

    checkActiveScreen() {
        this.hideScreen();
        if(window.innerWidth > 769) {
          switch (this.activeIndex) {
            case 0:
              this.activeStart = true
              break;
            case 1:
              this.activeNavigation = true
              break;
            case 2:
              this.activeBusiness = true
              break;
            case 3:
              this.activeStructure = true
              break;
            case 4:
              this.activeTeam = true
              break;
            case 5:
              this.activeProject = true
              break;
            case 6:
              this.activePartners = true
              break;
            case 7:
              this.activeFooter = true
              break;
          }
        } else {
          switch (this.activeIndex) {
            case 0:
              this.activeStart = true
              break;
            case 1:
              this.activeBusiness = true
              break;
            case 2:
              this.activeStructure = true
              break;
            case 3:
              this.activeTeam = true
              break;
            case 4:
              this.activeProject = true
              break;
            case 5:
              this.activePartners = true
              break;
            case 6:
              this.activeFooter = true
              break;
          }
        }

    },

    hideScreen() {
      this.activeStart = false
      this.activeNavigation = false
      this.activeBusiness = false
      this.activeStructure = false
      this.activeTeam = false
      this.activeProject = false
      this.activePartners = false
      this.activeFooter = false
    },

    onSwiperMain(swiper) {
      clearTimeout(this.timerZ)
      this.ACTIVE_BLOCK_SWIPER(true)
      this.activeIndex = swiper.activeIndex;
      if(this.activeMobile) {
        this.ACTIVE_MAIN_INDEX(this.activeIndex)
        if(this.activeIndex > 0 && this.activeIndex < 4) {
          this.activeColorBackground = 'rgba(0,0,0,0)'
          this.activeOpacity = 1
          this.timerZ = setTimeout(() => {
            this.activeZIndex = 2;
          }, 500)
        } else {
          this.activeColorBackground = 'var(--color-background)'
          this.activeOpacity = 0
          this.timerZ = setTimeout(() => {
            this.activeZIndex = 0;
          }, 500)
        }
      } else {
        if(this.activeIndex < 2) {
          this.ACTIVE_MAIN_INDEX(-1)
        } else {
          this.ACTIVE_MAIN_INDEX(this.activeIndex - 2)
        }
      }
      this.$nextTick(() => {
        this.ACTIVE_BLOCK_SWIPER(false)
      })
      if(!this.blockMountSwiper) {
        if(this.$route.name.includes('main') || this.$route.name.includes('home')) {
          this.changeMainPageSlide(this.activeIndex)
        }
      }
    },

    slideNext() {
      this.mainSwiper.slideNext();
    },

    changeSettingSwiper(swiper) {
      this.mainSwiper = swiper
    },

    blockPrevSlide(state) {
      this.blockSwiperPrev = state;
      this.checkBlockSwiperAllowSlideNext();
    },

    blockNextSlide(state) {
      this.blockSwiperNext = state;
      this.checkBlockSwiperAllowSlideNext();
    },

    checkBlockSwiperAllowSlideNext() {
      this.mainSwiper.allowSlideNext = !this.blockSwiperNext;
      this.mainSwiper.allowSlidePrev = !this.blockSwiperPrev;
    },

  }

}
</script>

<style scoped lang="scss">
.icon-arrow-container {
  position: absolute;
  z-index: 900;
  bottom: 18px;
  left: 50%;
}
.background-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  &.active {
    .img-container {
      & video {
        opacity: 1;
      }
    }
  }
  .img-container {
    width: 100%;
    height: 100%;

    & video {
      opacity: 0;
      transition: opacity .6s ease;
      //will-change: mix-blend-mode;
      //mix-blend-mode: screen;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}
</style>