<template>
  <swiper @slide-change="onSwiperMain"
          @swiper="changeSettingSwiper"
          :slides-per-view="1"
          :modules="modules"
          class="swiper"
          :speed="1000"
          :breakpoints="
            {
              0: {
                allowTouchMove: true,
              },
              1600: {
                allowTouchMove: false
              }
            }"
          :mousewheel="true"
          direction="vertical"
          :effect="'fade'">

      <icon-arrow v-if="activeMobile"
                  :class="{'active' : activeArrowMobile}"
                  @click="mainSwiper.slideNext()"
                  direction="bottom"/>

    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <philosophy-screen v-if="activePhilosophy"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <mission-screen v-if="activeMission"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <strategy-screen v-if="activeStrategy" :type-management="'founders'"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <values-screen v-if="activeValues" @blockNextSlide="blockNextSlide" @blockPrevSlide="blockPrevSlide"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <history-screen v-if="activeHistory" @blockNextSlide="blockNextSlide" @blockPrevSlide="blockPrevSlide"/>
      </transition>
    </swiper-slide>
    <swiper-slide>
      <transition name="fade-opacity-lazy">
        <subbrands-screen v-if="activeSubbrand"/>
      </transition>
    </swiper-slide>
  </swiper>
</template>

<script>

import {EffectFade, Mousewheel} from "swiper";
import {mapActions} from "vuex";
import PhilosophyScreen from "@/components/features/sub-page/PhilosophyScreen.vue";
import MissionScreen from "@/components/features/sub-page/MissionScreen.vue";
import StrategyScreen from "@/components/features/sub-page/StrategyScreen.vue";
import ValuesScreen from "@/components/features/sub-page/ValuesScreen.vue";
import HistoryScreen from "@/components/features/sub-page/HistoryScreen.vue";
import SubbrandsScreen from "@/components/features/sub-page/SubbrandsScreen.vue";
import IconArrow from "@/components/shared/IconArrow.vue";

export default {
  name: "sub-page",
  components: {
    IconArrow,
    SubbrandsScreen,
    HistoryScreen,
    ValuesScreen,
    StrategyScreen,
    MissionScreen,
    PhilosophyScreen},
  data() {
    return {
      mainSwiper: '',
      activeIndex: 0,
      modules: [EffectFade, Mousewheel],

      activePhilosophy: false,
      activeMission: false,
      activeStrategy: false,
      activeValues: false,
      activeHistory: false,
      activeSubbrand: false,

      blockMountSwiper: true,

      blockSwiperPrev: false,
      blockSwiperNext: false
    }
  },
  mounted() {
    this.checkMountRoute();
    this.blockMountSwiper = false
    this.checkActiveScreen();
    this.checkBlockSwiper();
  },

  computed: {
    activeSlideController() {
      return this.$store.state.activeSlideController;
    },

    activeArrowMobile() {
      if(this.activeMobile) {
        if(this.activeIndex === 5) {
          return false
        }
        if(this.blockSwiperNext) {
          return false
        }
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    activeBlockSwiper() {
      this.checkBlockSwiper();
    },

    '$route'(){
      if(this.$route.name.includes('brand')) {
        if (!this.$route.name.includes('history') && !this.$route.name.includes('values')) {
          this.blockSwiperPrev = false;
          this.blockSwiperNext = false;
          this.checkBlockSwiperAllowSlideNext();
        }
        if (this.activeSlideController) {
          this.blockSwiperPrev = false;
          this.blockSwiperNext = false;
          this.checkBlockSwiperAllowSlideNext();
        }
        this.mainSwiper.slideTo(this.$route.meta.idSlide, 500, false)
        this.checkActiveScreen();
      }
    }
  },
  methods: {
    ...mapActions(['ACTIVE_SUB_INDEX', 'ACTIVE_BLOCK_SWIPER']),
    checkMountRoute() {
      this.mainSwiper.slideTo(this.$route.meta.idSlide, 0, false)
    },

    checkBlockSwiper() {
      if(this.activeBlockSwiper) {
        this.mainSwiper.disable();
      } else {
        this.mainSwiper.enable();
      }
    },

    checkActiveScreen() {
      this.hideScreen();
      switch (this.activeIndex) {
        case 0:
          this.activePhilosophy = true
          break;
        case 1:
          this.activeMission = true
          break;
        case 2:
          this.activeStrategy = true
          break;
        case 3:
          this.activeValues = true
          break;
        case 4:
          this.activeHistory = true
          break;
        case 5:
          this.activeSubbrand = true
          break;
      }
    },

    hideScreen() {
      this.activePhilosophy = false
      this.activeMission = false
      this.activeStrategy = false
      this.activeValues = false
      this.activeHistory = false
      this.activeSubbrand = false
    },


    onSwiperMain(swiper) {
      this.ACTIVE_BLOCK_SWIPER(true)
      this.activeIndex = swiper.activeIndex;
      this.ACTIVE_SUB_INDEX(this.activeIndex)
      if(!this.blockMountSwiper) {
        this.changeSubPageSlide(this.activeIndex)
      }
      this.$nextTick(() => {
        this.ACTIVE_BLOCK_SWIPER(false)
      })
    },

    changeSettingSwiper(swiper) {
      this.mainSwiper = swiper
    },

    blockPrevSlide(state) {
      this.blockSwiperPrev = state;
      this.checkBlockSwiperAllowSlideNext();
    },

    blockNextSlide(state) {
      this.blockSwiperNext = state;
      this.checkBlockSwiperAllowSlideNext();
    },

    checkBlockSwiperAllowSlideNext() {
      this.mainSwiper.allowSlideNext = !this.blockSwiperNext;
      this.mainSwiper.allowSlidePrev = !this.blockSwiperPrev;
    },
  }

}
</script>

<style scoped  lang="scss">
.icon-arrow-container {
  position: absolute;
  z-index: 900;
  bottom: 18px;
  left: 50%;
  opacity: 0;
  transition: all .6s ease;
  --strategy-arrow: 0px;
  pointer-events: none;

  &.active {
    pointer-events: auto;
    opacity: 1;
  }
}

</style>