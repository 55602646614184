<template>
  <div class="mission-screen-container main-container">
    <transition name="fade-opacity-1">
      <button-title-mobile v-if="activeMobile && activeTitle"
                           :active-button="false"
                           :main-title="$t('breadCrumb.dynamicTitle.mission.staticTitle')"/>
    </transition>

    <card-text-info :paragraph-info="$tm('missionScreen.mainParagraph')"
                    :active-static-title="true"
                    @endAnimation="endAnimation"
                    :main-title="$t('missionScreen.mainTitle')"/>
    <transition name="fade-opacity-1">
      <div class="img-container" v-if="activeImg">
        <div class="element-img-container">
          <img v-if="activeColor" :src="getUrlMission('vector-dark.svg')" alt="element">
          <img v-else :src="getUrlMission('vector-light.svg')" alt="element">
        </div>
        <img v-if="activeImg" :src="getUrlMission('background.webp')" alt="img">
      </div>
    </transition>
  </div>
</template>

<script>
import CardTextInfo from "@/components/entities/CardTextInfo.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
import IconArrow from "@/components/shared/IconArrow.vue";

export default {
  name: "MissionScreen",
  components: {IconArrow, ButtonTitleMobile, CardTextInfo},
  data() {
    return {
      activeImg: false,
      activeTitle: false
    }
  },
  mounted() {
    this.activeTitle = true
    if(!this.activeMobile) {
      setTimeout(() => {
        this.activeImg = true
      }, 500)
    }

  },

  methods: {
    endAnimation() {
      setTimeout(() => {
        this.activeImg = true
      }, 500)
    }
  }
}

</script>

<style scoped lang="scss">
.mission-screen-container {
  flex-direction: row;

  .img-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    overflow: hidden;
    align-self: center;

    .element-img-container {
      position: absolute;
      bottom: -1%;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 3024px){
  .mission-screen-container {
    padding-right: 120px;

    .img-container {
      border-radius: 311.72px 155.86px 77.93px 38.965px;
      max-width: 800px;
      max-height: 984px;

      .element-img-container {
        max-width: 563px;
        max-height: 825px;
      }
    }
  }
}



@media (max-width: 1920px) {
  .mission-screen-container {
    padding-right: 100px;
    .img-container {
      border-radius:  256px 128px 64px 32px;
      max-width: 657px;
      max-height: 808px;

      .element-img-container {
        max-width: 462px;
        max-height: 676px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .mission-screen-container {
    padding-right: 100px;
    .img-container {
      border-radius:  179.892px 89.946px 44.973px 22.486px;
      max-width: 462px;
      max-height: 568px;

      .element-img-container {
        max-width: 325px;
        max-height: 476px;
      }
    }
  }
}
@media (max-height: 800px) {
  .mission-screen-container {
    padding-right: 60px;
    .img-container {
      border-radius:  136.072px 68.036px 34.018px 17.009px;
      max-width: 349px;
      max-height: 429px;
      .element-img-container {
        max-width: 246px;
        max-height: 360px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .mission-screen-container {
    padding-right: 100px;
    .img-container {
      border-radius:  179.892px 89.946px 44.973px 22.486px;
      max-width: 462px;
      max-height: 568px;
      .element-img-container {
        max-width: 325px;
        max-height: 476px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .mission-screen-container {
    padding-right: 60px;
    .img-container {
      border-radius:  136.072px 68.036px 34.018px 17.009px;
      max-width: 349px;
      max-height: 429px;
      .element-img-container {
        max-width: 246px;
        max-height: 360px;
      }
    }
  }
}
@media (max-width: 769px) {
  .mission-screen-container {
    flex-direction: column;
    padding: var(--burger-height) 0 var(--main-padding-bottom);

    .card-text-container {
      justify-content: flex-start;
    }
    .main-title-container {
      padding-left: var(--controller-padding);
    }

    .img-container {
      border-radius: 68px 36px 18px 9px;
      max-height: 208px;
      min-height: 208px;
      max-width: 169px;
      min-width: 169px;
      margin-bottom: 20px;

      .element-img-container {
        max-width: 118px;
        max-height: 175px;
        min-height: 175px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .mission-screen-container {

    .img-container {
      border-radius: 88.061px 44.03px 22.015px 11.008px;
      max-height: 278px;
      min-height: 278px;
      max-width: 226px;
      min-width: 226px;
      margin-bottom: 24px;

      .element-img-container {
        max-width: 158px;
        max-height: 232px;
        min-height: 232px;
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .mission-screen-container {
    .img-container {
      border-radius: 60px 28px 14px 6px;
      max-height: 166px;
      min-height: 166px;
      max-width: 148px;
      min-width: 148px;
      margin-bottom: 0;

      .element-img-container {
        max-width: 104px;
        max-height: 140px;
        min-height: 140px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .mission-screen-container {
    .img-container {
      border-radius: 68px 36px 18px 9px;
      max-height: 208px;
      min-height: 208px;
      max-width: 184px;
      min-width: 184px;
      margin-bottom: 0;

      .element-img-container {
        max-width: 118px;
        max-height: 175px;
        min-height: 175px;
      }
    }
  }
}


@media (max-width: 320px) {
  .mission-screen-container {
    .img-container {
      border-radius: 60px 28px 14px 6px;
      max-height: 126px;
      min-height: 126px;
      max-width: 108px;
      min-width: 108px;
      margin-bottom: 0;

      .element-img-container {
        max-width: 84px;
        max-height: 100px;
        min-height: 100px;
      }
    }
  }
}

@media (min-width: 3025px) {
  .mission-screen-container {
    padding-right: 256px;

    .img-container {
      border-radius: 522.865px 261.432px 130.716px 65.358px;
      max-width: 1342px;
      max-height: 1650px;

      .element-img-container {
        max-width: 943px;
        max-height: 1362px;
      }
    }
  }
}
</style>