<template>
  <div class="card-text-container">
    <transition name="fade-opacity-1">
      <main-title v-if="activeTitle && activeStaticTitle">
        {{ mainTitle }}
      </main-title>
    </transition>

    <div class="text-info-container">
        <main-paragraph v-for="(item, index) in paragraphInfo"
                        :class="{'active-text' : isVisible(index),
                        'active-padding-text' : activePaddingText && index === 0,
                        'active-padding-text-second' : activePaddingText && index === 1}"
                        :key="index">
          {{ item }}
        </main-paragraph>
    </div>

    <button-arrow v-if="activeButton"
                  @click="clickActive"
                  :class="{'active-button' : isVisible(this.paragraphInfo.length),
                  'active-left' : directionActive === 'left'}"
                  :direction-active="directionActive"
                  :active-text="activeText"
                  :activeEngStatic="activeEngStatic"/>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import IconArrow from "@/components/shared/IconArrow.vue";
import ButtonArrow from "@/components/shared/ButtonArrow.vue";

export default {
  name: "CardTextInfo",
  components: {ButtonArrow, IconArrow, MainParagraph, MainTitle},
  props: {

    activeAnimation: {
      type: Boolean,
      default: true
    },

    activePaddingText: {
      type: Boolean,
      default: false
    },

    mainTitle: {
      type: String
    },
    paragraphInfo: {
      type: Array,
      required: true
    },
    activeButton: {
      type: Boolean,
      default: false
    },
    activeText: {
      type: String
    },
    activeStaticTitle: {
      type: Boolean,
      default: true
    },
    activeEngStatic: {
      type: Boolean,
      default: false
    },
    directionActive: {
      type: String,
      default: 'right'
    }
  },
  data() {
    return {
      activeItem: -1,
      activeTitle: false
    }
  },
  mounted() {
    if(this.activeAnimation) {
      if(this.activeStaticTitle) {
        setTimeout(() => {
          this.activeTitle = true
          setTimeout(() => {
            this.showNextItem()
          }, 250)
        }, 500)
      } else {
        this.showNextItem()
      }
    } else {
      this.activeTitle = true
      this.activeItem = this.paragraphInfo.length

    }

  },
  methods: {
    showNextItem() {
      if (this.activeItem < this.paragraphInfo.length + 1) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
          if(this.activeItem === this.paragraphInfo.length - 1) {
            this.$emit('endAnimation')
          }
        }, 250)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },

    clickActive() {
      this.$emit('clickActive')
    }
  }
}
</script>

<style scoped lang="scss">
  .card-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .main-title-container {
      white-space: pre-wrap;
    }

    .text-info-container {
      display: flex;
      flex-direction: column;
      text-align: justify;

      .main-paragraph-container {
        transition: opacity 1s ease;
        opacity: 0;
        &.active-text {
          opacity: 1;
        }
      }
    }

    .sub-button-container {
      transition: opacity 1s ease;
      opacity: 0;
      cursor: default;
      &.active-button {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  @media (max-width: 3024px){
    .card-text-container {
      max-width: 940px;
      padding: 0 128px;
      row-gap: 48px;

      .text-info-container {
        row-gap: 32px;
      }

      .sub-button-container {
        &.active-left {
          margin-left: 42px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .card-text-container {
      max-width: 868px;
      padding: 0 128px;
      row-gap: 40px;

      .text-info-container {
        row-gap: 30px;
      }


      .sub-button-container {
        &.active-left {
          margin-left: 36px;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .card-text-container {
      max-width: 568px;
      padding: 0 96px;
      row-gap: 32px;

      .text-info-container {
        row-gap: 24px;
      }


      .sub-button-container {
        &.active-left {
          margin-left: 30px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .card-text-container {
      max-width: 476px;
      padding: 0 64px;
      row-gap: 20px;

      .text-info-container {
        row-gap: 16px;
      }

      .sub-button-container {
        &.active-left {
          margin-left: 22px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-text-container {
      max-width: 568px;
      padding: 0 96px;
      row-gap: 32px;

      .text-info-container {
        row-gap: 24px;
      }


      .sub-button-container {
        &.active-left {
          margin-left: 30px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .card-text-container {
      max-width: 476px;
      padding: 0 64px;
      row-gap: 20px;

      .text-info-container {
        row-gap: 16px;
      }


      .sub-button-container {
        &.active-left {
          margin-left: 22px;
        }
      }
    }
  }
  @media (max-width: 769px) {
    .card-text-container {
      max-width: 100%;
      padding: 0 14px;
      row-gap: 14px;

      .text-info-container {
        row-gap: 12px;

        .main-paragraph-container {
          &.active-padding-text {
            padding-right: 128px;
          }

          &.active-padding-text-second {
            padding-right: 196px;
          }
        }



        .main-paragraph-container {
          line-height: 130%;
        }
      }


      .sub-button-container {
        &.active-left {
          margin-left: 22px;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-text-container {
      max-width: 100%;
      padding: 0 16px;
      row-gap: 16px;

      .text-info-container {
        row-gap: 12px;
      }


      .sub-button-container {
        &.active-left {
          margin-left: 22px;
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    .card-text-container {
      max-width: 100%;
      padding: 0 14px;
      row-gap: 14px;

      .text-info-container {
        row-gap: 8px;
      }


      .sub-button-container {
        &.active-left {
          margin-left: 22px;
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .card-text-container {
      max-width: 1790px;
      padding: 0 160px;
      row-gap: 84px;

      .text-info-container {
        row-gap: 64px;
      }


      .sub-button-container {
        &.active-left {
          margin-left: 80px;
        }
      }
    }
  }

</style>