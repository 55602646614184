<template>
  <div class="header-controller-container">
    <block-logo @routToHomePage="routToHomePage"/>
    <div class="right-info-content">
      <button-arrow :direction-active="'left'"
                    @click="routToHomePage"
                    :active-text="$t('innovationScreen.buttonReturn')"/>
      <toggle-theme-local :name-check="'footer'"/>
    </div>
  </div>
</template>

<script>
import BlockLogo from "@/components/entities/BlockLogo.vue";
import {mapActions} from "vuex";
import ButtonArrow from "@/components/shared/ButtonArrow.vue";
import ToggleThemeLocal from "@/components/entities/ToggleThemeLocal.vue";

export default {
  name: "HeaderController",
  components: {ToggleThemeLocal, ButtonArrow, BlockLogo},
  methods: {
    ...mapActions(['ACTIVE_INDEX_PAGE']),
    routToHomePage() {
        this.ACTIVE_INDEX_PAGE(-1)
        this.$router.push({name: 'home-navigation-page'})
    }
  }
}
</script>

<style scoped lang="scss">
  .header-controller-container {
    z-index: 900;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--header-controller);

    .right-info-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  @media (max-width: 3024px) {
    .header-controller-container {
      padding: 64px 90px 32px;

      .right-info-content {
        column-gap: 80px;
      }
    }
  }

  @media (max-width: 1920px) {
    .header-controller-container {
       padding: 48px 68px 24px;
      .right-info-content {
        column-gap: 64px;
      }
    }
  }

  @media (max-width: 1600px) {
    .header-controller-container {
      padding: 32px 54px 16px;
      .right-info-content {
        column-gap: 64px;
      }
    }
  }
  @media (max-height: 800px) {
    .header-controller-container {
      padding: 24px 36px 12px;
      .right-info-content {
        column-gap: 64px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .header-controller-container {
      padding: 32px 54px 16px;
      .right-info-content {
        column-gap: 64px;
      }
    }
  }

  @media (max-width: 1133px) {
  }
  @media (max-width: 769px) {
    //iPhone 14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //iPhone Plus
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .header-controller-container {
      padding: 128px 144px 64px;
      .right-info-content {
        column-gap: 128px;
      }
    }
  }

</style>