<template>
  <main-controller :active-index="activeControllerIndex"
                   :active-menu-container="activeMenuController"
                   @changeIndex="changeIndex"
                   :info-menu="activeControllerInfo"/>
</template>

<script>
import MainController from "@/components/widgets/MainController.vue";
import {mapActions} from "vuex";

export default {
  name: "MainPageController",
  components: {MainController},
  computed: {

    mainPageController() {
      return this.$tm('mainController')
    },
    subPageController() {
      return this.$tm('subController')
    },

    activeControllerInfo() {
      switch (this.activeIndexPage) {
        case 0:
          return this.mainPageController;
        case 1:
          return this.subPageController;
      }
    },

    activeControllerIndex() {
      switch (this.activeIndexPage) {
        case 0:
          return this.activeMainIndex;
        case 1:
          return this.activeSubIndex;
      }
    },

    activeMainIndex() {
      return this.$store.state.activeMainIndex;
    },

    activeSubIndex() {
      return this.$store.state.activeSubIndex;
    },

    activeIndexPage() {
      return this.$store.state.activeIndexPage;
    },

    activeMenuController() {
      if(this.activeIndexPage === -1 || this.activeIndexPage === 2) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions(['ACTIVE_MAIN_INDEX', 'ACTIVE_SUB_INDEX', 'ACTIVE_SLIDE_CONTROLLER']),
    changeIndex(id, state) {
      this.ACTIVE_SLIDE_CONTROLLER(true)
      console.log(this.activeMainIndex, id)
      switch (this.activeIndexPage) {
        case 0:
          if(state && !this.activeMobile) {
            this.ACTIVE_MAIN_INDEX(id + 2)
            this.changeMainPageSlide(id + 2)
          } else {
            this.ACTIVE_MAIN_INDEX(id)
            this.changeMainPageSlide(id)
          }
          break;
        case 1:
          this.ACTIVE_SUB_INDEX(id)
          this.changeSubPageSlide(id)
          break;
      }
      setTimeout(() => {
        this.ACTIVE_SLIDE_CONTROLLER(false)
      }, 100)
    },
  }
}
</script>

<style scoped>

</style>