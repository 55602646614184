<template>
  <p class="mini-paragraph-container">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MiniParagraph"
}
</script>

<style scoped lang="scss">

.mini-paragraph-container {
  line-height: 120%;
  color: var(--color-main-text);
  white-space: pre-wrap;
}

@media (max-width: 3024px){
  .mini-paragraph-container {
    font-size: 18px;
  }
}


@media (max-width: 1920px) {
  .mini-paragraph-container {
    font-size: 14px;
  }
}


@media (max-width: 1600px) {
  .mini-paragraph-container {
    font-size: 12px;
  }
}
@media (max-height: 800px) {
  .mini-paragraph-container {
    font-size: 10px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .mini-paragraph-container {
    font-size: 12px;
  }
}

@media (max-width: 1133px) {
  .mini-paragraph-container {
    font-size: 10px;
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .mini-paragraph-container {
    font-size: 28px;
  }
}
</style>