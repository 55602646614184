<template>
  <div class="philosophy-screen-container main-container">

    <transition name="fade-opacity-1">
      <button-title-mobile v-if="activeMobile && activeTitle"
                           :active-button="false"
                           :main-title="$t('breadCrumb.dynamicTitle.philosophy.staticTitle')"/>
    </transition>

     <card-text-info :paragraph-info="$tm('philosophyScreen.mainParagraph')"
                     :active-static-title="!activeMobile"
                     @endAnimation="endAnimation"
                     :main-title="$t('philosophyScreen.mainTitle')"/>

    <div class="img-container" v-if="!activeMobile">
      <transition name="fade-translate-x-revert">
        <img v-if="activeImg" :src="getUrlPhilosophy('element.webp')" alt="img">
      </transition>
    </div>
    <div class="img-container" v-if="activeMobile">
      <transition name="fade-opacity-1">
       <img  v-if="activeImg"  :src="getUrlPhilosophy('mobile.webp')" alt="img">
      </transition>
    </div>
  </div>
</template>

<script>
import CardTextInfo from "@/components/entities/CardTextInfo.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";

export default {
  name: "PhilosophyScreen",
  components: {ButtonTitleMobile, CardTextInfo},
  data() {
    return {
      activeImg: false,
      activeTitle: false
    }
  },
  mounted() {
    this.activeTitle = true
    if(!this.activeMobile) {
      setTimeout(() => {
        this.activeImg = true
      }, 500)
    }
  },
  methods: {
    endAnimation() {
      this.activeImg = true
    }
  }

}
</script>

<style scoped lang="scss">
  .philosophy-screen-container {
    flex-direction: row;

    .img-container {
      width: 100%;
      height: var(--calc-height);
      position: relative;
      top: calc(-1 * var(--main-padding-top));


      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 3024px){
    .philosophy-screen-container {
      padding-right: 128px;

      .img-container {
        max-width: 863px;
      }
    }
  }



  @media (max-width: 1920px) {
    .philosophy-screen-container {
      padding-right: 96px;

      .img-container {
        max-width: 756px;
      }
    }
  }

  @media (max-width: 1600px) {
    .philosophy-screen-container {
      padding-right: 64px;

      .img-container {
        max-width: 582px;
      }
    }
  }
  @media (max-height: 800px) {
    .philosophy-screen-container {
      padding-right: 48px;

      .img-container {
        max-width: 494px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .philosophy-screen-container {
      padding-right: 64px;

      .img-container {
        max-width: 582px;
      }
    }
  }

  @media (max-width: 1133px) {
    .philosophy-screen-container {
      padding-right: 48px;

      .img-container {
        max-width: 494px;
      }
    }
  }
  @media (max-width: 769px) {
    .philosophy-screen-container {
      flex-direction: column;
      padding: var(--burger-height) 0 var(--main-padding-bottom);

      .card-text-container {
        justify-content: flex-start;
      }

      .main-title-container {
        padding-left: var(--controller-padding);
      }

      .img-container {
        top: 0;
        max-height: 185px;
        height: 100%;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .philosophy-screen-container {
      .img-container {
        max-height: 282px;
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .philosophy-screen-container {
      .img-container {
        max-height: 95px;
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .philosophy-screen-container {
      .img-container {
        max-height: 160px;
      }
    }
  }


  @media (max-width: 320px) {
    .philosophy-screen-container {
      .img-container {
        max-height: 60px;
      }
    }
  }

  @media (min-width: 3025px) {
    .philosophy-screen-container {
      padding-right: 160px;

      .img-container {
        max-width: 1385px;
      }
    }
  }

</style>