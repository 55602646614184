<template>
  <modal-frame-background>
    <transition name="fade-opacity-lazy">
      <slot v-if="!activeResult"/>
      <modal-frame class="result-container" @closeModalCross="closeModal" :active-title="activeTitle" v-else>
        <sub-paragraph>
          {{ activeText }}
        </sub-paragraph>
      </modal-frame>
    </transition>

  </modal-frame-background>
</template>

<script>
import ModalFrameBackground from "@/components/entities/ModalFrameBackground.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "ModalResult",
  components: {SubParagraph, ModalFrame, ModalFrameBackground},
  props: {
    activeTitle: {
      type: String,
      required: true
    },
    activeText: {
      type: String,
      required: true
    },
    activeResult: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped lang="scss">

.sub-paragraph-container {
  font-weight: 200;
}
@media (max-width: 3024px){
  .result-container {;
    row-gap: 64px;
    max-width: 746px;

    .sub-paragraph-container {
      font-size: 22px;
    }
  }
}


@media (max-width: 1920px) {
  .result-container {
    max-width: 640px;
    row-gap: 48px;

    .sub-paragraph-container {
      font-size: 18px;
    }
  }
}


@media (max-width: 1600px) {
  .result-container {
    row-gap: 32px;
    max-width: 480px;

    .sub-paragraph-container {
      font-size: 14px;
    }
  }
}
@media (max-height: 800px) {
  .result-container {
    max-width: 440px;
    row-gap: 24px;

    .sub-paragraph-container {
      font-size: 12px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .result-container {
    row-gap: 32px;
    max-width: 480px;
    .sub-paragraph-container {
      font-size: 14px;
    }
  }
}

@media (max-width: 1133px) {
  .result-container {
    max-width: 440px;
    row-gap: 24px;

    .sub-paragraph-container {
      font-size: 12px;
    }
  }
}
@media (max-width: 769px) {
  .result-container {
    border-radius: 0;
    border-width: 0;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    row-gap: 24px;

    .sub-paragraph-container {
      font-size: 12px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .result-container {
    row-gap: 80px;
    max-width: 1100px;
  }
}

</style>