<template>
  <div class="frame-modal-background-container blur-active"  @click.self="closeModal">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "ModalFrameBackground",
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped lang="scss">
.frame-modal-background-container {
      position: absolute;
      display: flex;
      justify-content: center;
      top:0;left:0;right:0;bottom: 0;
      width: var(--calc-width);
      height: var(--calc-height);
      z-index: 915;


}
</style>