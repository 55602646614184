<template>
  <div class="services-screen-container main-container" v-if="!activeMobile">
    <transition name="fade-opacity-lazy">
      <modal-consulting v-if="activeModal"
                        :active-text="{
                            mainTitle: this.$t('consultingModal.mainTitle'),
                            resultTitle: this.$t('consultingModal.resultTitle'),
                            resultText: this.$t('consultingModal.resultText'),
                        }"
                        @closeModal="changeStateModal"/>
    </transition>
    <div class="left-info-content">
      <div class="top-info-content">

        <transition name="fade-opacity-1">
        <div class="title-info-container"
             v-if="isVisible(0)">
                    <sub-paragraph :class="{'eng' : activeEng}">
                      {{ $t('divisionPage.complexTitle') }}
                    </sub-paragraph>
          <main-title>
            {{ activeDivisionService.mainTitle }}
          </main-title>
        </div>
        </transition>


        <transition name="fade-opacity-1">
          <div class="description-info-container"
               v-if="isVisible(1)">
            <main-paragraph>
              {{ activeDivisionService.description }}
            </main-paragraph>
          </div>
        </transition>
      </div>

      <div class="bottom-info-content">

        <transition name="fade-opacity-1">
                <sub-paragraph
                    :class="{'eng' : activeEng}"
                    v-show="isVisible(2)">
                  {{ $t('divisionPage.serviceTitle') }}
                </sub-paragraph>
        </transition>
        <div class="complex-title-container">
          <text-with-circle v-for="item in this.activeDivisionService.subInfo"
                            @click="changeIndex(item.id)"
                            :class="{'active-hover' : isVisible(item.id + 3)}"
                            :active-index="activeIndex"
                            :item-info="item"
                            :key="item.id">
            <main-paragraph :class="{'eng' : activeEng}">
              {{ item.mainTitle }}
            </main-paragraph>
          </text-with-circle>
        </div>
      </div>
    </div>

    <div class="right-info-content">
      <transition name="fade-translate-x-card" mode="out-in">
        <card-services :key="activeIndex"
                       v-if="isVisible(4)"
                       @changeStateModal="changeStateModal"
                       :info-card="activeDivisionSubService"/>
      </transition>
    </div>
  </div>

  <div class="services-screen-container main-container" v-else>

    <scroll-service-technologies-mobile :current-item="currentItem"
                                        @returnTo="returnTo"
                                        :active-index-scroll="activeDivisionSubService.id"
                                        :main-description="activeDivisionService.description"
                                        :sub-title="$t('divisionPage.serviceTitle')"
                                        :main-title="activeDivisionService?.mainTitleMobile || activeDivisionService.mainTitle"
                                        :scroll-info="activeDivisionService.subInfo"
                                        @changeIndex="changeIndex"/>


      <div class="bottom-info-content" >
        <transition name="fade-opacity-1" mode="out-in">
            <div class="info-text-container" v-if="isVisible(3)" :key="activeDivisionSubService">
              <main-paragraph class="main-title">
                {{ activeDivisionSubService.mainTitle }}
              </main-paragraph>

              <main-paragraph>
                {{ activeDivisionSubService?.descriptionMobile || activeDivisionSubService?.description  }}
              </main-paragraph>
            </div>
        </transition>
        <transition name="fade-opacity-1" mode="out-in">
        <button-arrow @click="changeStateModalConsulting"
                      v-if="isVisible(4)"
                      :direction-active="'right'"
                      :active-text="$t('divisionPage.contactButton')"/>
        </transition>
      </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import TextWithCircle from "@/components/shared/TextWithCircle.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import CardServices from "@/components/entities/CardServices.vue";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";
import {mapActions} from "vuex";
import ScrollMobileDivision from "@/components/entities/ScrollMobileDivision.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
import ButtonArrow from "@/components/shared/ButtonArrow.vue";
import ScrollServiceTechnologiesMobile from "@/components/entities/ScrollServiceTechnologiesMobile.vue";

export default {
  name: "ServiceScreen",
  components: {
    ScrollServiceTechnologiesMobile,
    ButtonArrow,
    ButtonTitleMobile,
    ScrollMobileDivision,
    ModalConsulting, CardServices, SubParagraph, TextWithCircle, MainParagraph, MainTitle},
  props: ['typeDivision', 'typeServices', 'typeSubServices'],
  data() {
    return {
      activeModal: false,
      currentItem: -1,

      timerThird: null,
      timerSecond: null,
      timerFirst: null
    }
  },
  computed: {
    activeDivisionService() {
      return this.$tm('divisionPage')[this.typeDivision].complexInfo.find(item => item.url === this.typeServices);
    },
    activeDivisionSubService() {
      return this.activeDivisionService.subInfo.find(item => item.url === this.typeSubServices);
    },
    activeIndex() {
      return this.activeDivisionSubService.id;
    }
  },

  mounted() {
    this.checkSlugTitle(this.activeDivisionService.mainTitle);
    this.timerFirst = setTimeout(() => {
      this.checkSlugTitle(this.activeDivisionSubService.mainTitle);
    }, 250)
    if(this.activeMobile) {
      this.showNextItem();
    } else {
      this.timerSecond = setTimeout(() => {
        this.showNextItem();
      }, 750)
    }
  },

  unmounted() {
    clearTimeout(this.timerThird)
    clearTimeout(this.timerFirst)
    clearTimeout(this.timerSecond)
  },

  watch: {
    activeDivisionSubService() {
      this.checkSlugTitle(this.activeDivisionSubService.mainTitle);
    }
  },
  methods: {
    ...mapActions(['ACTIVE_SLUG_TITLE', 'IS_ACTIVE_MODAL_CONSULTING']),

    checkSlugTitle(item) {
      this.ACTIVE_SLUG_TITLE(item)
    },

    changeIndex(id) {
      this.$router.push({name: "main-type-division-services-page", params:
            {
              typeDivision: this.typeDivision,
              typeServices: this.typeServices,
              typeSubServices: this.activeDivisionService.subInfo.find(item => item.id === id).url
            }
      })
    },

    returnTo() {
      this.$router.push({name: 'main-type-division-page', params: {typeDivision: this.typeDivision}})
    },

    showNextItem() {
      let time;
      if(this.activeMobile) {
        time = 500;
      } else {
        time = 150;
      }
      this.timerThird = setTimeout(() => {
        if (this.currentItem < this.activeDivisionService.subInfo.length + 3) {
          this.currentItem++
          this.showNextItem()
        }
      }, time)
    },
    isVisible(index) {
      return index <= this.currentItem
    },

    changeStateModal() {
      this.activeModal = !this.activeModal
    },

    changeStateModalConsulting() {
      this.IS_ACTIVE_MODAL_CONSULTING(true)
    }
  }
}
</script>

<style scoped lang="scss">
.services-screen-container {
  flex-direction: row;
  position: relative;

  .left-info-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .top-info-content {
      display: flex;
      flex-direction: column;

      .title-info-container {
        display: flex;
        flex-direction: column;
      }

      .description-info-container {
        display: flex;
        flex-direction: column;
        white-space: pre-wrap;

      }
    }

    .bottom-info-content {
      display: flex;
      flex-direction: column;

      &.height {
        .complex-title-container {
          .title-with-circle-container {
            cursor: default !important;
          }
        }
      }

      .complex-title-container {
        display: flex;
        flex-direction: column;


        .title-with-circle-container {
          opacity: 0;
          transition: opacity .6s ease;

          &.active-hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .right-info-content {
    width: fit-content;
    position: relative;
  }
}

@media (max-width: 3024px){
  .services-screen-container {

    .left-info-content {
      padding: 128px 0 0 64px;
      max-width: 924px;

      .top-info-content {
        row-gap: 48px;

        .title-info-container {
          row-gap: 10px;
        }

        .description-info-container {
          row-gap: 24px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 42px;

        .complex-title-container {
          row-gap: 20px;
        }
      }
    }
  }
}



@media (max-width: 1920px) {
  .services-screen-container {

    .left-info-content {
      padding: 96px 0 0 48px;
      max-width: 748px;

      .top-info-content {
        row-gap: 40px;

        .title-info-container {
          row-gap: 8px;
        }

        .description-info-container {
          row-gap: 20px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 36px;

        .complex-title-container {
          row-gap: 16px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .services-screen-container {

    .left-info-content {
      padding: 64px 0 0 32px;
      max-width: 582px;

      .top-info-content {
        row-gap: 32px;

        .title-info-container {
          row-gap: 6px;
        }

        .description-info-container {
          row-gap: 20px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 30px;

        .complex-title-container {
          row-gap: 12px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .services-screen-container {

    .left-info-content {
      padding: 48px 0 0 24px;
      max-width: 504px;

      .top-info-content {
        row-gap: 20px;

        .title-info-container {
          row-gap: 4px;
        }

        .description-info-container {
          row-gap: 20px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 22px;

        .complex-title-container {
          row-gap: 10px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .services-screen-container {

    .left-info-content {
      padding: 64px 0 0 32px;
      max-width: 582px;

      .top-info-content {
        row-gap: 32px;

        .title-info-container {
          row-gap: 6px;
        }

        .description-info-container {
          row-gap: 20px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 30px;

        .complex-title-container {
          row-gap: 12px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .services-screen-container {

    .left-info-content {
      padding: 48px 0 0 24px;
      max-width: 504px;

      .top-info-content {
        row-gap: 20px;

        .title-info-container {
          row-gap: 4px;
        }

        .description-info-container {
          row-gap: 20px;
        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 22px;

        .complex-title-container {
          row-gap: 10px;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .services-screen-container {
    padding: var(--burger-height) 0 var(--main-padding-bottom) 0;
    justify-content: flex-start;
    flex-direction: column;

    .bottom-info-content {
      padding: 24px 24px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .sub-button-container {
        align-self: flex-end;
        margin-right: 24px;
      }

      .info-text-container {
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        position: relative;

        .main-paragraph-container {
          text-align: justify;
          line-height: 130% !important;
        }

        .main-title {
          text-align: left;
          color: var(--color-main-title)
        }
      }

      .main-paragraph-container {
        position: relative;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .services-screen-container {

    .bottom-info-content {
      padding: 24px 24px 0;
      .info-text-container {
        row-gap: 12px;
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .main-paragraph-container {
    font-size: 10px;
  }
  .services-screen-container {

    .bottom-info-content {
      padding: 16px 16px 0;
      .info-text-container {
        row-gap: 8px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .main-paragraph-container {
    font-size: 12px;
  }
    .services-screen-container {

      .bottom-info-content {
        padding: 24px 24px 0;
        .info-text-container {
          row-gap: 12px;
        }
      }
    }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .services-screen-container {

    .left-info-content {
      padding: 320px 0 0 160px;
      max-width: 1800px;

      .top-info-content {
        row-gap: 84px;

        .title-info-container {
          row-gap: 12px;
        }

        .description-info-container {
          row-gap: 42px;

        }
      }

      .bottom-info-content {
        row-gap: 32px;
        padding-left: 80px;

        .complex-title-container {
          row-gap: 24px;
        }
      }
    }
  }

}

</style>