<template>
  <div class="check-box-container">
    <input :id="'toggler-'+nameCheck"
           :name="'toggler-'+nameCheck"
           type="checkbox" v-model="checkActive"
           @change="toggleTheme">
    <label :for="'toggler-'+nameCheck">
      <span class="toggler-on"/>
      <span class="toggler-off"/>
    </label>
  </div>
</template>

<script>
export default {
  name: "ToggleTheme",
  props: {
    nameCheck: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      checkActive: false,
      lightTheme: 'moon.svg',
      darkTheme: 'sun.svg',
    }
  },
  computed: {
    activeColor() {
      return this.$store.state.activeColor;
    }
  },
  mounted() {
    this.checkActive = !!this.activeColor;
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.check-box-container {
  transition: opacity .6s ease;
}

.check-box-container input {
  display: none;
}

.check-box-container label {
  display: block;
  position: relative;
  background: var(--color-card-division);
  cursor: pointer;
}

.check-box-container input:checked + label{
  background: var(--color-card-division);
}

.check-box-container label::after, .check-box-container label::before {
  display: block;
  border-radius: 50%;
  background: var(--color-background);
  content: '';
  animation-name: toggler-size;
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-play-state: running;
}

.check-box-container label::after {

}


.check-box-container {
  & label {

    &:after {
      filter: blur(var(--active-blur));
    }

    &:before {
      filter: blur(var(--active-blur));
    }

    &:hover {
      &:after {
        filter: blur(0px);
      }

      &:before {
        filter: blur(0px);
      }
    }
  }
}



.check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off, .check-box-container label::before, {
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out, opacity 0.15s ease-in-out, filter .6s ease;
}

.check-box-container input:checked + label::after, .check-box-container input:checked + label::before {
  animation-name: toggler-size2;
}

.check-box-container .toggler-on, .check-box-container .toggler-off {
  opacity: 1;
  z-index: 2;
  position: relative;
}

.check-box-container input:checked + label .toggler-off, .check-box-container input:not(:checked) + label .toggler-on {
  width: 0;
  height: 0;
  opacity: 0;
}



.check-box-container label::before {
  height: 100%;
  width: 100%;
}


.check-box-container label::after {
  height: 100%;
  width: 100%;
}



.check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
  z-index: 10;
}


.check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
  z-index: 10;
}

@media (max-width: 3024px){
  .check-box-container {
    width: 80px;
  }

  .check-box-container label {
    width: 80px;
    height: 40px;
    margin: 0 2px;
    border-radius: 10px;
  }

  .check-box-container label::before {
    border-radius: 6px;
    max-width: 24px;
    max-height: 24px;
  }


  .check-box-container label::after {
    border-radius: 6px;
    max-width: 32px;
    max-height: 32px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
    background: url(@/assets/icons/sun.svg) center 0/24px 23px no-repeat;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
    background: url(@/assets/icons/moon.svg) center 0/24px 23px no-repeat;
  }
}



@media (max-width: 1920px) {

  .check-box-container {
    width: 72px;
  }

  .check-box-container label {
    width: 72px;
    height: 36px;
    margin: 0 2px;
    border-radius: 9px;
  }

  .check-box-container label::before {
    max-width: 20px;
    max-height: 20px;
    border-radius: 5px;
  }


  .check-box-container label::after {
    max-width: 28px;
    max-height: 28px;
    border-radius: 5px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
    background: url(@/assets/icons/sun.svg) center 0/20px 19px no-repeat;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
    background: url(@/assets/icons/moon.svg) center 0/20px 19px no-repeat;
  }
}


@media (max-width: 1600px) {
  .check-box-container {
    width: 60px;
  }

  .check-box-container label {
    width: 60px;
    height: 30px;
    margin: 0 2px;
    border-radius: 12px;
  }

  .check-box-container label::before {
    border-radius: 4px;
    max-width: 18px;
    max-height: 18px;
  }


  .check-box-container label::after {
    border-radius: 4px;
    max-width: 24px;
    max-height: 24px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
    background: url(@/assets/icons/sun.svg) center 0/18px 17px no-repeat;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
    background: url(@/assets/icons/moon.svg) center 0/18px 17px no-repeat;
  }
}

@media (max-height: 800px) {
  .check-box-container {
    width: 48px;
  }

  .check-box-container label {
    width: 48px;
    height: 24px;
    margin: 0 2px;
    border-radius: 5px;
  }

  .check-box-container label::before {
    max-width: 14px;
    max-height: 14px;
    border-radius: 2px;
  }


  .check-box-container label::after {
    max-width: 18px;
    border-radius: 2px;
    max-height: 18px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
    background: url(@/assets/icons/sun.svg) center 0/14px 13px no-repeat;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
    background: url(@/assets/icons/moon.svg) center 0/14px 13px no-repeat;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .check-box-container {
    width: 60px;
  }

  .check-box-container label {
    width: 60px;
    height: 30px;
    margin: 0 2px;
    border-radius: 12px;
  }

  .check-box-container label::before {
    border-radius: 4px;
    max-width: 18px;
    max-height: 18px;
  }


  .check-box-container label::after {
    border-radius: 4px;
    max-width: 24px;
    max-height: 24px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
    background: url(@/assets/icons/sun.svg) center 0/18px 17px no-repeat;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
    background: url(@/assets/icons/moon.svg) center 0/18px 17px no-repeat;
  }
}
.toggle-local-container {
}
@media (max-width: 1133px) {
}
@media (max-width: 769px) {
  .check-box-container {
    width: 60px;
  }

  .check-box-container label {
    width: 60px;
    height: 30px;
    margin: 0 2px;
    border-radius: 7px;
  }

  .check-box-container label::before {
    max-width: 24px;
    max-height: 24px;
    border-radius: 4px;
    filter: blur(0px);
  }


  .check-box-container label::after {
    max-width: 24px;
    border-radius: 4px;
    max-height: 24px;
    filter: blur(0px);
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
    background: url(@/assets/icons/sun.svg) center 0/18px 24px no-repeat;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
    background: url(@/assets/icons/moon.svg) center 0/18px 24px no-repeat;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {


  .check-box-container {
    width: 96px;
  }

  .check-box-container label {
    width: 96px;
    height: 48px;
    margin: 0 2px;
    border-radius: 12px;
  }

  .check-box-container label::before {
    border-radius: 8px;
    max-width: 32px;
    max-height: 32px;
  }


  .check-box-container label::after {
    border-radius: 8px;
    max-width: 40px;
    max-height: 40px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
    background: url(@/assets/icons/sun.svg) center 0/32px 31px no-repeat;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
    background: url(@/assets/icons/moon.svg) center 0/32px 31px no-repeat;
  }
}


</style>