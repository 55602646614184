<template>
  <transition name="fade-translate-x-card" mode="out-in">
  <div class="consulting-card-container" v-if="activeConsultingCard">
    <p class="technologies-text-container"
       v-for="paragraph in servicesInfo.description">
      <transition name="fade-translate-x">
      <main-paragraph v-if="isVisible(paragraph.id)">
        {{ paragraph.description }}
        </main-paragraph>
      </transition>
    </p>
  </div>
  </transition>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";

export default {
  name: "CardConsulting",
  components: {MainParagraph},
  props: {
    servicesInfo: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      currentItem: -1,
      activeConsultingCard: false
    }
  },

  mounted() {
    this.activeConsultingCard = true
    this.showNextItem();
  },
  watch: {
    servicesInfo() {
      this.activeConsultingCard = false
      setTimeout(() => {
        this.activeConsultingCard = true
        this.currentItem = -1
        this.showNextItem();
      },500)
    }
  },
  methods: {
    showNextItem() {
      setTimeout(() => {
        if (this.currentItem < this.servicesInfo.description.length) {
          this.currentItem++
          this.showNextItem()
        }
      }, 200)
    },

    isVisible(index) {
      return index <= this.currentItem
    }
  }
}
</script>

<style scoped lang="scss">
  .consulting-card-container {
    display: flex;
    width: 100%;
    height: calc(100% + var(--main-padding-bottom));
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--color-card-division);
    position: relative;

    .technologies-text-container {
      color: var(--color-main-title);
      position: relative;

      .technologies-text {
        line-height: 150%;
        position: absolute;
      }
    }
  }

  @media (max-width: 3024px){
    .consulting-card-container {
      max-width: 576px;
      padding: 64px;
      border-radius: 64px 0 0 0;

      .technologies-text-container {
        height: 33px;
      }
    }
  }


  @media (max-width: 1920px) {
    .consulting-card-container {
      max-width: 463px;
      padding: 48px;
      border-radius: 48px 0 0 0;

      .technologies-text-container {
        height: 27px;
      }
    }
  }

  @media (max-width: 1600px) {
    .consulting-card-container {
      max-width: 349px;
      padding: 32px;
      border-radius: 32px 0 0 0;

      .technologies-text-container {
        height: 21px;
      }
    }
  }

  @media (max-height: 800px) {
    .consulting-card-container {
      max-width: 309px;
      padding: 32px;
      border-radius: 32px 0 0 0;

      .technologies-text-container {
        height: 18px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {

    .consulting-card-container {
      max-width: 349px;
      padding: 32px;
      border-radius: 32px 0 0 0;

      .technologies-text-container {
        height: 21px;
      }
    }
  }
  @media (max-width: 1133px) {
    .consulting-card-container {
      max-width: 309px;
      padding: 32px;
      border-radius: 32px 0 0 0;

      .technologies-text-container {
        height: 18px;
      }
    }
  }


  @media (min-width: 3025px) {
    .consulting-card-container {
      max-width: 1112px;
      padding: 128px;
      border-radius: 128px 0 0 0;

      .technologies-text-container {
        height: 63px;
      }
    }
  }

</style>