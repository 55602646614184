<template>
  <div class="main-title-container">
    <p class="main-title-content" :class="{'eng' : activeEng}">
      {{ mainTitle }}<span v-if="activeCircle" class="circle-container"></span>
    </p>
    <transition name="fade-opacity-lazy">
      <button-arrow @click="returnTo"
                    v-if="activeButton"
                    :active-text="this.$t('subBrandsScreen.returnButton')"/>
    </transition>

  </div>
</template>

<script>
import ButtonArrow from "@/components/shared/ButtonArrow.vue";

export default {
  name: "ButtonTitleMobile",
  components: {ButtonArrow},
  props: {
    mainTitle: {
      type: String,
      required: true
    },
    activeButton: {
      type: Boolean,
      default: true
    },
    activeCircle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    returnTo() {
      this.$emit('returnTo')
    }
  }
}
</script>

<style scoped lang="scss">
.main-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%;

  .main-title-content {
    white-space: pre-wrap;
    position: relative;
    color: var(--color-main-title);

    .circle-container {
      right: -8px;
      bottom: 4.6px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      overflow: hidden;
      background-color: var(--color-main-visioners);
      display: block;
      position: absolute;
    }
  }
}

@media (max-width: 769px) {
    .main-title-container {
      max-height: 64px;
      min-height: 64px;
      padding-right: 14px;

      .main-title-content {
        font-size: 18px;
      }
    }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .main-title-container {
      max-height: 72px;
      min-height: 72px;
      padding-right: 16px;

      .main-title-content {
        font-size: 20px;
      }
    }
}


@media (max-width: 375px){
    .main-title-container {
      max-height: 48px;
      min-height: 48px;

      .main-title-content {
        font-size: 18px;
      }
    }
}

@media (max-width: 375px) and (min-height: 630px){
    .main-title-container {
      max-height: 64px;
      min-height: 64px;

      .main-title-content {
        font-size: 18px;
      }
    }
}
</style>