<template>
  <div class="slider-scroll-container" :class="{'active-sub-page' : activeSubPage}">
    <icon-arrow class="swiper-button-left" v-if="activeInfoLength > 3 && activeScrollBar" direction="left"/>
    <swiper @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :slides-per-view="slidesPerView"
            :modules="modules"
            class="swiper empty"
            :navigation = "{
              nextEl: '.swiper-button-right',
              prevEl: '.swiper-button-left'
            }"
            :breakpoints="
            {
              0: {
                allowTouchMove: true,
                spaceBetween: 8,
                scrollbar: {
                  enabled: false
                }
              },
              1000: {
                allowTouchMove: true,
                spaceBetween: 32,
                scrollbar: {
                  hide: false,
                  enabled: true
                }
              },
              1600: {
                allowTouchMove: true,
                spaceBetween: 48,
                scrollbar: {
                  hide: false,
                  enabled: true
                }
              },
              1921: {
                allowTouchMove: true,
                spaceBetween: 64,
                scrollbar: {
                  hide: false,
                  enabled: true
                }
              },
              3025: {
                allowTouchMove: true,
                spaceBetween: 128,
                scrollbar: {
                  hide: false,
                  enabled: true
                }
              }
            }"
            :scrollbar="{
                draggable: true,
                dragClass: 'swiper-scrollbar-drag-custom'
             }"
            :mousewheel="true"
            :virtual="true"
            :speed="800"
            direction="horizontal"
            :effect="'fade'">
      <slot/>
    </swiper>
    <icon-arrow class="swiper-button-right" v-if="activeInfoLength > 3 && activeScrollBar" direction="right"/>
  </div>


</template>

<script>
import {Mousewheel, Navigation, Scrollbar, Virtual} from "swiper";
import CardProject from "@/components/entities/CardProject.vue";
import IconArrow from "@/components/shared/IconArrow.vue";
export default {
  name: "SliderScroll",
  components: {IconArrow, CardProject},
  props: {
    activeInfoLength: {
      type: Number,
      required: true
    },
    activeSubPage: {
      type: Boolean,
      default: false
    },
    slidesPerView: {
      type: Number,
      default: 3
    },
    activeScrollBar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scrollSwiper: '',
      activeIndex: 0,
      modules: [Scrollbar, Virtual, Navigation, Mousewheel]
    }
  },
  methods: {
    onSwiperMain(swiper) {
      this.activeIndex = swiper.activeIndex;
    },

    changeSettingSwiper(swiper) {
      this.scrollSwiper = swiper
    },
  }
}
</script>

<style scoped lang="scss">


.slider-scroll-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  position: relative;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-button-left {
    z-index: 100;
    position: absolute;
    cursor: pointer;
  }

  .swiper-button-right {
    z-index: 100;
    position: absolute;
    cursor: pointer;
  }
}

@media (max-width: 3024px){
  .slider-scroll-container  {
    padding: 0 64px;
    .swiper {
      padding: 40px 0 50px;
    }


    .swiper-button-left {
      margin-right: 16px;
      left: 64px;
    }

    .swiper-button-right {
      margin-left: 24px;
      right: 64px;
    }
  }
}

@media (max-width: 2240px){
  .slider-scroll-container  {
    .swiper {
      padding: 32px 0 50px;
    }
  }
}

@media (max-width: 1920px) {
  .slider-scroll-container {
    padding: 0 48px;

    .swiper {
      padding: 24px 0 40px;
    }

    .swiper-button-left {
      margin-right: 12px;
      left: 56px;
    }

    .swiper-button-right {
      margin-left: 12px;
      right: 48px;
    }
  }
}

@media (max-width: 1600px) {
  .slider-scroll-container {
    padding: 0 32px;

    .swiper {
      padding: 32px 0 46px;
    }

    .swiper-button-left {
      margin-right: 8px;
      left: 32px;
    }

    .swiper-button-right {
      margin-left: 8px;
      right: 32px;
    }
  }
}
@media (max-height: 800px) {
  .slider-scroll-container {
    padding: 0 24px;

    .swiper {
      padding: 24px 0 34px;
    }

    .swiper-button-left {
      margin-right: 8px;
      left: 24px;
    }

    .swiper-button-right {
      margin-left: 8px;
      right: 24px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .slider-scroll-container {
    padding: 0 32px;

    .swiper {
      padding: 32px 0 46px;
    }

    .swiper-button-left {
      margin-right: 8px;
      left: 32px;
    }

    .swiper-button-right {
      margin-left: 8px;
      right: 32px;
    }
  }
}

@media (max-width: 1133px) {
  .slider-scroll-container {
    padding: 0 24px;

    .swiper {
      padding: 24px 0 34px;
    }

    .swiper-button-left {
      margin-right: 8px;
      left: 24px;
    }

    .swiper-button-right {
      margin-left: 8px;
      right: 24px;
    }
  }
}

@media (max-width: 769px) {
  .slider-scroll-container {
    padding: 0;

    .swiper {
      padding: 0;


    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .slider-scroll-container {
    padding: 0;

    .swiper {
      padding: 0;
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .slider-scroll-container {
    padding: 0 128px;

    &.active-sub-page {
      padding: 0 160px;

      .swiper-button-right {
        right: 160px;
      }
    }

    .swiper {
      padding: 64px 0 96px;
    }

    .swiper-button-left {
      margin-right: 24px;
    }

    .swiper-button-right {
      margin-left: 24px;
      right: 128px;
    }
  }
}

</style>