<template>
<!--  <swiper @slide-change="onSwiperMain"-->
<!--          v-if="!activeMobile"-->
<!--          @swiper="changeSettingSwiper"-->
<!--          :slides-per-view="1"-->
<!--          :modules="modules"-->
<!--          class="swiper"-->
<!--          :breakpoints="-->
<!--            {-->
<!--              0: {-->
<!--                allowTouchMove: true,-->
<!--              },-->
<!--              1500: {-->
<!--                allowTouchMove: false-->
<!--              }-->
<!--            }"-->
<!--          :allow-touch-move="false"-->
<!--          :speed="1000"-->
<!--          :mousewheel="true"-->
<!--          direction="vertical"-->
<!--          :effect="'fade'">-->
<!--    <swiper-slide>-->
<!--      <start-screen/>-->
<!--    </swiper-slide>-->
<!--    <swiper-slide>-->
<!--      <navigation-screen/>-->
<!--    </swiper-slide>-->
<!--  </swiper>-->

  <home-page/>
</template>

<script>

import NavigationScreen from "@/components/features/NavigationScreen.vue";
import StartScreen from "@/components/features/main-page/StartScreen.vue";
import {EffectFade, Mousewheel} from "swiper";
import {mapActions} from "vuex";
import GroupScreen from "@/components/features/GroupScreen.vue";
import ProjectScreen from "@/components/features/main-page/ProjectScreen.vue";
import PartnersScreen from "@/components/features/main-page/PartnersScreen.vue";
import FooterScreenMobile from "@/components/features/FooterScreenMobile.vue";
import FooterScreen from "@/components/features/main-page/FooterScreen.vue";
import HomePage from "@/views/home-page.vue";

export default {
  name: "main-page",
  components: {
    HomePage,
    FooterScreen,
    FooterScreenMobile,
    PartnersScreen,
    ProjectScreen,
    GroupScreen,
    StartScreen,
    NavigationScreen},
  data() {
    return {
      mainSwiper: '',
      activeIndex: 0,
      activeProject: false,
      activePartners: false,
      activeFooter: false,
      modules: [EffectFade, Mousewheel],
    }
  },
  mounted() {
    // if(!this.activeMobile) {
    //   this.checkMountRoute();
    //   this.checkBlockSwiper();
    // }
  },
  watch: {
    // activeBlockSwiper() {
    //   if(!this.activeMobile) {
    //     this.checkBlockSwiper();
    //   }
    // }
  },
  methods: {
    ...mapActions(['ACTIVE_HOME_INDEX']),
    checkMountRoute() {
        switch (this.$route.name) {
          case 'home-logo-page':
            this.ACTIVE_HOME_INDEX(0)
            this.mainSwiper.slideTo(0, 0, false)
            break;
          case 'home-navigation-page':
            this.ACTIVE_HOME_INDEX(1)
            this.mainSwiper.slideTo(1, 0, false)
            break;
      }
    },

    checkBlockSwiper() {
        if(this.activeBlockSwiper) {
          this.mainSwiper.disable();
        } else {
          this.mainSwiper.enable();
          this.checkMountRoute();
        }
    },

    checkRoute(id) {
        switch (id) {
          case 0:
            this.ACTIVE_HOME_INDEX(0)
            this.$router.push({name: 'home-logo-page'})
            break;
          case 1:
            this.ACTIVE_HOME_INDEX(1)
            this.$router.push({name: 'home-navigation-page'})
            break;
        }
    },

    onSwiperMain(swiper) {
      this.activeIndex = swiper.activeIndex;
      this.checkRoute(this.activeIndex);
    },

    changeSettingSwiper(swiper) {
      this.mainSwiper = swiper
    },
  }

}
</script>

<style scoped>

</style>