<template>
  <footer class="footer-container">

      <div class="left-info-content">
        <div class="logo-container" @click="routTo('home')">
          <img v-if="activeColor" :src="getUrlFooter('logo-dark.svg')" alt="logo">
          <img v-else :src="getUrlFooter('logo-light.svg')" alt="logo">
        </div>
        <div class="logo-robot-container">
          <img v-if="activeColor" :src="getUrlFooter('robot-dark.png')" alt="robot">
          <img v-else :src="getUrlFooter('robot-light.png')" alt="robot">
        </div>
        <div class="slogan-info-container" :class="{'eng' : activeEng}">
          <p class="sub-title-container not-hover">
            {{ footerStatic[0] }}
          </p>
          <p class="sub-title-container not-hover">
            {{ footerStatic[1] }}
          </p>
        </div>

        <toggle-theme-local :name-check="'footer'"/>
        <p class="sub-title-container not-hover">
          {{ footerStatic[2] }}
        </p>
      </div>
    <div class="right-info-content">
      <div class="top-info-content" :class="{'eng' : activeEng}">
        <div class="controller-info-container" v-for="item in footerController" :key="item.id">
          <div class="footer-title-container">
            <div class="icon-container">
              <img :src="getUrlFooter(item.icon)" alt="icon">
            </div>
            <p class="main-title-container">
              {{ item.mainTitle }}
            </p>
          </div>
          <div class="controller-button-container">
            <div class="controller-button-content" v-for="itemTwo in item.info"
                 :key="itemTwo.id">
              <p class="sub-title-container" @click="routTo(item, itemTwo)">
                {{ itemTwo.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-info-container">
          <div class="bottom-info-content" :class="{'eng' : activeEng}"
               v-for="item in [footerBottomInfo[0], footerBottomInfo[1]]"
               :key="item.id">
              <p class="main-title-container">
                {{ item.mainTitle }}
              </p>
              <p class="sub-title-container one not-hover">
                {{ item.subTitleOne }}
              </p>
              <p class="sub-title-container not-hover">
                {{ item.subTitleTwo }}
              </p>
          </div>
          <div class="bottom-button-content">
            <a class="sub-title-container" :href="'mailto:' + footerBottomInfo[2].email">
              {{ footerBottomInfo[2].email }}
            </a>
            <p class="sub-title-container" @click="changeStateModal" :class="{'eng' : activeEng}">
              {{ footerBottomInfo[2].mistakes }}
            </p>
            <p class="sub-title-container" :class="{'eng' : activeEng}">
              {{ footerBottomInfo[2].date }}
            </p>
          </div>
      </div>
    </div>
  </footer>
</template>

<script>

import ToggleLocal from "@/components/shared/ToggleLocal.vue";
import ToggleTheme from "@/components/shared/ToggleTheme.vue";
import ToggleThemeLocal from "@/components/entities/ToggleThemeLocal.vue";
import ModalMistakes from "@/components/entities/ModalMistakes.vue";

export default {
  name: "MainFooter",
  components: {ModalMistakes, ToggleThemeLocal, ToggleTheme, ToggleLocal},
  data() {
    return {
      activeModal: false
    }
  },
  computed: {
    footerStatic() {
      return this.$tm('footerScreen.footerStatic')
    },
    footerController() {
      return this.$tm('footerScreen.footerController')
    },
    footerBottomInfo() {
      return this.$tm('footerScreen.footerBottomInfo')
    }
  },
  methods: {
    routTo(idItem, idTypeItem) {
      let id = idItem?.id
      let idType = idTypeItem?.id
      if(typeof idItem !== 'string') {
        switch(id) {
          case 0:
            switch(idType) {
              case 0:
                this.$router.push({name: 'main-business-page'})
                break;
              case 1:
                this.$router.push({name: 'brand-philosophy-page'});
                break;
              case 2:
                this.$router.push({name: 'brand-subbrands-page'})
                break;
              case 3:
                this.$router.push({name: 'brand-mission-page'})
                break;
              case 4:
                this.$router.push({name: 'brand-values-page'})
                break;
              case 5:
                this.$router.push({name: 'brand-history-page', params: {typeHistory: 'past'}})
                break;
              case 6:
                this.$router.push({name: 'main-management-page', params: {typeManagement: 'founders'}})
                break;
            }
            break;
          case 1:
            switch(idType) {
              case 0:
                this.$router.push({name: 'main-type-division-page', params: {typeDivision: 'project'}})
                break;
              case 1:
                this.$router.push({name: 'main-type-division-page', params: {typeDivision: 'consulting'}})
                break;
              case 2:
                this.$router.push({name: 'main-type-division-page', params: {typeDivision: 'engineering'}})
                break;
            }
            break;
          case 2:
            switch(idType) {
              case 0:
                this.$router.push({name: 'static-rp-page', params: {typeNews: 'all'}});
                break;
              case 1:
                console.log('1')
                break;
              case 2:
                console.log('2')
                break;
              case 3:
                console.log('3')
                break;
              case 4:
                this.$router.push({name: 'static-helper-ai-page'});
                break;
            }
            break;
          case 3:
            switch(idType) {
              case 0:
                console.log('0')
                break;
              case 1:
                console.log('1')
                break;
              case 2:
                console.log('2')
                break;
              case 3:
                this.$router.push({name: 'static-compliance-page'});
                break;
              case 4:
                console.log('3')
                break;
              case 5:
                console.log('3')
                break;
            }
            break;
        }
      } else {
        this.$router.push({name: 'home-navigation-page'})
      }
    },
    changeStateModal() {
      this.$emit('changeStateModal')
    }
  }
}
</script>

<style scoped lang="scss">
.footer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: relative;

  .right-info-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .top-info-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .controller-info-container {
        display: flex;
        flex-direction: column;

        .footer-title-container {
          display: flex;
          flex-direction: row;

          .icon-container {
            & img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .controller-button-container {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .bottom-info-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;


      .bottom-button-content {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .logo-container {
    cursor: pointer;
    width: 100%;
    height: 100%;
    
    & img {
      width: 100%;
      height: 100%;
    }
  }

  .logo-robot-container {
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  .sub-title-container {
    opacity: .3;
    cursor: pointer;
    transition: opacity .6s ease;

    &:hover {
      opacity: 1;
    }

    &.not-hover {
      opacity: 1;
      cursor: default;
    }
  }

  .left-info-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .slogan-info-container {
      display: flex;
      flex-direction: column;
    }

    .toggle-container {
      display: flex;
      flex-direction: row;
    }
  }
}

@media (max-width: 3024px){
  .footer-container {
    max-height: 629px;
    padding: 64px;
    border-top: 1px solid var(--color-main-title);

    .right-info-content {
      row-gap: 64px;

      .top-info-content {
        padding-left: 96px;

        .controller-info-container {
          row-gap: 24px;

          .footer-title-container {
            column-gap: 18px;

            .icon-container {
              width: 32px;
              height: 32px;
            }
          }

          .controller-button-container {
            padding-left: 50px;
            row-gap: 18px;
          }
        }
      }

      .bottom-info-container {
        padding-left: 156px;

        .bottom-info-content {
          .sub-title-container {
            &.one {
              margin-bottom: 18px;
            }
          }

          .main-title-container {
            margin-bottom: 28px;
          }
        }

        .bottom-button-content {
          row-gap: 18px;
        }
      }
    }

    .logo-container {
      max-width: 260px;
      max-height: 52px;
    }

    .logo-robot-container {
      max-width: 320px;
      max-height: 256px;
    }

    .main-title-container {
      font-size: 24px;
    }

    .sub-title-container {
      font-size: 18px;
    }

    .left-info-content {
      max-width: 380px;

      .slogan-info-container {
        row-gap: 8px;
      }

      .toggle-container {
        column-gap: 12px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .footer-container {
    max-height: 560px;
    padding: 64px;
    border-top: 1px solid var(--color-main-title);

    .right-info-content {
      row-gap: 48px;

      .top-info-content {
        padding-left: 48px;

        .controller-info-container {
          row-gap: 20px;

          .footer-title-container {
            column-gap: 12px;

            .icon-container {
              width: 24px;
              height: 24px;
            }
          }

          .controller-button-container {
            padding-left: 38px;
            row-gap: 16px;
          }
        }
      }

      .bottom-info-container {
        padding-left: 86px;

        .bottom-info-content {
          .sub-title-container {
            &.one {
              margin-bottom: 16px;
            }
          }

          .main-title-container {
            margin-bottom: 24px;
          }
        }

        .bottom-button-content {
          row-gap: 16px;
        }
      }
    }

    .logo-container {
      max-width: 220px;
      max-height: 44px;
    }

    .logo-robot-container {
      max-width: 280px;
      max-height: 224px;
    }

    .main-title-container {
      font-size: 20px;
    }

    .sub-title-container {
      font-size: 16px;
    }

    .left-info-content {
      max-width: 338px;

      .slogan-info-container {
        row-gap: 6px;
      }

      .toggle-container {
        column-gap: 10px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .footer-container {
    max-height: 443px;
    padding: 48px;
    border-top: 1px solid var(--color-main-title);

    .right-info-content {
      row-gap: 48px;

      .top-info-content {
        padding-left: 32px;

        .controller-info-container {
          row-gap: 16px;

          .footer-title-container {
            column-gap: 10px;

            .icon-container {
              width: 24px;
              height: 24px;
            }
          }

          .controller-button-container {
            padding-left: 34px;
            row-gap: 12px;
          }
        }
      }

      .bottom-info-container {
        padding-left: 66px;

        .bottom-info-content {
          .sub-title-container {
            &.one {
              margin-bottom: 12px;
            }
          }

          .main-title-container {
            margin-bottom: 20px;
          }
        }

        .bottom-button-content {
          row-gap: 12px;
        }
      }
    }

    .logo-container {
      max-width: 190px;
      max-height: 38px;
    }

    .logo-robot-container {
      max-width: 240px;
      max-height: 190px;
    }

    .main-title-container {
      font-size: 16px;
    }

    .sub-title-container {
      font-size: 12px;
    }

    .left-info-content {
      max-width: 254px;

      .slogan-info-container {
        row-gap: 4px;
      }

      .toggle-container {
        column-gap: 8px;
      }
    }
  }
}
@media (max-height: 800px) {
  .footer-container {
    max-height: 353px;
    padding: 32px;
    border-top: 1px solid var(--color-main-title);

    .right-info-content {
      row-gap: 48px;

      .top-info-content {
        padding-left: 24px;

        .controller-info-container {
          row-gap: 14px;

          .footer-title-container {
            column-gap: 8px;

            .icon-container {
              width: 18px;
              height: 18px;
            }
          }

          .controller-button-container {
            padding-left: 26px;
            row-gap: 10px;
          }
        }
      }

      .bottom-info-container {
        padding-left: 50px;

        .bottom-info-content {
          .sub-title-container {
            &.one {
              margin-bottom: 10px;
            }
          }

          .main-title-container {
            margin-bottom: 16px;
          }
        }

        .bottom-button-content {
          row-gap: 10px;
        }
      }
    }

    .logo-container {
      max-width: 143px;
      max-height: 28px;
    }

    .logo-robot-container {
      max-width: 190px;
      max-height: 150px;
    }

    .main-title-container {
      font-size: 12px;
    }

    .sub-title-container {
      font-size: 10px;
    }

    .left-info-content {
      max-width: 211px;

      .slogan-info-container {
        row-gap: 2px;
      }

      .toggle-container {
        column-gap: 6px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .footer-container {
    max-height: 443px;
    padding: 48px;
    border-top: 1px solid var(--color-main-title);

    .right-info-content {
      row-gap: 48px;

      .top-info-content {
        padding-left: 32px;

        .controller-info-container {
          row-gap: 16px;

          .footer-title-container {
            column-gap: 10px;

            .icon-container {
              width: 24px;
              height: 24px;
            }
          }

          .controller-button-container {
            padding-left: 34px;
            row-gap: 12px;
          }
        }
      }

      .bottom-info-container {
        padding-left: 66px;

        .bottom-info-content {
          .sub-title-container {
            &.one {
              margin-bottom: 12px;
            }
          }

          .main-title-container {
            margin-bottom: 20px;
          }
        }

        .bottom-button-content {
          row-gap: 12px;
        }
      }
    }

    .logo-container {
      max-width: 190px;
      max-height: 38px;
    }

    .logo-robot-container {
      max-width: 240px;
      max-height: 190px;
    }

    .main-title-container {
      font-size: 16px;
    }

    .sub-title-container {
      font-size: 12px;
    }

    .left-info-content {
      max-width: 254px;

      .slogan-info-container {
        row-gap: 4px;
      }

      .toggle-container {
        column-gap: 8px;
      }
    }
  }

}

@media (max-width: 1133px) {
  .footer-container {
    max-height: 353px;
    padding: 32px;
    border-top: 1px solid var(--color-main-title);

    .right-info-content {
      row-gap: 48px;

      .top-info-content {
        padding-left: 24px;

        .controller-info-container {
          row-gap: 14px;

          .footer-title-container {
            column-gap: 8px;

            .icon-container {
              width: 18px;
              height: 18px;
            }
          }

          .controller-button-container {
            padding-left: 26px;
            row-gap: 10px;
          }
        }
      }

      .bottom-info-container {
        padding-left: 50px;

        .bottom-info-content {
          .sub-title-container {
            &.one {
              margin-bottom: 10px;
            }
          }

          .main-title-container {
            margin-bottom: 16px;
          }
        }

        .bottom-button-content {
          row-gap: 10px;
        }
      }
    }

    .logo-container {
      max-width: 143px;
      max-height: 28px;
    }

    .logo-robot-container {
      max-width: 190px;
      max-height: 150px;
    }

    .main-title-container {
      font-size: 12px;
    }

    .sub-title-container {
      font-size: 10px;
    }

    .left-info-content {
      max-width: 211px;

      .slogan-info-container {
        row-gap: 2px;
      }

      .toggle-container {
        column-gap: 6px;
      }
    }
  }
}


@media (min-width: 3025px) {
  .footer-container {
    max-height: 1143px;
    padding: 128px;
    border-top: 2px solid var(--color-main-title);

    .right-info-content {
      row-gap: 128px;

      .top-info-content {
        padding-left: 160px;

        .controller-info-container {
          row-gap: 40px;

          .footer-title-container {
            column-gap: 24px;

            .icon-container {
              width: 48px;
              height: 48px;
            }
          }

          .controller-button-container {
            padding-left: 72px;
            row-gap: 32px;
          }
        }
      }

      .bottom-info-container {
        padding-left: 232px;

        .bottom-info-content {
          .sub-title-container {
            &.one {
              margin-bottom: 32px;
            }
          }

          .main-title-container {
            margin-bottom: 42px;
          }
        }

        .bottom-button-content {
          row-gap: 32px;
        }
      }
    }

    .logo-container {
      max-width: 400px;
      max-height: 80px;
    }

    .logo-robot-container {
      max-width: 600px;
      max-height: 480px;
    }

    .main-title-container {
      font-size: 42px;
    }

    .sub-title-container {
      font-size: 32px;
    }

    .left-info-content {
      max-width: 674px;

      .slogan-info-container {
        row-gap: 10px;
      }

      .toggle-container {
        column-gap: 16px;
      }
    }
  }
}

</style>