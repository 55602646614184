<template>
  <slider-scroll :active-info-length="activeLength"
                 :active-scroll-bar="activeScrollBar"
                 @mouseenter="mouseEnterBlockSwiper"
                 @mouseleave="mouseEnterUnblockSwiper"
                 :slides-per-view="slidesPerView">
    <swiper-slide v-for="(item, index) in activeLength"
                  class="clickable"
                  v-slot="{ isActive, isPrev }"
                  :virtual-index="index"
                  :key="index">
      <card-project v-if="index < this.activeInfo.length"
                    :class="{'is-last' : activeMobile && index === activeLength - 1 && isActive,
                              'is-last-prev' : activeMobile && isPrev && index === activeLength - 2}"
                    :active-card-mobile="isActive"
                    :info-card="this.activeInfo[index]"/>
      <div v-else class="empty-project"></div>
    </swiper-slide>
  </slider-scroll>
</template>

<script>
import SliderScroll from "@/components/entities/SliderScroll.vue";
import CardProject from "@/components/entities/CardProject.vue";

export default {
  name: "SliderScrollProject",
  components: {CardProject, SliderScroll},
  props: {
    activeInfo: {
      type: Array,
      required: true
    }
  },
  computed: {
    slidesPerView() {
      if(this.activeMobile) {
        return 1.3;
      } else {
        return 3;
      }
    },

    activeScrollBar() {
      if(this.activeMobile) {
        return false
      } else {
        return true;
      }
    },


    activeLength() {
      return this.activeInfo.length
    }
  }
}
</script>

<style scoped lang="scss">


@media (max-width: 769px) {
  .card-project-container {
    &.is-last {
      transform: translateX(-86px);
    }
    &.is-last-prev {
      transform: translateX(-86px);
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .card-project-container {
      &.is-last {
        transform: translateX(-96px);
      }
      &.is-last-prev {
        transform: translateX(-96px);
      }
  }
}

@media (max-width: 375px){
  .card-project-container {
    &.is-last {
      transform: translateX(-83px);
    }
    &.is-last-prev {
      transform: translateX(-83px);
    }
  }
}
</style>