<template>
  <div class="cookie-buttons-content">
    <div class="large-button-container">
      <span class="button-text-content">
        {{ $t('cookieModal.cookieText')  }}
      </span>
      <div class="button-info-container">
        <main-button class="cookie-text icon" :icon-active="'check-mark'" @click="closeCookie"/>
        <main-button  class="cookie-text icon" :icon-active="'setting'" @click="changeModalCookieSetting"/>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/shared/MainButton.vue";

export default {
  name: "CookieStartButton",
  components: {MainButton},
  data() {
    return {
      settingImg: 'setting-icon',
      checkMarkImg: 'check-mark-circle',
    }
  },
  methods: {
    changeModalCookieSetting() {
      this.$emit('changeModalCookieSetting')
    },
    closeCookie() {
      this.$emit('closeCookie')
    }
  }
}
</script>

<style scoped lang="scss">
.cookie-buttons-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;

  .large-button-container {
    align-items: center;
    width: fit-content;
    border-style: solid;
    border-color: var(--color-button-border);
    background-color: var(--color-background);
    display: flex;
    flex-direction: row;

    .button-info-container {
      display: flex;
      flex-direction: row;
    }
  }
}

@media (max-width: 3024px){
  .cookie-buttons-content {
    bottom: 100px;

    .large-button-container {
      border-width: 6px;
      border-radius: 24px;
      padding: 42px 44px;
      max-height: 110px;
      min-height: 110px;
      column-gap: 88px;
      font-size: 22px;
      .button-info-container {
        column-gap: 24px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .cookie-buttons-content {
    bottom: 80px;

    .large-button-container {
      border-width: 4px;
      border-radius: 48px;
      padding: 34px 36px;
      max-height: 90px;
      min-height: 90px;
      column-gap: 72px;
      font-size: 18px;
        .button-info-container {
          column-gap: 16px;
        }
    }
  }
}


@media (max-width: 1600px) {
  .cookie-buttons-content {
    bottom: 60px;

    .large-button-container {
      border-width: 4px;
      border-radius: 16px;
      padding: 14px 28px;
      max-height: 70px;
      min-height: 70px;
      column-gap: 56px;
      font-size: 14px;
      .button-info-container {
        column-gap: 12px;
      }
    }
  }
}
@media (max-height: 800px){
  .cookie-buttons-content {
    bottom: 40px;

    .large-button-container {
      border-width: 3px;
      border-radius: 12px;
      padding: 12px 24px;
      max-height: 58px;
      min-height: 58px;
      column-gap: 48px;
      font-size: 12px;
      .button-info-container {
        column-gap: 10px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .cookie-buttons-content {
    bottom: 60px;

    .large-button-container {
      border-width: 4px;
      border-radius: 16px;
      padding: 14px 28px;
      max-height: 70px;
      min-height: 70px;
      column-gap: 56px;
      font-size: 14px;
      .button-info-container {
        column-gap: 12px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .cookie-buttons-content {
    bottom: 40px;

    .large-button-container {
      border-width: 3px;
      border-radius: 12px;
      padding: 12px 24px;
      max-height: 58px;
      min-height: 58px;
      column-gap: 48px;
      font-size: 12px;
      .button-info-container {
        column-gap: 10px;
      }
    }
  }
}
@media (max-width: 769px) {
  .cookie-buttons-content {
    padding: 0 14px;
    bottom: 14px;

    .large-button-container {
      width: 100%;
      border-radius: 10px;
      max-height: fit-content;
      min-height: fit-content;
      padding: 10px 20px;
      border-width: 3px;
      font-weight: 200;
      font-size: 10px;
      column-gap: 20px;

      .button-info-container {
        column-gap: 10px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
  .cookie-buttons-content {
    bottom: 16px;
    padding: 0 16px;

    .large-button-container {
      border-radius: 12px;
      padding: 12px 24px;
      max-height: fit-content;
      min-height: fit-content;
      border-width: 3px;
      column-gap: 24px;
      font-weight: 200;
      font-size: 12px;


      .button-info-container {
        column-gap: 12px;
      }
    }
  }
}


@media (max-width: 320px) {
  .cookie-buttons-content {
    .large-button-container {
      .button-text-content {
        max-width: 211px;
      }
    }
  }
}


@media (min-width: 3025px) {
  .cookie-buttons-content {
    bottom: 128px;

    .large-button-container {
      border-width: 10px;
      border-radius: 48px;
      padding: 61px 64px;
      max-height: 160px;
      min-height: 160px;
      column-gap: 128px;
      font-size: 32px;
      .button-info-container {
        column-gap: 32px;
      }
    }
  }
}


</style>