<template>
  <div class="card-partners-container"
       :class="{'active-scale' : !activeCardMobile && activeMobile}"
       @mouseenter="focusTrue" @mouseleave="focusFalse">
    <div class="text-info-container">
      <main-title>
        {{ infoCard.mainTitle }}
      </main-title>

      <main-paragraph>
        {{ infoCard.description }}
      </main-paragraph>
    </div>
    
    <div class="img-container" v-if="!activeTouchAllow">
      <div class="img-content" v-if="activeColor">
        <transition name="fade-opacity-lazy">
          <img v-if="!focusParam" :src="getUrlPartners(infoCard.img + '-dark.svg')" alt="logo">
          <img v-else :src="getUrlPartners(infoCard.img + '-dark-hover.svg')" alt="logo">
        </transition>
      </div>
      <div class="img-content" v-else>
        <transition name="fade-opacity-lazy">
          <img v-if="!focusParam" :src="getUrlPartners(infoCard.img + '-light.svg')" alt="logo">
          <img v-else  :src="getUrlPartners(infoCard.img + '-light-hover.svg')" alt="logo">
        </transition>
      </div>
    </div>
    <div class="img-container" v-else>
      <div class="img-content" v-if="activeColor">
        <transition name="fade-opacity-lazy">
          <img :src="getUrlPartners(infoCard.img + '-dark-hover.svg')" alt="logo">
        </transition>
      </div>
      <div class="img-content" v-else>
        <transition name="fade-opacity-lazy">
          <img :src="getUrlPartners(infoCard.img + '-light-hover.svg')" alt="logo">
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/CardText/MainTitle.vue";
import MainParagraph from "@/components/shared/CardText/MainParagraph.vue";

export default {
  name: "CardPartners",
  components: {MainParagraph, MainTitle},
  props: {
    infoCard: {
      type: Object,
      required: true
    },
      activeCardMobile: {
        type: Boolean
      }
  },
  data() {
    return {
      focusParam: false
    }
  },
  computed: {
    activeTouchAllow() {
      return this.$store.state.activeTouchAllow;
    }
  }
}
</script>

<style scoped lang="scss">
  .card-partners-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0);
    border-style: solid;
    border-color: var(--color-card-division);
    transition: all .6s ease;

    &.active-scale {
      transform: scale(95%);
      opacity: .4;
    }

    &:hover {
      background-color: var(--color-card-division);

      .text-info-container {

        .main-title-container {
          color: var(--color-card-main-title) !important;
        }

        .card-description-content {
          color: var(--color-card-main-title) !important;
        }
      }
    }


    .text-info-container {
      display: flex;
      flex-direction: column;

      .main-title-container {
        color: var(--color-card-main-text) !important;
      }

      .card-description-content {
        font-weight: 200;
      }
    }

    .img-container {
      align-self: center;
      height: 100%;
      width: 100%;
      .img-content {
        max-height: inherit;
        max-width: inherit;
        width: 100%;
        height: 100%;

        & img {
          max-width: inherit;
          max-height: inherit;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 3840px){
    .card-partners-container {
      padding: 90px 64px 64px;
      border-radius: 32px;
      border-width: 10px;

      .text-info-container {
        row-gap: 64px;
      }

      .img-container {
        max-width: 645px;
        max-height: 480px;
      }
    }
  }

  @media (max-width: 3024px) {
    .card-partners-container {
      padding: 60px 40px 40px;
      border-radius: 24px;
      border-width: 8px;

      .text-info-container {
        row-gap: 48px;
      }

      .img-container {
        max-width: 391px;
        max-height: 292px;
      }
    }
  }



  @media (max-width: 1920px) {
    .card-partners-container {
      padding: 40px 30px 30px;
      border-radius: 16px;
      border-width: 7px;

      .text-info-container {
        row-gap: 32px;
      }

      .img-container {
        max-width: 350px;
        max-height: 260px;
      }
    }
  }


  @media (max-width: 1600px) {
    .card-partners-container {
      padding: 32px 24px 24px;
      border-radius: 12px;
      border-width: 6px;

      .text-info-container {
        row-gap: 24px;
      }

      .img-container {
        max-width: 280px;
        max-height: 208px;
      }
    }
  }
  @media (max-height: 800px) {
    .card-partners-container {
      padding: 24px 16px 16px;
      border-radius: 10px;
      border-width: 4px;

      .text-info-container {
        row-gap: 16px;
      }

      .img-container {
        max-width: 220px;
        max-height: 164px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-partners-container {
      padding: 32px 24px 24px;
      border-radius: 12px;
      border-width: 6px;

      .text-info-container {
        row-gap: 24px;
      }

      .img-container {
        max-width: 280px;
        max-height: 208px;
      }
    }
  }

  @media (max-width: 1133px) {
    .card-partners-container {
      padding: 24px 16px 16px;
      border-radius: 10px;
      border-width: 4px;

      .text-info-container {
        row-gap: 16px;
      }

      .img-container {
        max-width: 220px;
        max-height: 164px;
      }
    }
  }
  @media (max-width: 769px) {
    .card-partners-container {
      background-color: var(--color-card-division);
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //iPhone Plus
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    .card-partners-container {
      padding: 24px 16px 16px;
      border-radius: 10px;
      border-width: 4px;

      .text-info-container {
        row-gap: 14px;
      }

      .img-container {
        max-width: 140px;
        max-height: 104px;
      }
    }
  }

  @media (min-width: 3841px) {
    .card-partners-container {
      padding: 120px 80px 80px;
      border-radius: 32px;
      border-width: 10px;

      .text-info-container {
        row-gap: 64px;
      }

      .img-container {
        max-width: 645px;
        max-height: 480px;
      }
    }
  }

</style>