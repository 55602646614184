<template>
  <div class="block-chat-message-container">
    <circle-mini-block :is-white="true" :focused="infoMessage.ai"/>
    <sub-paragraph>
      {{ infoMessage.text }}
    </sub-paragraph>
  </div>
</template>

<script>
import CircleMiniBlock from "@/components/shared/CircleMiniBlock.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "BlockChatMessage",
  components: {SubParagraph, CircleMiniBlock},
  props: {
    infoMessage: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.block-chat-message-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: var(--color-card-division);
}
@media (max-width: 3024px){
  .block-chat-message-container {
    padding: 22px;
    column-gap: 15px;
  }
}


@media (max-width: 1920px) {
  .block-chat-message-container {
    padding: 18px;
    column-gap: 14px;
  }
}

@media (max-width: 1600px) {
  .block-chat-message-container {
    padding: 14px;
    column-gap: 13px;
  }
}
@media (max-height: 800px) {
  .block-chat-message-container {
    padding: 10px;
    column-gap: 11px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .block-chat-message-container {
    padding: 14px;
    column-gap: 13px;
  }
}

@media (max-width: 1133px) {
  .block-chat-message-container {
    padding: 10px;
    column-gap: 11px;
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .block-chat-message-container {
    padding: 32px;
    column-gap: 32px;
  }
}

</style>