<template>
  <div class="sub-button-container" @click="clickToButton">
    <icon-arrow :class="{'active-right' : directionActive === 'right'}" :direction="directionActive"/>
    <p class="text-info-content" :class="{'eng' : activeEng || activeEngStatic}">
      {{ activeText }}
    </p>
  </div>
</template>

<script>
import IconArrow from "@/components/shared/IconArrow.vue";

export default {
  name: "ButtonArrow",
  components: {IconArrow},
  props: {
    directionActive: {
      type: String,
      default: 'left'
    },
    activeText: {
      type: String
    },
    activeEngStatic: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickToButton() {
      this.$emit('clickToButton')
    }
  }
}
</script>

<style scoped lang="scss">
.sub-button-container {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  bottom: 0;
  width: fit-content;
  cursor: pointer;

  .text-info-content {
    position: relative;
    opacity: .3;
    transition: opacity .6s ease;
  }


  &:hover {
    .text-info-content {
      opacity: 1;
    }
    .icon-arrow-container {
      opacity: 1;
    }
  }

  .icon-arrow-container {
    transition: opacity .6s ease;
    opacity: .4;
    position: absolute;
    left: var(--active-position);
    right: auto;
    &.active-right {
      left: auto;
      right: var(--active-position);
    }
  }
}

@media (max-width: 3024px){
      .sub-button-container {

        .text-info-content {
          font-size: 18px;
        }
        .icon-arrow-container {
          --active-position: -42px;
        }
      }
}


@media (max-width: 1920px) {
      .sub-button-container {
        .text-info-content {
          font-size: 16px;
        }
        .icon-arrow-container {
          --active-position: -36px;
        }
      }
}


@media (max-width: 1600px) {
      .sub-button-container {
        .text-info-content {
          font-size: 14px;
        }
        .icon-arrow-container {
          --active-position: -30px;
        }
      }
}
@media (max-height: 800px) {
      .sub-button-container {
        .text-info-content {
          bottom: 15%;
          font-size: 10px;
        }
        .icon-arrow-container {
          --active-position: -22px;
          top: 0;
        }
      }
}

@media (max-width: 1400px) and (min-height: 801px) {
      .sub-button-container {
        .text-info-content {
          bottom: 15%;
          font-size: 14px;
        }
        .icon-arrow-container {
          --active-position: -30px;
          top: 0;
        }
      }
}

@media (max-width: 1133px) {
  .sub-button-container {
    .text-info-content {
      font-size: 10px;
    }
    .icon-arrow-container {
      --active-position: -22px;
      top: 0;
    }
  }
}
@media (max-width: 769px) {
  .sub-button-container {
    color: var(--color-main-title);
    height: 12px;
    line-height: normal !important;
      .text-info-content {
        opacity: 1;
      }

    .icon-arrow-container {
      height: 12px;
      top: 0.3px;
      opacity: 1;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .sub-button-container {
    height: 12px;
    .text-info-content {
      font-size: 10px;
    }
    .icon-arrow-container {
      --active-position: -22px;
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
      .sub-button-container {
        .text-info-content {
          font-size: 32px;
        }
        .icon-arrow-container {
          --active-position: -80px;
        }
      }
}
</style>