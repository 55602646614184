<template>
  <slider-scroll :active-info-length="activeInfo.length"
                 :active-scroll-bar="activeScrollBar"
                 :slides-per-view="slidesPerView"
                 @mouseenter="mouseEnterBlockSwiper"
                 @mouseleave="mouseEnterUnblockSwiper"
                 :active-sub-page="true">
    <swiper-slide v-for="(item, index) in activeLength"
                  class="clickable"
                  v-slot="{ isActive }"
                  :virtual-index="index"
                  :key="index">
      <card-HR v-if="index < this.activeInfo.length"
              :active-card-mobile="isActive && activeMobile"
               @openModal="openModal"
              :info-card="activeInfo[index]"/>
      <div v-else class="empty-project"></div>
    </swiper-slide>
  </slider-scroll>
</template>

<script>
import SliderScroll from "@/components/entities/SliderScroll.vue";
import CardProject from "@/components/entities/CardProject.vue";
import CardHR from "@/components/entities/CardHR.vue";

export default {
  name: "SliderScrollHR",
  components: {CardHR, CardProject, SliderScroll},
  props: {
    activeInfo: {
      type: Array,
      required: true
    }
  },
  methods: {
    openModal() {
      this.$emit('openModal')
    }
  },
  computed: {
    slidesPerView() {
      if(this.activeMobile) {
        return 1.2;
      } else {
        return 3;
      }
    },

    activeScrollBar() {
      if(this.activeMobile) {
        return false
      } else {
        return true;
      }
    },

    activeLength() {
      if(this.activeMobile) {
        return this.activeInfo.length + 1;
      } else {
        return this.activeInfo.length;
      }
    }
  }
}
</script>

<style scoped lang="scss">



</style>