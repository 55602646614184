<template>
  <div class="values-screen-container main-container">

      <line-controller-container :active-screen="true"
                                 :class="{'active-controller' : activeController}"
                                 @endAnimation="endAnimation"
                                 @changeActiveLine="changeActiveLineController"
                                 :active-index-line="activeIndex"
                                 :line-controller="$tm('valuesScreen.controllerInfo')"/>

    <icon-arrow v-if="activeMobile"
                @click="slideNext"
                direction="bottom"/>

    <transition name="fade-opacity-1">
      <div class="top-info-container" v-if="activeTopContent">
        <swiper class="swiper"
                @slide-change="onSwiperMain"
                @swiper="changeSettingSwiper"
                :slides-per-view="1"
                :modules="modules"
                :speed="1000"
                :breakpoints="
                  {
                    0: {
                      allowTouchMove: true,
                    },
                    1600: {
                      allowTouchMove: false
                    }
                  }"
                :mousewheel="true"
                :direction="'vertical'"
                :effect="'fade'">
          <swiper-slide>
            <transition name="fade-opacity-1" mode="out-in">
              <medium-title v-if="activeFirstContent && !activeMobile">
                {{ $t('valuesScreen.mainTitle') }}
              </medium-title>
            </transition>
            <transition name="fade-opacity-1" mode="out-in">
              <medium-title v-if="activeFirstContent && activeMobile">
                {{ $t('valuesScreen.mainTitleMobile') }}
              </medium-title>
            </transition>
          </swiper-slide>
          <swiper-slide v-for="item in $tm('valuesScreen.controllerInfo').length">
            <transition name="fade-opacity-lazy" mode="out-in">
              <card-values v-if="!activeFirstContent"
                           :key="activeValues"
                           :info-card="activeValues"/>
            </transition>
          </swiper-slide>
        </swiper>
      </div>
    </transition>


    <transition name="fade-opacity-1">
      <button-title-mobile v-if="activeMobile && activeTitle"
                           :active-button="false"
                           :main-title="$t('breadCrumb.dynamicTitle.values.staticTitle')"/>
    </transition>
  </div>
</template>

<script>
import LineControllerContainer from "@/components/entities/LineControllerContainer.vue";
import MediumTitle from "@/components/shared/Text/MediumTitle.vue";
import CardValues from "@/components/entities/CardValues.vue";
import {EffectFade, Mousewheel} from "swiper";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
import IconArrow from "@/components/shared/IconArrow.vue";

export default {
  name: "ValuesScreen",
  components: {IconArrow, ButtonTitleMobile, CardValues, MediumTitle, LineControllerContainer},
  data() {
    return {
      typeValues: undefined,
      valuesSwiper: '',
      modules: [EffectFade, Mousewheel],
      activeTopContent: false,
      activeTitle: false
    }
  },
  created() {
    this.typeValues = this.$route.params.typeValues
    this.$watch(
        () => this.$route.params,
        (toParams) => {
          this.typeValues = toParams?.typeValues
        }
    )
  },
  mounted() {
    if(this.activeMobile) {
      this.activeTitle = true
      setTimeout(() => {
        this.endAnimation()
      }, 500)
    }
  },

  watch: {
    '$route'() {
      if(this.$route.name.includes('brand-values-page')) {
        this.valuesSwiper.slideTo(0, 500, false)
        this.checkBlock();
      }
    }
  },

  computed: {
    activeValues() {
      if(this.activeIndex !== -1) {
        return this.$tm('valuesScreen.valuesInfo')[this.activeIndex - 1];
      } else {
        return undefined
      }
    },
    activeController() {
      if(this.activeMobile) {
        if(this.activeIndex === -1) {
          return false
        }
        return true
      } else {
        return true
      }
    },
    activeIndex() {
      if(this.typeValues !== undefined) {
        switch (this.typeValues) {
          case 'responsibility':
            return 1;
          case 'transparency':
            return 2;
          case 'openness':
            return 3;
          case 'productivity':
            return 4;
        }
      } else {
        return -1
      }
    },

    activeFirstContent() {
      return this.activeIndex === -1;
    },
  },

  methods: {

    changeActiveLineController(id) {
      this.valuesSwiper.slideTo(id, 500, false)
      this.changeActiveLine(id)
    },


    changeActiveLine(id) {
      switch (id) {
        case 0:
          this.$router.push({name: 'brand-values-page'})
          break;
        case 1:
          this.$router.push({name: 'brand-values-type-page', params: {typeValues: 'responsibility'}})
          break;
        case 2:
          this.$router.push({name: 'brand-values-type-page', params: {typeValues: 'transparency'}})
          break;
        case 3:
          this.$router.push({name: 'brand-values-type-page', params: {typeValues: 'openness'}})
          break;
        case 4:
          this.$router.push({name: 'brand-values-type-page', params: {typeValues: 'productivity'}})
          break;
      }
    },


    endAnimation() {
      this.activeTopContent = true;
      setTimeout(() => {
        this.valuesSwiper.slideTo(this.activeIndex, 0, false)
        this.checkBlock();
      }, 50)
    },


    onSwiperMain(swiper) {
      this.changeActiveLine(swiper.activeIndex)
      this.checkBlock();
    },


    checkBlock() {
      if(this.valuesSwiper.isBeginning) {
        this.$emit('blockNextSlide', true)
        setTimeout(() => {
          this.$emit('blockPrevSlide', false)
        },100)
      } else if(this.valuesSwiper.isEnd){
        this.$emit('blockPrevSlide', true)
        setTimeout(() => {
          this.$emit('blockNextSlide', false)
        },100)
      } else {
        this.$emit('blockPrevSlide', true)
        this.$emit('blockNextSlide', true)
      }
    },

    changeSettingSwiper(swiper) {
      this.valuesSwiper = swiper
    },

    slideNext() {
      this.valuesSwiper.slideNext();
    }
  }
}
</script>

<style scoped lang="scss">
  .values-screen-container {
    flex-direction: column-reverse;
    .top-info-container {
      display: flex;
      align-items: center;
      height: 85%;

      .swiper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          justify-content: flex-start;
          .medium-title-container {
            color: var(--color-main-title);
          }
        }

      }
    }
  }

  @media (max-width: 3024px){
    .values-screen-container {
      .top-info-container {
        padding: 0 64px 0 128px;
      }
    }
  }

  @media (max-width: 1920px) {
    .values-screen-container {
      .top-info-container {
        padding: 0 48px 0 96px;
      }
    }
  }

  @media (max-width: 1600px) {
    .values-screen-container {
      .top-info-container {
        padding: 0 32px 0 64px;
      }
    }
  }
  @media (max-height: 800px) {
    .values-screen-container {
      .top-info-container {
        padding: 0 24px 0 48px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .values-screen-container {
      .top-info-container {
        padding: 0 32px 0 64px;
      }
    }
  }

  @media (max-width: 1133px) {
    .values-screen-container {
      .top-info-container {
        padding: 0 24px 0 48px;
      }
    }
  }
  @media (max-width: 769px) {
    .values-screen-container {
      padding: var(--burger-height) var(--controller-padding) var(--main-padding-bottom);

      .line-controller-container {
        opacity: 0;
        transition: opacity .6s ease;
        &.active-controller {
          opacity: 1;
        }
      }

      .icon-arrow-container {
        position: absolute;
        z-index: 950;
        bottom: 18px;
        left: 50%;
      }

      .top-info-container {
        padding: 0;
        height: 100%;
        max-height: 400px;
      }

      .medium-title-container {
        font-weight: 400;
        font-size: 24px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .values-screen-container {
      .icon-arrow-container {
        bottom: 18px;
      }

      .top-info-container {
        max-height: 508px;
      }

      .medium-title-container {
        font-size: 28px;
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .values-screen-container {

      .top-info-container {
        max-height: 335px;
      }

      .medium-title-container {
        font-size: 22px;
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .values-screen-container {

      .top-info-container {
        max-height: 376px;
      }

      .medium-title-container {
        font-size: 24px;
      }
    }
  }


  @media (max-width: 320px) {
    .values-screen-container {

      .top-info-container {
        max-height: 250px;
      }

      .medium-title-container {
        font-size: 18px;
      }
    }
  }

  @media (min-width: 3025px) {
    .values-screen-container {
      .top-info-container {
        padding: 0 128px 0 256px;
      }
    }
  }

</style>