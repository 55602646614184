<template>
    <div class="history-screen-container main-container">
      <line-controller-container
          v-if="!activeMobile"
          @changeActiveLine="changeActiveLineController"
          @endAnimation="endAnimation"
          :active-screen="true"
          :active-index-line="activeIndex"
          :line-controller="$tm('historyScreen.historyInfo')"/>
      <line-controller-container
          v-else
          @changeActiveLine="changeActiveLineController"
          @endAnimation="endAnimation"
          :active-screen="true"
          :active-index-line="activeIndex"
          :line-controller="$tm('historyScreen.historyInfoMobile')"/>

      <icon-arrow v-if="activeMobile"
                  @click="slideNext"
                  direction="bottom"/>

      <transition name="fade-opacity-lazy" mode="out-in">
        <div class="bottom-info-container" v-if="activeBottomContent">
          <swiper class="swiper"
                  @slide-change="onSwiperMain"
                  @swiper="changeSettingSwiper"
                  :slides-per-view="1"
                  :modules="modules"
                  :speed="1000"
                  :breakpoints="
                  {
                    0: {
                      allowTouchMove: true,
                    },
                    1600: {
                      allowTouchMove: false
                    }
                  }"
                  :mousewheel="true"
                  :direction="activeDirection"
                  :effect="'fade'">
            <swiper-slide v-for="item in 4">
              <div class="bottom-info-content">
                <transition :name="activeAnimationLeft" mode="out-in">
                  <div class="left-info-container" :key="activeInfoHistory">
                    <main-paragraph v-for="(item, index) in activeInfoHistory.description" :key="index">
                      {{ item }}
                    </main-paragraph>
                  </div>
                </transition>
                <transition :name="activeAnimationRight" mode="out-in">
                  <div class="right-info-container" :key="activeInfoHistory">
                    <circle-block v-if="activeInfoHistory.id === 3"/>
                    <div v-else>
                      <transition name="fade-opacity-move-y-revert">
                        <year-block :history-text="activeInfoHistory.text.split(' ')"/>
                      </transition>
                    </div>
                  </div>
                </transition>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </transition>

      <transition name="fade-opacity-1">
        <button-title-mobile v-if="activeMobile && activeTitle"
                             :active-button="false"
                             :main-title="$t('breadCrumb.dynamicTitle.history.staticTitle')"/>
      </transition>
    </div>
</template>

<script>
import LineControllerContainer from "@/components/entities/LineControllerContainer.vue";
import YearBlock from "@/components/shared/YearBlock.vue";
import CircleBlock from "@/components/shared/CircleBlock.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import {EffectFade, Mousewheel} from "swiper";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
import IconArrow from "@/components/shared/IconArrow.vue";

export default {
  name: "HistoryScreen",
  components: {IconArrow, ButtonTitleMobile, MainParagraph, CircleBlock, YearBlock, LineControllerContainer},
  data() {
    return {
      typeHistory: undefined,
      historySwiper: '',
      activeBottomContent: false,
      modules: [EffectFade, Mousewheel],
      activeTitle: false
    }
  },
  created() {
    this.typeHistory = this.$route.params.typeHistory
    this.$watch(
        () => this.$route.params,
        (toParams) => {
          this.typeHistory = toParams?.typeHistory
        }
    )
  },
  mounted() {
    if(this.activeMobile) {
      this.activeTitle = true
      setTimeout(() => {
        this.endAnimation();
      }, 250)
    }
  },

  computed: {

    activeAnimationLeft() {
      if(this.activeMobile) {
        return 'fade-translate-y-revert'
      } else {
        return 'fade-translate-x'
      }
    },


    activeAnimationRight() {
      if(this.activeMobile) {
        return 'fade-translate-y-revert'
      } else {
        return 'fade-translate-x-revert'
      }
    },

    activeInfoHistory() {
      return this.$tm('historyScreen.historyInfo')[this.activeIndex]
    },

    activeIndex() {
        switch (this.typeHistory) {
          case 'past':
            return 0;
          case '17-20':
            return 1;
          case '21-23':
            return 2;
          case 'vision':
            return 3;
        }
    },

    activeDirection() {
        return 'vertical'
    }
  },


  methods: {


    slideNext() {
      this.historySwiper.slideNext();
    },

    endAnimation() {
      this.activeBottomContent = true;
      setTimeout(() => {
        if(this.$route.typeHistory === 'vision') {
          this.historySwiper.slideTo(this.activeIndex, 3, false)
        } else {
          this.historySwiper.slideTo(this.activeIndex, 0, false)
        }
        this.checkBlock();
      }, 50)
    },

    changeActiveLineController(id) {
      this.historySwiper.slideTo(id, 500, false)
      this.changeActiveLine(id)
    },

    changeActiveLine(id) {
      switch (id) {
        case 0:
          this.$router.push({name: 'brand-history-page', params: {typeHistory: 'past'}})
          break;
        case 1:
          this.$router.push({name: 'brand-history-page', params: {typeHistory: '17-20'}})
          break;
        case 2:
          this.$router.push({name: 'brand-history-page', params: {typeHistory:  '21-23'}})
          break;
        case 3:
          this.$router.push({name: 'brand-history-page', params: {typeHistory: 'vision'}})
          break;
      }
    },

    changeOrange() {
      if(this.orangeActive !== 2) {
        setTimeout(() => {
          this.orangeActive++;
          this.changeOrange();
        },500)
      } else {
        setInterval(() => {
          if(this.orangeActive === 2) {
            this.orangeActive = 0
          } else {
            this.orangeActive++;
          }
        },2000)
      }
    },

    changeSettingSwiper(swiper) {
      this.historySwiper = swiper
    },

    onSwiperMain(swiper) {
        this.changeActiveLine(swiper.activeIndex)
      this.checkBlock();
    },

    checkBlock() {
        if(this.historySwiper.isBeginning) {
          this.$emit('blockNextSlide', true)
          setTimeout(() => {
            this.$emit('blockPrevSlide', false)
          },100)
        } else if(this.historySwiper.isEnd){
          this.$emit('blockPrevSlide', true)
          setTimeout(() => {
            this.$emit('blockNextSlide', false)
          },100)
        } else {
          this.$emit('blockPrevSlide', true)
          this.$emit('blockNextSlide', true)
        }
    },

  }

}
</script>

<style scoped lang="scss">
  .history-screen-container {
    .bottom-info-container {
      width: 100%;
      height: 80%;

      .swiper {
        width: 100%;
        height: 100%;
        .bottom-info-content {
          display: flex;
          flex-direction: row;
          height: 100%;
          position: relative;

          .left-info-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: justify;
            width: 50%;
          }

          .right-info-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
          }
        }
      }
    }
  }
  @media (max-width: 3024px){
    .history-screen-container {
      .bottom-info-container {
        .left-info-container {
          row-gap: 32px;
          padding: 0 128px;
        }
      }
    }
  }

  @media (max-width: 1920px) {
    .history-screen-container {
      .bottom-info-container {
        .left-info-container {
          row-gap: 24px;
          padding: 0 128px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .history-screen-container {
      .bottom-info-container {
        .left-info-container {
          row-gap: 28px;
          padding: 0 96px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .history-screen-container {
      .bottom-info-container {
        .left-info-container {
          row-gap: 16px;
          padding: 0 64px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .history-screen-container {
      .bottom-info-container {
        .left-info-container {
          row-gap: 28px;
          padding: 0 96px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .history-screen-container {
      .bottom-info-container {
        .left-info-container {
          row-gap: 16px;
          padding: 0 64px;
        }
      }
    }
  }
  @media (max-width: 769px) {
    .history-screen-container {
      padding: var(--burger-height) var(--controller-padding) var(--main-padding-bottom);
      flex-direction: column-reverse;


      .icon-arrow-container {
        position: absolute;
        z-index: 950;
        bottom: 18px;
        left: 50%;
      }

      .bottom-info-container {
        .swiper {
          .bottom-info-content {
            flex-direction: column;

            .left-info-container {
              width: 100%;
              row-gap: 12px;
              padding: 0;

              .main-paragraph-container {
                line-height: 130%;
              }
            }

            .right-info-container {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .history-screen-container {
      .bottom-info-container {
        .swiper {
          .bottom-info-content {
            .left-info-container {
              row-gap: 12px;
            }
          }
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .history-screen-container {
      .bottom-info-container {
        .swiper {
          .bottom-info-content {
            .left-info-container {
              .main-paragraph-container {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .history-screen-container {
      .bottom-info-container {
        .swiper {
          .bottom-info-content {
            .left-info-container {
              row-gap: 12px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 320px) {
    .history-screen-container {
      .bottom-info-container {
        .swiper {
          .bottom-info-content {
            .left-info-container {
              .main-paragraph-container {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .history-screen-container {
      .bottom-info-container {
        .left-info-container {
          row-gap: 64px;
          padding: 0 160px;
        }

      }
    }
  }

</style>