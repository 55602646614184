<template>


  <div class="burger-menu-container">

    <div class="top-info-content">
      <div class="logo-icon-container" @click="routToHome">
        <img v-if="activeColor" :src="getUrlLogo(logoText + '-dark.svg')" alt="">
        <img v-else :src="getUrlLogo(logoText + '-light.svg')" alt="">
      </div>
      <div class="button-burger-container" @click="changeStateBurgerMenu">
        <transition name="fade-scale">
          <div class="button-burger-content" v-if="activeBurgerMenu" >
              <button-icon :focus-param="true" info-icon="cross-hover.svg"/>
          </div>
          <div class="button-burger-content" v-else>
            <button-icon class="burger" v-if="activeColor && activeSubIndex !== 2" :focus-param="true" info-icon="burger-dark.svg"/>
            <button-icon class="burger" v-else-if="activeColor && activeSubIndex === 2" :focus-param="true" info-icon="burger-dark-strategy.svg"/>
            <button-icon class="burger" v-else-if="!activeColor && activeSubIndex !== 2" :focus-param="true" info-icon="burger-light.svg"/>
            <button-icon class="burger" v-else-if="!activeColor && activeSubIndex === 2" :focus-param="true" info-icon="burger-light-strategy.svg"/>
          </div>
        </transition>
      </div>
    </div>

    <transition name="fade-translate-x-card-revert">
      <burger-menu-content v-if="activeBurgerMenu"
                           @changeStateModalConsulting="changeStateModalConsulting"
                           @closeBurgerMenu="changeStateBurgerMenu"/>
    </transition>

  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import BurgerMenuContent from "@/components/entities/BurgerMenuContent.vue";
import {mapActions} from "vuex";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";

export default {
  name: "BurgerMenu",
  components: {ModalConsulting, BurgerMenuContent, ButtonIcon},
  data() {
    return {
      logoText: 'logo',
      activeBurgerMenu: false
    }
  },
  computed: {
    activeSubIndex() {
      return this.$store.state.activeSubIndex;
    }
  },
  methods: {
    ...mapActions(['ACTIVE_INDEX_PAGE', 'IS_ACTIVE_MODAL_CONSULTING']),
    changeStateBurgerMenu() {
      this.activeBurgerMenu = !this.activeBurgerMenu;
    },
    routToHome() {
      this.ACTIVE_INDEX_PAGE(-1)
      this.$router.push({name: 'home-logo-page'})
    },

    changeStateModalConsulting() {
      this.IS_ACTIVE_MODAL_CONSULTING(true)
    }
  }
}
</script>

<style scoped lang="scss">
  .burger-menu-container {
    position: fixed;
    width: 100%;
    z-index: 900;

    .top-info-content {
      z-index: 902;
      position: absolute;
      top: 0;
      height: var(--burger-height);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;


      .logo-icon-container {
        height: 100%;

        & img {
          width: 100%;
          object-fit: contain;
          height: 100%;
        }
      }

      .button-burger-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon-img-container {
        opacity: 1;
        transition: inherit;
      }
    }
  }

  @media (max-width: 769px) {
    .burger-menu-container {
      .top-info-content {
        padding: 14px;

        .logo-icon-container {
          max-width: 36.47px;
          max-height: 20px;
        }

        .button-burger-container {
          max-width: 18px;
          height: 34px;
        }

        .icon-img-container {
          &.burger {
            max-width: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .burger-menu-container {
      .top-info-content {
        padding: 16px;

        .logo-icon-container {
          max-width: 43.764px;
          max-height: 24px;
        }

        .button-burger-container {
          width: 34px;
          height: 34px;
        }

        .icon-img-container {
          &.burger {
            max-width: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 375px){

  }

  @media (max-width: 320px){

  }
</style>