<template>
  <transition name="fade-opacity-lazy" mode="out-in">
    <div style="position: relative;" v-if="!activeAnimationColor" :key="activeEng">


      <transition name="fade-opacity-lazy">
        <modal-consulting v-if="activeModalConsulting"
                          :active-text="{
                            mainTitle: this.$t('consultingModal.mainTitle'),
                            resultTitle: this.$t('consultingModal.resultTitle'),
                            resultText: this.$t('consultingModal.resultText'),
                        }"
                          @closeModal="changeStateModalConsulting"/>
      </transition>

      <transition name="fade-opacity-lazy">
        <modal-login v-if="activeModalLogin"
                     :active-title="{
                      type: 'library',
                      mainTitle: $t('modalLoginLibrary.mainTitle'),
                      inputInfo: $tm('modalLoginLibrary.inputInfo'),
                   }"
                     @closeModal="changeStateModalLogin"/>
      </transition>

      <transition name="fade-translate-y">
        <burger-menu v-if="activeMobile && $route.name !== 'home-logo-page'"/>
      </transition>


      <transition name="fade-opacity-lazy">
        <breadcrumbs v-if="activeBreadcrumb && !activeMobile"/>
      </transition>
      <transition name="fade-opacity-lazy">
        <cookie-screen v-if="activeCookieModal" @closeCookie="changeFalseCookie"/>
      </transition>
      <transition name="fade-translate-x-revert">
        <main-page-controller v-if="activeMountController && !activeMobile"/>
      </transition>
      <transition name="fade-translate-x">
        <header-controller v-if="activeIndexPage === 3 && !activeMobile"/>
      </transition>
      <router-view v-slot="{ Component }">
        <transition name="fade-opacity-lazy">
          <component :is="Component"/>
        </transition>
      </router-view>
    </div>
  </transition>


</template>

<script>
import {mapActions} from "vuex";
import CookieScreen from "@/components/entities/cookie/CookieScreen.vue";
import MainPageController from "@/components/widgets/MainPageController.vue";
import ModalMistakes from "@/components/entities/ModalMistakes.vue";
import Breadcrumbs from "@/components/entities/Breadcrumbs.vue";
import HeaderController from "@/components/widgets/HeaderController.vue";
import BurgerMenu from "@/components/entities/BurgerMenu.vue";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";
import ModalLogin from "@/components/entities/ModalLogin.vue";

export default {
  name: "MainLayout",
  components: {
    ModalLogin,
    ModalConsulting,
    BurgerMenu, HeaderController, Breadcrumbs, ModalMistakes, MainPageController, CookieScreen},

  data() {
    return {
      activeMountController: false,
      activeBlockGo: true
    }
  },
  mounted() {
    this.$route.params.lang === 'en' ? this.$i18n.locale = 'en' :  this.$i18n.locale = 'ru'
    document.documentElement.lang = this.$i18n.locale
    this.checkSize();
    this.checkTouch();
    this.checkCookie();
    this.changeMountIndexPage();
    setTimeout(() => {
      this.activeMountController = this.activeController
    }, 500)
    addEventListener('touchstart', this.checkTouch)
    addEventListener('resize', this.checkSize)
  },

  watch: {
    '$route'(){
      this.changeMountIndexPage();
    },

    activeMobile() {
        if(!this.activeBlockGo) {
          this.$router.go(0)
        }
    },

    activeController() {
      this.activeMountController = this.activeController;
    }
  },

  computed: {
    activeAnimationColor() {
      return this.$store.state.activeAnimationColor
    },

    activeModalConsulting() {
      return this.$store.state.activeModalConsulting;
    },

    activeModalLogin() {
      return this.$store.state.activeModalLogin;
    },

    activeCookieModal() {
      return this.$store.state.activeCookieModal;
    },
    activeController() {
      return !this.$route.name.includes('contacts') && this.activeIndexPage !== 3 && !this.$route.name.includes('home-logo') && this.$route.name !== 'home-page';
    },
    activeIndexPage() {
      return this.$store.state.activeIndexPage;
    },
    activeMainIndex() {
      return this.$store.state.activeMainIndex;
    },
    activeHomeIndex() {
      return this.$store.state.activeHomeIndex;
    },

    activeBreadcrumb() {
      return (this.activeIndexPage === 0 && this.activeMainIndex !== 5) || this.activeIndexPage === 1
    }
  },

  unmounted() {
    removeEventListener('resize',this.checkSize);
  },
  methods: {
  ...mapActions(['ACTIVE_COOKIE_MODAL', 'ACTIVE_MOBILE', 'ACTIVE_TOUCH_ALLOW',
    'ACTIVE_BLOCK_SWIPER', 'IS_ACTIVE_MODAL_CONSULTING', 'IS_ACTIVE_MODAL_LOGIN']),

    ...mapActions(['ACTIVE_INDEX_PAGE', 'ACTIVE_MAIN_INDEX', 'ACTIVE_SUB_INDEX']),


    changeMountIndexPage() {
      if(this.$route.name.includes('main')) {
        this.ACTIVE_INDEX_PAGE(0)
        if(!this.activeMobile) {
          this.ACTIVE_MAIN_INDEX(this.$route.meta?.idSlide - 2)
        } else {
          this.ACTIVE_MAIN_INDEX(this.$route.meta?.idSlideMobile)
        }
      } else if (this.$route.name.includes('brand')) {
        this.ACTIVE_INDEX_PAGE(1)
        this.ACTIVE_SUB_INDEX(this.$route.meta?.idSlide)
      } else if(this.$route.name.includes('static-sub')) {
        this.ACTIVE_INDEX_PAGE(2)
      } else if(this.$route.name.includes('static')) {
        this.ACTIVE_INDEX_PAGE(3)
      }else {
        this.ACTIVE_INDEX_PAGE(-1)
      }
    },

    checkTouch() {
      if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
        this.ACTIVE_TOUCH_ALLOW(true)
      } else {
        this.ACTIVE_TOUCH_ALLOW(false)
      }
    },

    checkSize() {
      this.innerHeight = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${this.innerHeight * 0.01}px`);
      this.innerWidth = window.innerWidth;
      document.documentElement.style.setProperty('--vw', `${this.innerWidth * 0.01}px`);
      if(this.innerWidth <= 769) {
        this.ACTIVE_MOBILE(true)
      } else {
        this.ACTIVE_MOBILE(false)
      }
      this.checkTouch();
      if(this.activeBlockGo) {
        setTimeout(() => {
          this.activeBlockGo = false
        }, 250)
      }
    },

    checkCookie() {
      this.checkLocalStorageCookie();
    },

    checkLocalStorageCookie() {
      if(!Boolean(localStorage.getItem('cookieActive'))) {
          if (this.activeHomeIndex === 0) {
            setTimeout(this.changeTrueCookie, 4500);
          } else {
            setTimeout(this.changeTrueCookie, 1000);
          }
      } else {
        this.ACTIVE_BLOCK_SWIPER(false)
      }
    },

    changeTrueCookie() {
      this.ACTIVE_COOKIE_MODAL(true)
    },

    changeFalseCookie(item) {
      this.ACTIVE_COOKIE_MODAL(item)
    },

    changeStateModalConsulting() {
        this.IS_ACTIVE_MODAL_CONSULTING(!this.activeModalConsulting)
    },

    changeStateModalLogin() {
      this.IS_ACTIVE_MODAL_LOGIN(!this.activeModalLogin)
    }
  }
}
</script>

<style scoped>

</style>