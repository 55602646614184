<template>
  <div class="line-controller-container">
    <div class="line-controller-content" v-for="lineText in lineController" :key="lineText.id">
      <transition name="fade-translate-x-revert">
        <line-controller @changeActiveLine="changeActiveLine"
                         v-show="isVisible(lineText.id)"
                         :line-text="lineText"
                         :active-line="activeIndexLine"/>
      </transition>
    </div>
  </div>
</template>

<script>
import LineController from "@/components/shared/LineController.vue";

export default {
  name: "LineControllerContainer",
  components: {LineController},
  props: {
    lineController: {
      type: Array,
      required: true
    },
    activeIndexLine: {
      type: Number,
      required: true
    },
    activeScreen: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      activeItem: -1
    }
  },

  mounted() {
    if(this.activeScreen) {
      if(!this.activeMobile) {
        this.showNextItem();
      } else {
        this.activeItem = this.lineController.length
      }
    }
  },

  watch: {
    activeScreen() {
      if(this.activeScreen) {
        this.showNextItem();
      }
    }
  },

  methods: {

    changeActiveLine(id, position) {
      this.$emit('changeActiveLine', id, position)
    },

    showNextItem() {
      if (this.activeItem < this.lineController.length) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 500)
      }

      if(this.activeItem === this.lineController.length - 2) {
        this.$emit('endAnimation')
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
.line-controller-container {
  position: relative;
  display: flex;
  width: 100%;
  z-index: 500;
  flex-direction: row;

  .line-controller-content {
    width: 100%;
  }
}

@media (max-width: 3024px){
  .line-controller-container {
    max-height: 128px;
    padding: 32px 80px;
  }
}

@media (max-width: 1920px) {
  .line-controller-container {
    max-height: 123px;
    padding: 32px 48px;
  }
}

@media (max-width: 1600px) {
  .line-controller-container {
    max-height: 81px;
    padding: 16px 24px;
  }
}

@media (max-height: 800px) {
  .line-controller-container {
    max-height: 68px;
    padding: 16px 24px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .line-controller-container {
    max-height: 81px;
    padding: 16px 24px;
  }
}

@media (max-width: 1133px) {
  .line-controller-container {
    max-height: 68px;
    padding: 36px 70px 36px 40px;
  }
}

@media (max-width: 769px) {
  .line-controller-container {
    width: calc(100% + 16px);
    max-height: 52px;
    margin: 0 -8px;
    padding: 0 0 16px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .line-controller-container {
    max-height: 60px;
    padding: 0 0 24px;
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .line-controller-container {
    width: calc(100% + 16px);
    max-height: 36px;
    margin: 0 -8px;
    padding: 0 0 8px;
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .line-controller-container {
    width: calc(100% + 16px);
    max-height: 52px;
    margin: 0 -8px;
    padding: 0 0 16px;
  }
}


@media (max-width: 320px) {
  //iPhone SE
}


@media (min-width: 3025px) {
  .line-controller-container {
    max-height: 218px;
    padding: 64px 128px;
  }
}

</style>