<template>
  <div class="toggle-container">
    <toggle-local/>
    <toggle-theme :name-check="nameCheck"/>
  </div>
</template>

<script>
import ToggleLocal from "@/components/shared/ToggleLocal.vue";
import ToggleTheme from "@/components/shared/ToggleTheme.vue";

export default {
  name: "ToggleThemeLocal",
  components: {ToggleTheme, ToggleLocal},
  props: {
    nameCheck: {
      type: String,
      required: true
    },
  },
}
</script>

<style scoped lang="scss">
    .toggle-container {
      display: flex;
      flex-direction: row-reverse;
    }


@media (max-width: 3024px){
      .toggle-container {
        column-gap: 12px;
      }
    }


@media (max-width: 1920px) {
      .toggle-container {
        column-gap: 10px;
      }
    }



@media (max-width: 1600px) {
      .toggle-container {
        column-gap: 8px;
      }
    }


@media (max-height: 800px) {
      .toggle-container {
        column-gap: 6px;
      }
    }

@media (max-width: 1400px) and (min-height: 801px) {

      .toggle-container {
        column-gap: 8px;
      }
    }
    @media (max-width: 1133px) {
      .toggle-container {
        column-gap: 6px;
      }
    }



@media (min-width: 3025px) {
      .toggle-container {
        column-gap: 16px;
      }
    }

</style>