<template>
  <div class="icon-arrow-container">
    <img :src="getUrlIcons('arrow-' + direction + '.svg')" :alt="'arrow-' + direction">
  </div>
</template>

<script>
export default {
  name: "IconArrow",
  props: {
    direction: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .icon-arrow-container {
    cursor: pointer;
    & img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 3024px){
    .icon-arrow-container {
      width: 18px;
      height: 18px;
    }
  }


  @media (max-width: 1920px) {
    .icon-arrow-container {
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: 1600px) {
    .icon-arrow-container {
      width: 14px;
      height: 14px;
    }
  }
  @media (max-height: 800px) {
    .icon-arrow-container {
      margin-top: -3px;
      width: 10px;
      height: 10px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .icon-arrow-container {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 1133px) {
    .icon-arrow-container {
      margin-top: -3px;
      width: 10px;
      height: 10px;
    }
  }
  @media (max-width: 769px) {
    //iPhone 14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //iPhone Plus
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .icon-arrow-container {
      width: 32px;
      height: 32px;
    }
  }

</style>