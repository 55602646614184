import { createRouter, createWebHistory } from 'vue-router'
import MainPage from "@/views/main-page.vue";
import MainLayout from "@/layout/MainLayout.vue";
import BusinessScreen from "@/components/features/main-page/BusinessScreen.vue";
import StructureScreen from "@/components/features/main-page/StructureScreen.vue";
import TeamScreen from "@/components/features/main-page/TeamScreen.vue";
import ProjectScreen from "@/components/features/main-page/ProjectScreen.vue";
import PartnersScreen from "@/components/features/main-page/PartnersScreen.vue";
import FooterScreen from "@/components/features/main-page/FooterScreen.vue";
import PhilosophyScreen from "@/components/features/sub-page/PhilosophyScreen.vue";
import MissionScreen from "@/components/features/sub-page/MissionScreen.vue";
import StrategyScreen from "@/components/features/sub-page/StrategyScreen.vue";
import ValuesScreen from "@/components/features/sub-page/ValuesScreen.vue";
import HistoryScreen from "@/components/features/sub-page/HistoryScreen.vue";
import SubbrandsScreen from "@/components/features/sub-page/SubbrandsScreen.vue";
import MainDivisionScreen from "@/components/features/division-page/MainDivisionScreen.vue";
import TechnologiesScreen from "@/components/features/division-page/TechnologiesScreen.vue";
import ServiceScreen from "@/components/features/division-page/ServiceScreen.vue";
import SecretaryScreen from "@/components/features/static-page/SecretaryScreen.vue";
import ComplienceScreen from "@/components/features/static-page/ComplienceScreen.vue";
import HRScreen from "@/components/features/static-page/HRScreen.vue";
import InnovationScreen from "@/components/features/static-page/InnovationScreen.vue";
import RPScreen from "@/components/features/static-page/RPScreen.vue";
import HomePage from "@/views/home-page.vue";
import SubPage from "@/views/sub-page.vue";
import StartScreen from "@/components/features/main-page/StartScreen.vue";
import NavigationScreen from "@/components/features/NavigationScreen.vue";
import store from "@/store";
import i18n from "@/locales/i18n-config";
import reusedCode from "@/mixins/reusedCode";

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: () => {
      return { path: '/ru'}
    },
  },
  {
    path: '/',
    redirect: '/ru',
  },
  {
    path: '/:lang',
    props: {
      default: 'en'
    },
    children: [
      {
        path: '',
        name: 'home',
        redirect: { name: 'home-page' },
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'home-page',
            meta: {
              breadCrumbParam: {
                activeLength: 0
              }
            },
            component: HomePage,
            children:
                [
                  {
                    path: '',
                    name: 'home-logo-page',
                    meta: {
                      idSlideMobile: 0,
                      idSlide: 0,
                      breadCrumbParam: {
                        activeLength: 0,
                        isMobile: false
                      }
                    },
                    component: StartScreen
                  },
                  {
                    path: 'home',
                    name: 'home-navigation-page',
                    meta: {
                      idSlide: 1,
                      idSlideMobile: null,
                      breadCrumbParam: {
                        activeLength: 0
                      }
                    },
                    component: NavigationScreen
                  }
                ]
          },
          {
            path: 'main',
            name: 'main-page',
            component: HomePage,
            children: [
              {
                path: 'home/business-model',
                name: 'main-business-page',
                component: BusinessScreen,
                meta: {
                  idSlideMobile: 1,
                  idSlide: 2,
                  breadCrumbParam: {
                    activeClick: true,
                    activeProps: true,
                    activeLength: 2,
                    activeSubProps: false,
                    linkStatic: 'main-business-page',
                    linkProps: undefined,
                    type: 'business',
                    typeProps: 'typeBusiness'
                  }
                },
                children: [
                  {
                    path: ':typeBusiness',
                    name: 'main-business-type-page',
                    props: true,
                    meta: {
                      idSlideMobile: 1,
                      idSlide: 2,
                      breadCrumbParam: {
                        activeClick: true,
                        activeProps: true,
                        activeLength: 3,
                        activeSubProps: false,
                        linkStatic: 'main-business-page',
                        linkProps: undefined,
                        type: 'business',
                        typeProps: 'typeBusiness'
                      }
                    },
                    component: BusinessScreen
                  }
                ]
              },
              {
                path: 'home/division',
                meta: {
                  idSlideMobile: 2,
                  idSlide: 3,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: false,
                    activeLength: 2,
                    activeSubProps: false,
                    linkStatic: 'main-division-page',
                    linkProps: undefined,
                    type: 'division',
                    typeProps: undefined
                  }
                },
                name: 'main-division-page',
                component: StructureScreen,
              },
              {
                path: 'home/management/:typeManagement',
                props: true,
                meta: {
                  idSlideMobile: 3,
                  idSlide: 4,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: true,
                    activeLength: 3,
                    activeSubProps: false,
                    linkStatic: undefined,
                    linkProps: undefined,
                    type: 'management',
                    typeProps: 'typeManagement'
                  }
                },
                name: 'main-management-page',
                component: TeamScreen,
              },
              {
                path: 'home/projects/:typeProject',
                props: true,
                meta: {
                  idSlideMobile: 4,
                  idSlide: 5,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: true,
                    activeLength: 3,
                    activeSubProps: false,
                    linkStatic: undefined,
                    linkProps: undefined,
                    type: 'projects',
                    typeProps: 'typeProject'
                  }
                },
                name: 'main-project-page',
                component: ProjectScreen,
              },
              {
                path: 'home/partners',
                meta: {
                  idSlideMobile: 5,
                  idSlide: 6,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: false,
                    activeLength: 2,
                    activeSubProps: false,
                    linkStatic: undefined,
                    linkProps: undefined,
                    type: 'partners',
                    typeProps: undefined
                  }
                },
                name: 'main-partners-page',
                component: PartnersScreen,
              },
              {
                path: 'home/contacts',
                meta: {
                  idSlideMobile: 6,
                  idSlide: 7,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: false,
                    activeLength: 0,
                    activeSubProps: false,
                    linkStatic: undefined,
                    linkProps: undefined,
                    type: undefined,
                    typeProps: undefined
                  }
                },
                name: 'main-contacts-page',
                component: FooterScreen,
              }
            ]
          },
          {
            path: 'home/division/:typeDivision',
            props: true,
            meta: {
              activeDivisionPage: true,
              idSlide: 3,
              breadCrumbParam: {
                activeClick: true,
                activeProps: true,
                activeSubProps: false,
                activeLength: 3,
                linkStatic: 'main-division-page',
                linkProps: undefined,
                type: 'division',
                typeProps: 'typeDivision'
              }
            },
            name: 'main-type-division-page',
            component: MainDivisionScreen
          },
          {
            path: 'home/division/:typeDivision/technologies/:typeTechnologies',
            props: true,
            meta: {
              activeDivisionTechnologiesPage: true,
              idSlide: 3,
              breadCrumbParam: {
                activeClick: true,
                activeProps: true,
                activeLength: 5,
                activeSubProps: false,
                linkStatic: 'main-division-page',
                linkProps: undefined,
                type: 'division',
                typeProps: 'typeDivision'
              }
            },
            name: 'main-type-division-technologies-page',
            component: TechnologiesScreen
          },
          {
            path: 'home/division/:typeDivision/:typeServices/:typeSubServices',
            props: true,
            meta: {
              activeDivisionServicesPage: true,
              idSlide: 3,
              breadCrumbParam: {
                activeClick: true,
                activeProps: true,
                activeLength: 5,
                activeSubProps: false,
                linkStatic: 'main-division-page',
                linkProps: undefined,
                type: 'division',
                typeProps: 'typeDivision'
              }
            },
            name: 'main-type-division-services-page',
            component: ServiceScreen
          },
          {
            path: 'brand',
            name: 'sub-page',
            component: SubPage,
            children: [
              {
                path: 'philosophy',
                meta: {
                  idSlide: 0,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: false,
                    activeLength: 3,
                    activeSubProps: false,
                    linkStatic: undefined,
                    linkProps: undefined,
                    type: 'philosophy',
                    typeProps: undefined
                  }
                },
                name: 'brand-philosophy-page',
                component: PhilosophyScreen,
              },
              {
                path: 'mission',
                meta: {
                  idSlide: 1,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: false,
                    activeLength: 3,
                    activeSubProps: false,
                    linkStatic: undefined,
                    linkProps: undefined,
                    type: 'mission',
                    typeProps: undefined
                  }
                },
                name: 'brand-mission-page',
                component: MissionScreen,
              },
              {
                path: 'strategy',
                meta: {
                  idSlide: 2,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: false,
                    activeLength: 3,
                    activeSubProps: false,
                    linkStatic: undefined,
                    linkProps: undefined,
                    type: 'strategy',
                    typeProps: undefined
                  }
                },
                name: 'brand-strategy-page',
                component: StrategyScreen,
              },
              {
                path: 'values',
                meta: {
                  idSlide: 3,
                  breadCrumbParam: {
                    activeClick: true,
                    activeProps: true,
                    activeLength: 3,
                    activeSubProps: false,
                    linkStatic: 'brand-values-page',
                    linkProps: undefined,
                    type: 'values',
                    typeProps: 'typeValues'
                  }
                },
                name: 'brand-values-page',
                component: ValuesScreen,
                children: [
                  {
                    path: ':typeValues',
                    name: 'brand-values-type-page',
                    props: true,
                    meta: {
                      idSlide: 3,
                      breadCrumbParam: {
                        activeClick: true,
                        activeProps: true,
                        activeLength: 4,
                        activeSubProps: false,
                        linkStatic: 'brand-values-page',
                        linkProps: undefined,
                        type: 'values',
                        typeProps: 'typeValues'
                      },
                    },
                    component: ValuesScreen,
                  }
                ]
              },
              {
                path: 'history/:typeHistory',
                meta: {
                  idSlide: 4,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: true,
                    activeLength: 4,
                    activeSubProps: false,
                    linkStatic: undefined,
                    linkProps: undefined,
                    type: 'history',
                    typeProps: 'typeHistory'
                  }
                },
                props: true,
                name: 'brand-history-page',
                component: HistoryScreen,
              },
              {
                path: 'subbrands',
                meta: {
                  idSlide: 5,
                  breadCrumbParam: {
                    activeClick: false,
                    activeProps: false,
                    activeLength: 3,
                    activeSubProps: false,
                    linkStatic: undefined,
                    linkProps: undefined,
                    type: 'subbrands',
                    typeProps: undefined
                  }
                },
                name: 'brand-subbrands-page',
                component: SubbrandsScreen,
              },
            ]
          },

          {
            path: 'helper-ai',
            name: 'static-helper-ai-page',
            meta: {
              breadCrumbParam: {
                activeLength: 0
              }
            },
            component: SecretaryScreen,
          },

          {
            path: 'compliance',
            name: 'static-compliance-page',
            meta: {
              breadCrumbParam: {
                activeLength: 0
              }
            },
            component: ComplienceScreen,
          },

          {
            path: 'rp/:typeNews',
            name: 'static-rp-page',
            meta: {
              breadCrumbParam: {
                activeLength: 0
              }
            },
            props: true,
            component: RPScreen,
          },

          {
            path: 'hr',
            name: 'static-hr-page',
            meta: {
              breadCrumbParam: {
                activeLength: 0
              }
            },
            component: HRScreen,
          },

          {
            path: 'innovation/:typeInnovation',
            props: true,
            name: 'static-sub-innovation-page',
            meta: {
              breadCrumbParam: {
                activeLength: 0
              }
            },
            component: InnovationScreen,
          },
        ]
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})


router.beforeEach((to, from, next) => {
  const lang = to.params?.lang;
  i18n.global.locale = lang;
  store.dispatch('LAST_ROUT', from)
  let key = Object.keys(i18n.global.tm('defaultMetaTag'))
  for (const el of key) {
    reusedCode.methods.updateMetaTag(el, to.meta[el])
  }
  if (!['en', 'ru'].includes(lang) || lang === undefined) {
    return next({ path: `/ru${to.path}` });
  }
  return next();
})

export default router
