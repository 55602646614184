<template>
  <transition name="fade-opacity-lazy" mode="out-in">
    <label :for="'fileInput ' + activeInput" class="file-input-content">
      <label :for="'fileInput ' + activeInput" class="icon-and-text-container title" :class="{'hidden' : fileSrc === null}">
        <p class="input-text-content" :class="{'eng' : activeEng}">
          {{ $t('inputFile.fileOpen') }}
        </p>
        <p class="input-text-content file-name">
          {{ fileName }}
        </p>
      </label>
      <input accept=".doc, .docx, .pdf" name="file" type="file" :id="'fileInput ' + activeInput"  @change="handleFileChange">
      <label :for="'fileInput ' + activeInput" class="icon-and-text-container">
        <div class="icon-and-text-content" :class="{'eng' : activeEng}">
          <button-icon :info-icon="activeIcon"/>
          <sub-paragraph>
            {{ activeTitle }}
          </sub-paragraph>
        </div>
      </label>
      <label :for="'fileInput ' + activeInput" class="icon-and-text-container">
        <p class="input-text-content" :class="{'eng' : activeEng}">
          {{ $t('inputFile.subTitle') }}
        </p>
      </label>
    </label>
  </transition>

</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "InputFile",
  components: {SubParagraph, ButtonIcon},
  props: {
    activeInput: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fileSrc: null,
      fileName: ''
    }
  },
  computed: {
    activeTitle() {
      if(this.fileSrc === null) {
        return this.$t('inputFile.mainTitle')
      } else {
        return this.$t('inputFile.fileOpen')
      }
    },
    activeIcon() {
      if(this.fileSrc === null) {
        return 'upload.svg'
      } else {
        return 'reupload.svg'
      }
    },
  },
  methods: {

    handleFileChange(event) {
      this.fileSrc = event.target.files[0];
      this.fileName = this.fileSrc.name;
      if(this.fileSrc) {
        let fileSize = this.fileSrc.size / 1000000.0;
        if(fileSize <= 5) {
          this.$emit('changeActiveFile', this.fileSrc)
        } else {
          this.fileSrc = null;
          this.$emit('changeActiveFile', null)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">

p {
  font-weight: 200;
}


.file-input-content {
  background-color: var(--color-card-division);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: opacity .5s ease;
  opacity: .4;
  border-radius: 24px;
  max-height: 298px;
  min-height: 298px;
  padding: 32px 64px;

  .icon-and-text-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    opacity: 1;
    transition: opacity .6s ease;

    &.title {
      flex-direction: row;
    }

    &.hidden {
      opacity: 0;
    }

    .input-text-content {
      font-size: 24px;
      white-space: pre-wrap;
      text-align: center;

      &.file-name {
        text-align: center;
        width: 256px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-color: var(--color-main-visioners);
        border-bottom-style: solid;
      }
    }

    .icon-and-text-content {
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }

  &:hover {
    opacity: 1;
  }
}


  #fileInput {
    position: absolute;
    z-index: 999;
  }

.file-input-content input[type="file"] {
  display: none;
}

@media (max-width: 3024px){
  .file-input-content {
    border-radius: 16px;
    max-height: 226px;
    min-height: 226px;
    padding: 22px 44px;

    .icon-and-text-container {

      .input-text-content {
        font-size: 20px;

        &.file-name {
          width: 214px;
          border-bottom-width: 1px;
        }
      }

      .icon-and-text-content {
        column-gap: 14px;

        .sub-paragraph-container {
          font-size: 22px;
        }
      }
    }
  }
}


@media (max-width: 1920px) {
  .file-input-content {
    border-radius: 12px;
    max-height: 170px;
    min-height: 170px;
    padding: 16px 32px;

    .icon-and-text-container {

      .input-text-content {
        font-size: 16px;

        &.file-name {
          width: 170px;
          border-bottom-width: 1px;
        }
      }

      .icon-and-text-content {
        column-gap: 12px;

        .sub-paragraph-container {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .file-input-content {
    border-radius: 12px;
    max-height: 170px;
    min-height: 170px;
    padding: 16px 32px;

    .icon-and-text-container {

      .input-text-content {
        font-size: 16px;

        &.file-name {
          width: 170px;
          border-bottom-width: 1px;
        }
      }

      .icon-and-text-content {
        column-gap: 12px;

        .sub-paragraph-container {
          font-size: 18px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .file-input-content {
    border-radius: 12px;
    max-height: 142px;
    min-height: 142px;
    padding: 16px 32px;

    .icon-and-text-container {

      .input-text-content {
        font-size: 10px;

        &.file-name {
          width: 107px;
          border-bottom-width: 1px;
        }
      }

      .icon-and-text-content {
        column-gap: 12px;

        .sub-paragraph-container {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .file-input-content {
    border-radius: 12px;
    max-height: 170px;
    min-height: 170px;
    padding: 16px 32px;

    .icon-and-text-container {

      .input-text-content {
        font-size: 16px;

        &.file-name {
          width: 170px;
          border-bottom-width: 1px;
        }
      }

      .icon-and-text-content {
        column-gap: 12px;

        .sub-paragraph-container {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .file-input-content {
    border-radius: 12px;
    max-height: 142px;
    min-height: 142px;
    padding: 16px 32px;

    .icon-and-text-container {

      .input-text-content {
        font-size: 10px;

        &.file-name {
          width: 107px;
          border-bottom-width: 1px;
        }
      }

      .icon-and-text-content {
        column-gap: 12px;

        .sub-paragraph-container {
          font-size: 12px;
        }
      }
    }
  }
}
@media (max-width: 769px) {
  .file-input-content {
    opacity: 1;
    border-radius: 12px;
    max-height: 106px;
    min-height: 106px;
    padding: 10px 20px;

    .icon-and-text-container {

      .input-text-content {
        font-size: 10px;

        &.file-name {
          width: 107px;
          border-bottom-width: 1px;
        }
      }

      .icon-and-text-content {
        width: 100%;
        column-gap: 12px;

        .sub-paragraph-container {
          font-size: 12px;
        }
      }
    }
  }
}


@media (min-width: 3025px) {
  .file-input-content {
    border-radius: 24px;
    max-height: 298px;
    min-height: 298px;
    padding: 32px 64px;

    .icon-and-text-container {

      .input-text-content {
        font-size: 24px;

        &.file-name {
          width: 256px;
          border-bottom-width: 1px;
        }
      }

      .icon-and-text-content {
        column-gap: 24px;
      }
    }
  }
}

</style>