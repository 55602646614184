<template>
  <div class="input-container" @click="focusInput"
       :class="{'textarea' : info.type === 'textarea',
       'focus' : focused,
       'eng' : activeEng && info.placeholder !== 'your.email@provider.com' && info.placeholder !== 'telegram.t.me'}">

    <circle-mini-block :is-necessary="info.isNecessary" :focused="focused"/>
    <div class="letter-counter" v-if="info.type === 'textarea'">
      <p>{{this.countLetter}}/{{lengthTextArea}}</p>
    </div>
    <input :type="info.type"
           :name="info.dataIndex"
           @focus="focused = true"
           @blur="focused = false"
           :placeholder="info.placeholder"
           v-if="info.type !== 'textarea'"
           v-model="textInput">

    <textarea class="textarea"
              ref="textareaMessage"
              :name="info.dataIndex"
              @focus="focused = true"
              @blur="focused = false"
              :placeholder="info.placeholder"
              :maxlength="lengthTextArea"
              v-model="textInput" v-else></textarea>
  </div>
</template>

<script>

import CircleMiniBlock from "@/components/shared/CircleMiniBlock.vue";

export default {
  name: "InputMain",
  components: {CircleMiniBlock},
  props: {
    info: {
      type: Object,
      required: true
    },
    lengthTextArea: {
      type: Number
    },
    submitActive: {
      type: Boolean
    },
    nameBlock: {
      type: String
    },
    activeTextInput: {
      type: String
    }
  },

  mounted() {
    if(this.activeTextInput !== undefined) {
      this.textInput = this.activeTextInput
    }
  },

  data() {
    return {
      textInput: '',
      countLetter: 0,
      focused: false
    }
  },
  watch: {
    textInput() {
      this.countLetter = this.textInput.length
      this.$emit('changeTextInput', this.textInput, this.info.dataIndex)
    },
    submitActive() {
      if(this.submitActive) {
        this.textInput = ''
      }
    },
  },
  methods: {
    focusInput() {
      let block = document.querySelector("." + this.nameBlock)
      if(this.info.type !== 'textarea') {
        let input = block.querySelectorAll("input")
        input[this.info.id].focus()
      } else {
        let textarea = block.querySelectorAll("textarea")
        textarea[this.info.id].focus()
      }
    },
  }
}
</script>

<style scoped lang="scss">

.input-container {
  overflow: visible;
  cursor: text;
  display: flex;
  justify-content: center;
  background: var(--color-card-division);
  font-family: 'ProximaNova', sans-serif;
  font-weight: 200;
  color: var(--color-main-text);
  position: relative;
  transition: opacity .5s ease;
  opacity: .6;
  height: 100%;

  &.eng {
    & input, textarea {
      &::placeholder {
        font-variant: all-small-caps;
      }
    }
  }


  &:hover {
    opacity: 1;
  }

  &.focus {
    opacity: 1;
  }

  .button-icon-container {
    position: absolute;
  }

  .letter-counter {
    color: var(--color-text);
    font-family: 'ProximaNova', sans-serif;
    font-weight: 200;
    position: absolute;
    opacity: .6;
  }


  .important-input {
    position: relative;
  }


  & input, textarea {
    overflow: visible;
    width: 100%;
    height: 100%;
    font-family: 'ProximaNova', sans-serif;
    font-weight: 200;
    color: var(--color-text);
    background-color: rgba(1,1,1,0);

    &::placeholder {
      color: var(--color-text);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .textarea {
    resize: none;
    height: auto;
  }
}

@media (max-width: 3024px){
  .input-container {
    padding: 22px 44px 22px 22px;
    column-gap: 16px;
    border-radius: 16px;
    max-height: 66px;

    &.textarea {
      max-height: 194px;
      min-height: 194px;
    }



    .letter-counter {
      bottom: 22px;
      right: 44px;
      font-size: 20px;
    }

    & input, textarea {
      font-size: 22px;
    }
  }
}


@media (max-width: 1920px) {
  .input-container {
    padding: 18px 36px 18px 18px;
    column-gap: 14px;
    border-radius: 12px;
    max-height: 54px;

    &.textarea {
      max-height: 158px;
      min-height: 158px;
    }


    .letter-counter {
      bottom: 18px;
      right: 36px;
      font-size: 16px;
    }

    & input, textarea {
      font-size: 18px;
    }
  }
}

@media (max-width: 1600px) {
  .input-container {
    padding: 14px 28px 14px 14px;
    column-gap: 12px;
    border-radius: 10px;
    max-height: 42px;

    &.textarea {
      max-height: 122px;
      min-height: 122px;
    }


    .letter-counter {
      bottom: 14px;
      right: 28px;
      font-size: 12px;
    }

    & input, textarea {
      font-size: 14px;
    }
  }
}
@media (max-height: 800px) {
  .input-container {
    padding: 10px 20px 10px 10px;
    column-gap: 10px;
    border-radius: 8px;
    max-height: 32px;

    &.textarea {
      max-height: 114px;
      min-height: 114px;
    }


    .letter-counter {
      bottom: 10px;
      right: 20px;
      font-size: 10px;
    }

    & input, textarea {
      font-size: 12px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .input-container {
    padding: 14px 28px 14px 14px;
    column-gap: 12px;
    border-radius: 10px;
    max-height: 42px;

    &.textarea {
      max-height: 122px;
      min-height: 122px;
    }


    .letter-counter {
      bottom: 14px;
      right: 28px;
      font-size: 12px;
    }

    & input, textarea {
      font-size: 14px;
    }
  }
}

@media (max-width: 1133px) {
  .input-container {
    padding: 10px 20px 10px 10px;
    column-gap: 10px;
    border-radius: 8px;
    max-height: 32px;

    &.textarea {
      max-height: 114px;
      min-height: 114px;
    }

    .circle-container {
      margin-top: 3px;
    }


    .letter-counter {
      bottom: 10px;
      right: 20px;
      font-size: 10px;
    }

    & input, textarea {
      font-size: 12px;
    }
  }
}
@media (max-width: 769px) {
  .input-container {
    opacity: 1;
    padding: 10px 26px 10px 10px;
    column-gap: 10px;
    border-radius: 8px;
    max-height: 32px;

    &.textarea {
      max-height: 100%;
      min-height: 100%;
    }

    .circle-container {
      margin-top: 3px;
    }


    .letter-counter {
      bottom: 10px;
      right: 20px;
      font-size: 10px;
    }

    & input, textarea {
      font-size: 12px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  .input-container {
    padding: 8px 24px 8px 8px;
    column-gap: 10px;
    border-radius: 8px;
    max-height: 24px;

    &.textarea {
      max-height: 100%;
      min-height: 100%;
    }

    .circle-container {
      margin-top: 3px;
    }


    .letter-counter {
      bottom: 10px;
      right: 20px;
      font-size: 10px;
    }

    & input, textarea {
      font-size: 10px;
    }
  }
}

@media (min-width: 3025px) {
  .input-container {
    padding: 32px 64px 32px 32px;
    column-gap: 24px;
    border-radius: 24px;
    max-height: 96px;

    &.textarea {
      max-height: 272px;
      min-height: 272px;
    }


    .letter-counter {
      bottom: 32px;
      right: 64px;
      font-size: 24px;
    }

    & input, textarea {
      font-size: 32px;
    }
  }
}

</style>