<template>
  <div class="controller-container" :class="{'active-sub-page' : activeSubPage}">
    <p class="controller-title-content"
       @mouseenter="focusTrue(index)" @mouseleave="focusFalse"
       :class="{'active' : activeIndex === index , 'eng' : activeEng}"
       @click="changeActiveItem(index)"
       v-for="(item, index) in infoController"
       :key="index">
      <transition name="fade-scale">
        <span v-if="focusParam === index || activeIndex === index" class="circle-container"/>
      </transition>
      {{ item?.text || item }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ControllerTitleBlock",
  props: {
    activeIndex: {
      type: Number,
      required: true
    },
    infoController: {
      type: Array,
      required: true
    },
    activeSubPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focusParam: -1
    }
  },
  methods: {
    changeActiveItem(index) {
      this.$emit('changeActiveItem', index)
    },
    focusTrue(id) {
      this.focusParam = id
    },
    focusFalse() {
      this.focusParam = -1
    }
  }
}
</script>

<style scoped lang="scss">
.controller-container {
  display: flex;
  flex-direction: row;

  .controller-title-content {
    cursor: pointer;
    opacity: .2;
    transition: opacity .6s ease;
    width: fit-content;
    position: relative;
    line-height: 100%;

    &.active {
      opacity: 1;
    }

    .circle-container {
      position: absolute;
      background-color: var(--color-main-visioners);
      border-radius: 50%;
    }

    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: 3840px){
        .controller-container {
          column-gap: 128px;
          padding: 0 128px;

          &.active-sub-page {
            padding: 0 160px;
          }

          .controller-title-content {
            font-size: 46px;
            .circle-container {
              width: 8px;
              height: 8px;
              right: -12px;
              bottom: 6px;
            }
          }
        }
}

@media (max-width: 3024px){

        .controller-container {
          column-gap: 64px;
          padding: 0 64px;

          &.active-sub-page {
            padding: 0 64px;
          }


          .controller-title-content {
            font-size: 24px;
            .circle-container {
              width: 4px;
              height: 4px;
              right: -8px;
              bottom: 4px;
            }
          }
        }
}


@media (max-width: 1920px) {

        .controller-container {
          column-gap: 48px;
          padding: 0 48px;

          &.active-sub-page {
            padding: 0 48px;
          }


          .controller-title-content {
            font-size: 20px;
            .circle-container {
              width: 4px;
              height: 4px;
              right: -7px;
              bottom: 2px;
            }
          }
        }
}


@media (max-width: 1600px) {
        .controller-container {
          column-gap: 32px;
          padding: 0 32px;

          &.active-sub-page {
            padding: 0 32px;
          }


          .controller-title-content {
            font-size: 16px;
            .circle-container {
              width: 3px;
              height: 3px;
              right: -6px;
              bottom: 2px;
            }
          }
        }
}
@media (max-height: 800px) {
        .controller-container {
          column-gap: 24px;
          padding: 0 24px;

          &.active-sub-page {
            padding: 0 24px;
          }


          .controller-title-content {
            font-size: 12px;
            .circle-container {
              width: 2px;
              height: 2px;
              right: -4px;
              bottom: 2px;
            }
          }
        }
}

@media (max-width: 1400px) and (min-height: 801px) {
    .controller-container {
      column-gap: 32px;
      padding: 0 32px;

      &.active-sub-page {
        padding: 0 32px;
      }


      .controller-title-content {
        font-size: 16px;

        .circle-container {
          width: 3px;
          height: 3px;
          right: -6px;
          bottom: 2px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .controller-container {
      column-gap: 24px;
      padding: 0 24px;

      &.active-sub-page {
        padding: 0 24px;
      }


      .controller-title-content {
        font-size: 12px;

        .circle-container {
          width: 2px;
          height: 2px;
          right: -4px;
          bottom: 2px;
        }
      }
    }
  }

@media (max-width: 769px) {
  .controller-container {
    justify-content: space-around;
    column-gap: 0;
    padding: 8px 16px 16px 0;

    &.active-sub-page {
      padding: 0 24px;
    }

    .controller-title-content {
      font-size: 12px;

      .circle-container {
        width: 2px;
        height: 2px;
        right: -4px;
        bottom: 2px;
      }
    }
  }
}


@media (max-width: 320px) {
  .controller-container {
    justify-content: space-around;
    column-gap: 0;
    padding: 8px 14px 8px 0;

    &.active-sub-page {
      padding: 0 24px;
    }

    .controller-title-content {
      font-size: 10px;

      .circle-container {
        width: 2px;
        height: 2px;
        right: -4px;
        bottom: 2px;
      }
    }
  }
}

@media (min-width: 3841px) {
        .controller-container {
          column-gap: 128px;
          padding: 0 128px;

          &.active-sub-page {
            padding: 0 160px;
          }

          .controller-title-content {
            font-size: 46px;

            .circle-container {
              width: 8px;
              height: 8px;
              right: -12px;
              bottom: 6px;
            }
          }
        }
}
</style>