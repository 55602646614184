<template>
  <transition name="fade-opacity-lazy">
  <div class="hr-screen-container sub-container" v-if="activeScreen && !activeMobile">
    <transition name="fade-opacity-lazy">
      <modal-resume v-if="activeModal" @closeModal="changeStateModal"/>
    </transition>

    <div class="hr-screen-content">
      <div class="top-info-content">
        <div class="left-info-content">
          <transition name="fade-opacity-1">
            <main-title v-if="isVisible(0)">
              {{ $t('HRCenterScreen.mainTitle') }}
            </main-title>
          </transition>
            <main-paragraph :class="{'is-visible' : isVisible(1)}">
              {{ $t('HRCenterScreen.mainParagraph') }}
            </main-paragraph>
        </div>

        <transition name="fade-opacity-1">
        <div class="right-info-content" v-if="isVisible(2)">
          <button-with-icon :info-icon="'contact.svg'"
                            @click="changeStateModal"
                            :info-text="$t('HRCenterScreen.buttonHR')"/>
        </div>
        </transition>
      </div>
      <transition name="fade-opacity-1" >
      <div class="slider-container" :class="{'is-visible' : isVisible(3)}">
        <controller-title-block :info-controller="$tm('HRCenterScreen.controllerTitle')"
                                :active-sub-page="true"
                                @changeActiveItem="changeActiveHR"
                                :active-index="activeIndex"/>
        <transition name="fade-opacity-lazy"  mode="out-in">
          <slider-scroll-h-r v-if="activeIndex === 0" :active-info="activeInfo"
                             @openModal="changeStateModal"/>
          <card-h-r v-else :info-card="activeInfo" @openModal="changeStateModal"/>
        </transition>
      </div>
      </transition>
    </div>
  </div>


    <div class="hr-screen-container sub-container" v-else-if="activeScreen && activeMobile">
      <transition name="fade-opacity-lazy">
        <modal-resume v-if="activeModal" @closeModal="changeStateModal"/>
      </transition>

      <transition name="fade-opacity-lazy">
        <button-title-mobile v-if="activeMobile && activeTitle"
                             :active-button="true"
                             @returnTo="routToHome"
                             :main-title="$t('HRCenterScreen.mainTitle')"/>
      </transition>

      <div class="hr-screen-content">
          <div class="top-info-content">
            <div class="left-info-content">
                <main-paragraph :class="{'is-visible' : isVisible(0)}">
                  {{ $t('HRCenterScreen.mainParagraph') }}
                </main-paragraph>
            </div>
          </div>
        <div class="slider-container" :class="{'active-card' : activeIndex !== 0, 'is-visible' : isVisible(1)}">
            <controller-title-block :info-controller="$tm('HRCenterScreen.controllerTitle')"
                                    :active-sub-page="true"
                                    @changeActiveItem="changeActiveHR"
                                    :active-index="activeIndex"/>
              <transition name="fade-opacity-lazy" mode="out-in">
            <slider-scroll-h-r v-if="activeIndex === 0" :active-info="activeInfo"
                               @openModal="changeStateModal"/>
            <card-h-r v-else :info-card="activeInfo" @openModal="changeStateModal"/>
          </transition>
        </div>

        <div class="right-info-content mobile" :class="{'is-visible' : isVisible(5)}">
          <button-with-icon :info-icon="'contact.svg'"
                            :active-mobile="true"
                            @click="changeStateModal"
                            :info-text="$t('HRCenterScreen.buttonHR')"/>
        </div>
      </div>
    </div>

  </transition>
</template>

<script>
import ControllerTitleBlock from "@/components/entities/ControllerTitleBlock.vue";
import SliderScrollHR from "@/components/entities/SliderScrollHR.vue";
import CardHR from "@/components/entities/CardHR.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import MainParagraph from "@/components/shared/CardText/MainParagraph.vue";
import ButtonWithIcon from "@/components/shared/ButtonWithIcon.vue";
import ModalResume from "@/components/entities/ModalResume.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";

export default {
  name: "HRScreen",
  components: {
    ButtonTitleMobile,
    ModalResume, ButtonWithIcon, MainParagraph, MainTitle, CardHR, SliderScrollHR, ControllerTitleBlock},
  data() {
    return {
      activeIndex: 0,
      activeModal: false,
      activeScreen: false,
      activeTitle: false,
      activeItem: -1
    }
  },
  mounted() {
    this.activeTitle = true
    setTimeout(() => {
      this.activeScreen = true
      this.showNextItem();
    }, 500)
  },
  computed: {
    activeInfo() {
      switch (this.activeIndex) {
        case 0:
          return this.$tm('HRCenterScreen.openInternshipsInfo')
        case 1:
          return this.$t('HRCenterScreen.emptyBlock')
      }
    }
  },
  methods: {

    routToHome() {
      this.$router.push({name: 'home-logo-page'});
    },
    changeActiveHR(id) {
      this.activeIndex = id
    },
    changeStateModal() {
      this.activeModal = !this.activeModal;
    },

    showNextItem() {
      if (this.activeItem < 4) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 350)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
.hr-screen-container {
  justify-content: flex-end;

  .hr-screen-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .top-info-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;

      .left-info-content {
        display: flex;
        flex-direction: column;

        .card-description-content {
          opacity: 0;
          transition:  opacity 1s ease;

          &.is-visible {
            opacity: 1;
          }
        }
      }

      .right-info-content {
        width: 100%;

        &.mobile {
          opacity: 0;
          transition: opacity 1s ease;

          &.is-visible {
            opacity: 1;
          }
        }
      }
    }

    .slider-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }


}
@media (max-width: 3024px){
.hr-screen-container {
  .top-info-content {
    max-height: 541px;
    padding: 0 180px;
    .left-info-content {
      row-gap: 48px;
    }

    .right-info-content {
      max-width: 560px;
    }
  }

  .slider-container {
    max-height: 687px;
    min-height: 687px;
  }
}

}


@media (max-width: 1920px) {
  .hr-screen-container {
    .hr-screen-content {
      .top-info-content {
        max-height: 340px;
        padding: 0 136px;
        .left-info-content {
          row-gap: 40px;
        }

        .right-info-content {
          max-width: 480px;
        }
      }

      .slider-container {
        max-height: 580px;
        min-height: 580px;
      }
    }

  }
}

@media (max-width: 1600px) {
  .hr-screen-container {

    .hr-screen-content {
      .top-info-content {
        max-height: 275px;
        padding: 0 80px;

        .left-info-content {
          row-gap: 32px;
        }

        .right-info-content {
          max-width: 340px;
        }
      }

      .slider-container {
        padding: 0 20px;
        max-height: 513px;
        min-height: 513px;
      }
    }
  }
}
@media (max-height: 800px) {
  .hr-screen-container {

    .hr-screen-content {
      .top-info-content {
        max-height: 232px;
        padding: 0 56px;

        .left-info-content {
          row-gap: 20px;
        }

        .right-info-content {
          max-width: 300px;
        }
      }

      .slider-container {
        padding: 0 8px;
        max-height: 404px;
        min-height: 404px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .hr-screen-container {

    .hr-screen-content {
      .top-info-content {
        max-height: 275px;
        padding: 0 80px;

        .left-info-content {
          row-gap: 32px;
        }

        .right-info-content {
          max-width: 340px;
        }
      }

      .slider-container {
        padding: 0 20px;
        max-height: 513px;
        min-height: 513px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .hr-screen-container {

    .hr-screen-content {
      .top-info-content {
        max-height: 232px;
        padding: 0 56px;

        .left-info-content {
          row-gap: 20px;
        }

        .right-info-content {
          max-width: 300px;
        }
      }

      .slider-container {
        padding: 0 8px;
        max-height: 404px;
        min-height: 404px;
      }
    }
  }
}
@media (max-width: 769px) {
  .hr-screen-container {
    --main-padding-bottom: 16px;
    .main-title-container {
      padding-left: var(--controller-padding);
    }

    .hr-screen-content {
      .top-info-content {
        padding: 8px var(--controller-padding);
        max-height: 67px;
      }

      .slider-container {
        --padding-bottom-slider: 16px;
        transition: padding .6s ease, opacity 1s ease;
        max-height: 416px;
        min-height: 416px;
        padding: 8px 0 var(--padding-bottom-slider) 14px;
        row-gap: 16px;
        opacity: 0;

        &.is-visible {
          opacity: 1;
        }

        &.active-card {
          padding: 8px 0 var(--padding-bottom-slider);

          .card-hr-container {
            margin: 0;
            width: 100%;
            border-width: 0;
            border-radius: 0;
          }
        }
      }

      .right-info-content {

        .sub-button-container {
          max-height: 46px;
          min-height: 46px;
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .hr-screen-container {
    .hr-screen-content {

      .slider-container {
        --padding-bottom-slider: 16px;
        max-height: 469px;
        min-height: 469px;
        padding: 8px 0 var(--padding-bottom-slider) 16px;
        row-gap: 16px;
      }

      .right-info-content {
        .sub-button-container {
          max-height: 46px;
          min-height: 46px;
        }
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .hr-screen-container {
    --main-padding-bottom: 8px;
    .hr-screen-content {

      .slider-container {
        --padding-bottom-slider: 14px;
        max-height: 350px;
        min-height: 350px;
        padding: 8px 0 var(--padding-bottom-slider) 14px;
        row-gap: 16px;
      }

      .right-info-content {
        .sub-button-container {
          max-height: 38px;
          min-height: 38px;
        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .hr-screen-container {
    --main-padding-bottom: 16px;
    .hr-screen-content {

      .slider-container {
        --padding-bottom-slider: 16px;
        max-height: 390px;
        min-height: 390px;
        padding: 8px 0 var(--padding-bottom-slider) 14px;
        row-gap: 16px;
      }

      .right-info-content {
        .sub-button-container {
          max-height: 46px;
          min-height: 46px;
        }
      }
    }
  }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .hr-screen-container {

    .hr-screen-content {
      .top-info-content {
        max-height: 1028px;
        padding: 0 288px;

        .left-info-content {
          row-gap: 84px;
        }

        .right-info-content {
          max-width: 900px;
        }
      }

      .slider-container {
        max-height: 1087px;
        min-height: 1087px;
      }
    }
  }
}

</style>