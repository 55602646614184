import { createStore } from 'vuex'

export default createStore({
  state: {
    activeColor: '-light',
    activeMainIndex: 0,
    activeSubIndex: 0,
    activeHomeIndex: -1,
    activeBlockSwiper: true,
    activeCookieModal: false,
    activeMobile: false,
    activeTouchAllow: false,
    activeIndexPage: -1,
    activeSlideController: false,
    activeModalConsulting: false,
    activeModalLogin: false,
    activeAnimationColor: false,

    lastRout: '',

    activeSlugTitle: ''
  },
  getters: {
    getActiveMobile: state => {
      return state.activeMobile;
    }
  },
  mutations: {
    SET_ACTIVE_MAIN_INDEX: (state, payload) => {
      state.activeMainIndex = payload
    },

    SET_LAST_ROUT: (state, payload) => {
      state.lastRout = payload
    },

    SET_IS_ACTIVE_MODAL_CONSULTING: (state, payload) => {
      state.activeModalConsulting = payload
    },

    SET_IS_ACTIVE_MODAL_LOGIN: (state, payload) => {
      state.activeModalLogin = payload
    },

    SET_ACTIVE_SLUG_TITLE: (state, payload) => {
      state.activeSlugTitle = payload
    },

    SET_ACTIVE_SUB_INDEX: (state, payload) => {
      state.activeSubIndex = payload
    },

    SET_ACTIVE_HOME_INDEX: (state, payload) => {
      state.activeHomeIndex = payload
    },

    SET_ACTIVE_INDEX_PAGE: (state, payload) => {
      state.activeIndexPage = payload
    },

    SET_ACTIVE_COLOR: (state, payload) => {
      state.activeColor = payload
    },

    SET_ACTIVE_COOKIE_MODAL: (state, payload) => {
      state.activeCookieModal = payload
    },
    SET_ACTIVE_MOBILE: (state, payload) => {
      state.activeMobile = payload
    },
    SET_ACTIVE_TOUCH_ALLOW: (state, payload) => {
      state.activeTouchAllow = payload
    },

    SET_ACTIVE_SLIDE_CONTROLLER: (state, payload) => {
      state.activeSlideController = payload
    },


    SET_ACTIVE_BLOCK_SWIPER: (state, payload) => {
      state.activeBlockSwiper = payload
    },

    SET_ACTIVE_ANIMATION_COLOR: (state, payload) => {
      state.activeAnimationColor = payload
    },
  },
  actions: {
    ACTIVE_MAIN_INDEX: (context, payload) => {
      context.commit('SET_ACTIVE_MAIN_INDEX', payload)
    },

    LAST_ROUT: (context, payload) => {
      context.commit('SET_LAST_ROUT', payload)
    },

    IS_ACTIVE_MODAL_CONSULTING: (context, payload) => {
      context.commit('SET_IS_ACTIVE_MODAL_CONSULTING', payload)
    },

    IS_ACTIVE_MODAL_LOGIN: (context, payload) => {
      context.commit('SET_IS_ACTIVE_MODAL_LOGIN', payload)
    },

    ACTIVE_SLUG_TITLE: (context, payload) => {
      context.commit('SET_ACTIVE_SLUG_TITLE', payload)
    },

    ACTIVE_SUB_INDEX: (context, payload) => {
      context.commit('SET_ACTIVE_SUB_INDEX', payload)
    },

    ACTIVE_HOME_INDEX: (context, payload) => {
      context.commit('SET_ACTIVE_HOME_INDEX', payload)
    },


    ACTIVE_INDEX_PAGE: (context, payload) => {
      context.commit('SET_ACTIVE_INDEX_PAGE', payload)
    },


    ACTIVE_SLIDE_CONTROLLER: (context, payload) => {
      context.commit('SET_ACTIVE_SLIDE_CONTROLLER', payload)
    },

    ACTIVE_COLOR: (context, payload) => {
      context.commit('SET_ACTIVE_COLOR', payload)
    },
    ACTIVE_COOKIE_MODAL: (context, payload) => {
      context.commit('SET_ACTIVE_COOKIE_MODAL', payload)
    },

    ACTIVE_MOBILE: (context, payload) => {
      context.commit('SET_ACTIVE_MOBILE', payload)
    },
    ACTIVE_TOUCH_ALLOW: (context, payload) => {
      context.commit('SET_ACTIVE_TOUCH_ALLOW', payload)
    },

    ACTIVE_ANIMATION_COLOR: (context, payload) => {
      context.commit('SET_ACTIVE_ANIMATION_COLOR', payload)
    },

    ACTIVE_BLOCK_SWIPER: (context, payload) => {
      context.commit('SET_ACTIVE_BLOCK_SWIPER', payload)
    },
  },
  modules: {
  }
})
