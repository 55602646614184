<template>
  <modal-frame-background>
    <modal-frame :active-title="$t('modalHotline.mainTitle')" @closeModalCross="closeModal">
      <sub-paragraph>
        {{ $t('modalHotline.mainParagraph') }}
        <span>
          <a :href="'mailto:' + mail">
              {{ mail }}
            </a>
        </span>
      </sub-paragraph>
      <input-main   @changeTextInput="changeTextInput"
                    :length-text-area="500"
                    :name-block="'frame-modal-container'"
                    :info="$tm('modalHotline.inputInfo')"/>
      <button-with-icon
          @click="submitInfo"
          :disabled-button="!isValid"
          :info-text="$t('modalHotline.submitInfo')"
          :info-icon="'message.svg'"/>
    </modal-frame>
  </modal-frame-background>
</template>

<script>
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import InputMain from "@/components/shared/InputMain.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import ButtonWithIcon from "@/components/shared/ButtonWithIcon.vue";
import ModalFrameBackground from "@/components/entities/ModalFrameBackground.vue";

export default {
  name: "ModalHotline",
  components: {ModalFrameBackground, ButtonWithIcon, ModalFrame, InputMain, SubParagraph},
  data() {
    return {
      resultInput: '',
      mail: 'report@visioners.tech'
    }
  },
  computed: {
    isValid() {
      return this.resultInput !== ''
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    changeTextInput(item, dataIndex) {
      this.resultInput = item
    },
    submitInfo() {
      if (this.isValid) {
        this.$emit('closeModal')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.frame-modal-container {

  .sub-paragraph-container {
    white-space: pre-wrap;
    text-align: justify;
  }
}

  @media (max-width: 3024px){
    .frame-modal-container {
      row-gap: 32px;
      max-width: 1085px;

      .sub-paragraph-container {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 1920px) {
    .frame-modal-container {
      row-gap: 24px;
      max-width: 890px;

      .sub-paragraph-container {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 1600px) {
    .frame-modal-container {
      row-gap: 16px;
      max-width: 680px;

      .sub-paragraph-container {
        font-size: 14px;
      }
    }
  }
  @media (max-height: 800px) {
    .frame-modal-container {
      row-gap: 12px;
      max-width: 520px;

      .sub-paragraph-container {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .frame-modal-container {
      row-gap: 16px;
      max-width: 680px;

      .sub-paragraph-container {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1133px) {
  }
  @media (max-width: 769px) {
    .frame-modal-container {
      height: 100%;
      border-width: 0;
      border-radius: 0;
      max-width: 100%;
      row-gap: 12px;

      .input-container {
        max-height: 284px;
        min-height: 225px;
      }

      .sub-paragraph-container {
        font-size: 12px;
        line-height: 120%;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .frame-modal-container {
      .input-container {
        max-height: 354px;
        min-height: 300px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .frame-modal-container {
      .input-container {
        max-height: 177px;
        min-height: 145px;
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .frame-modal-container {
      .input-container {
        max-height: 258px;
        min-height: 200px;
      }
    }
  }


  @media (max-width: 320px) {
    .frame-modal-container {
      .input-container {
        max-height: 150px;
        min-height: 125px;
      }

      .sub-paragraph-container {
        font-size: 9px;
      }
    }
  }

  @media (min-width: 3025px) {
    .frame-modal-container {
      row-gap: 40px;
      max-width: 1360px;
    }
  }

</style>