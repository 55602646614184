<template>
  <div class="button-agreement-container">
    <main-button :icon-active="'check-mark'"
                 :active-button="true"
                 :disabled-button="disabledButton"
                 @click="changeState"/>
    <sub-paragraph :class="{'eng' : activeEng}">
      {{ $t('agreementButton') }}
    </sub-paragraph>
  </div>
</template>

<script>
import MainButton from "@/components/shared/MainButton.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "ButtonAgreement",
  components: {SubParagraph, MainButton},
  data() {
    return {
      disabledButton: true
    }
  },
  methods: {
    changeState() {
      this.disabledButton = !this.disabledButton
      this.$emit('changeStateAgreementButton')
    }
  }
}
</script>

<style scoped lang="scss">
  .button-agreement-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 3024px){
    .button-agreement-container {
      column-gap: 48px;
    }
  }



  @media (max-width: 1920px) {
    .button-agreement-container {
      column-gap: 32px;
    }
  }

  @media (max-width: 1600px) {
    .button-agreement-container {
      column-gap: 24px;
    }
  }
  @media (max-height: 800px) {
    .button-agreement-container {
      column-gap: 16px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .button-agreement-container {
      column-gap: 24px;
    }
  }

  @media (max-width: 1133px) {
    .button-agreement-container {
      column-gap: 16px;
    }
  }
  @media (max-width: 769px) {
    .button-agreement-container {
      column-gap: 16px;

      .sub-paragraph-container {
        font-size: 10px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .button-agreement-container {
      column-gap: 16px;

      .sub-paragraph-container {
        font-size: 12px;
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .button-agreement-container {
      column-gap: 16px;

      .main-button-container {
        max-width: 28px;
        max-height: 28px;
      }

      .sub-paragraph-container {
        font-size: 10px;
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .button-agreement-container {
      column-gap: 16px;


      .sub-paragraph-container {
        font-size: 10px;
      }
    }
  }


  @media (max-width: 320px) {
    .button-agreement-container {
      column-gap: 12px;

      .sub-paragraph-container {
        font-size: 8px;
      }
    }
  }

  @media (min-width: 3025px) {
    .button-agreement-container {
      column-gap: 60px;
    }
  }

</style>