<template>
  <p class="main-paragraph-container">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainParagraph"
}
</script>

<style scoped lang="scss">

.main-paragraph-container {
  line-height: 150%;
  color: var(--color-main-text)
}

@media (max-width: 3024px){

  .main-paragraph-container {
    font-size: 22px;
  }
}

@media (max-width: 2240px) {
  .main-paragraph-container {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .main-paragraph-container {
    font-size: 14px;
  }
}
@media (max-height: 800px) {
  .main-paragraph-container {
    font-size: 12px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-paragraph-container {
    font-size: 14px;
  }
}

@media (max-width: 1133px) {
  .main-paragraph-container {
    font-size: 12px;
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  .main-paragraph-container {
    font-size: 10px;
  }
}

@media (min-width: 3025px) {
  .main-paragraph-container {
    font-size: 42px;
  }
}

</style>