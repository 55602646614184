<template>
  <modal-result :active-text="$t('resumeModal.resultText')"
                :active-result="activeResult"
                :active-title="$t('resumeModal.resultTitle')">
    <modal-frame :active-title="$t('resumeModal.mainTitle')" @closeModalCross="closeModal">
      <div class="top-info-container">
        <div class="top-info-content" v-for="item in $tm('resumeModal.inputInfo')">
          <sub-paragraph>
            {{ item.mainTitle }}
          </sub-paragraph>
          <input-main :info="item.inputInfo"
                      @changeTextInput="changeTextInput"
                      :name-block="'frame-modal-container'"/>
        </div>
      </div>

      <input-file active-input="file"
                  @changeActiveFile="changeActiveFile"/>

      <input-main :info="$tm('resumeModal.textareaInfo')"
                  @changeTextInput="changeTextInput"
                  :name-block="'frame-modal-container'"
                  :length-text-area="300"/>


      <button-agreement @changeStateAgreementButton="changeStateAgreementButton"/>
      
      <button-with-icon :info-icon="'message.svg'"
                        @click="submitInfo"
                        :disabled-button="!isValid"
                        :info-text="$t('submitButton')"/>
    </modal-frame>
  </modal-result>
</template>

<script>
import ModalResult from "@/components/entities/ModalResult.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import InputMain from "@/components/shared/InputMain.vue";
import InputFile from "@/components/shared/InputFile.vue";
import ButtonWithIcon from "@/components/shared/ButtonWithIcon.vue";
import ButtonAgreement from "@/components/shared/ButtonAgreement.vue";

export default {
  name: "ModalResume",
  components: {ButtonAgreement, ButtonWithIcon, InputFile, InputMain, SubParagraph, ModalFrame, ModalResult},
  data() {
    return {
      fileSrc: null,
      resultText: {
        name: '',
        email: '',
        message: ''
      },
      activeResult: false,
      stateAgreementButton: false

    }
  },
  computed: {
    isValid() {
      return  this.stateAgreementButton && this.fileSrc !== null && Object.values(this.resultText).every(r => r.length);
    }
  },
  methods: {

    changeStateAgreementButton() {
      this.stateAgreementButton = !this.stateAgreementButton
    },


    changeTextInput(item, dataIndex) {
      this.resultText[dataIndex] = item
    },
    changeActiveFile(item) {
      this.fileSrc = item
    },
    submitInfo() {
      if(this.isValid) {
        console.log('submit info')
        this.activeResult = true
      }
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped lang="scss">
.frame-modal-container {
  .top-info-container {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    width: 100%;
    justify-content: space-between;

    .top-info-content {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

@media (max-width: 3024px){
  .frame-modal-container {
    max-width: 1085px;
    .top-info-container {
      .top-info-content {
        row-gap: 11px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .frame-modal-container {
    max-width: 890px;
    .top-info-container {
      .top-info-content {
        row-gap: 9px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .frame-modal-container {
    max-width: 680px;
    .top-info-container {
      .top-info-content {
        row-gap: 7px;
      }
    }
  }
}
@media (max-height: 800px) {
  .frame-modal-container {
    max-width: 520px;
    .top-info-container {
      .top-info-content {
        row-gap: 6px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .frame-modal-container {
    max-width: 680px;
    .top-info-container {
      .top-info-content {
        row-gap: 7px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .frame-modal-container {
    max-width: 520px;
    .top-info-container {
      .top-info-content {
        row-gap: 6px;
      }
    }
  }
}
@media (max-width: 769px) {
  .frame-modal-container {
    border-radius: 0;
    border-width: 0;
    height: 100%;
    max-width: 100%;
    .top-info-container {
      flex-direction: column;
      row-gap: 12px;
      .top-info-content {
        row-gap: 6px;

        .sub-paragraph-container {
          font-size: 12px;
        }
      }
    }

    .input-container {
      &.textarea {
        max-height: 245px;
        min-height: 200px;
      }

    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .frame-modal-container {
    .top-info-container {
      row-gap: 12px;
      .top-info-content {
        row-gap: 6px;

        .sub-paragraph-container {
          font-size: 12px;
        }
      }
    }
    .input-container {
      &.textarea {
        max-height: 298px;
        min-height: 200px;
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .frame-modal-container {
    padding: 16px 14px;
    row-gap: 10px;
    height: 100%;
    max-width: 100%;
    .top-info-container {
      flex-direction: column;
      row-gap: 6px;
      .top-info-content {
        row-gap: 4px;

        .sub-paragraph-container {
          font-size: 12px;
        }
      }
    }

    .input-container {
      &.textarea {
        max-height: 167px;
        min-height: 145px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .frame-modal-container {
    height: 100%;
    max-width: 100%;
    .top-info-container {
      flex-direction: column;
      row-gap: 12px;
      .top-info-content {
        row-gap: 6px;

        .sub-paragraph-container {
          font-size: 12px;
        }
      }
    }

    .input-container {
      &.textarea {
        max-height: 220px;
        min-height: 200px;
      }
    }
  }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .frame-modal-container {
    max-width: 1360px;
    .top-info-container {
      .top-info-content {
        row-gap: 16px;
      }
    }
  }
}

</style>