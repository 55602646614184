<template>
  <div class="slider-scroll-container" :class="{'active-gradient' : activeIndex !== 0 && activeInfoLength > 4}">
    <icon-arrow class="swiper-button-top" v-if="!activeMobile"  direction="top"/>
    <swiper @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :modules="modules"
            :slidesPerView="'auto'"
            :space-between="5"
            class="swiper"
            :navigation = "{
              nextEl: '.swiper-button-bottom',
              prevEl: '.swiper-button-top'
            }"
            :breakpoints="
            {
              0: {
                allowTouchMove: true,
                scrollbar: {
                  hide: false,
                  enabled: true
                }

              },
              1600: {
                allowTouchMove: false,
                scrollbar: {
                  hide: false,
                  enabled: true
                }
              }
            }"
            :mousewheel="true"
            :autoHeight="true"
            :freeMode="true"
            :speed="1000"
            :scrollbar="{
                hide: false,
                enabled: true,
                draggable: true,
                dragClass: 'swiper-scrollbar-drag-custom'
             }"
            :allow-touch-move="false"
            direction="vertical">
      <slot/>
    </swiper>
    <icon-arrow class="swiper-button-bottom"  v-if="!activeMobile" direction="bottom"/>
  </div>
</template>

<script>
import {FreeMode, Mousewheel, Navigation, Scrollbar, Virtual} from "swiper";
import CardProject from "@/components/entities/CardProject.vue";
import IconArrow from "@/components/shared/IconArrow.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";

export default {
  name: "SliderScrollVertical",
  components: {MainTitle, IconArrow, CardProject},
  props: {
    activeInfoLength: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      scrollSwiper: '',
      activeIndex: 0,
      modules: [FreeMode, Scrollbar, Mousewheel, Virtual, Navigation]
    }
  },
  computed: {
    allowTouchMove() {
      return this.$store.state.activeTouchAllow;
    }
  },
  watch: {
    allowTouchMove() {
      if(this.allowTouchMove) {
        this.scrollSwiper.allowTouchMove = true
      } else {
        this.scrollSwiper.allowTouchMove = false
      }
    },
    activeInfoLength() {
      setTimeout(() => {
        this.scrollSwiper.slideTo(this.activeInfoLength)
        console.log(this.scrollSwiper)
      }, 100)
    }
  },
  methods: {
    onSwiperMain(swiper) {
      this.activeIndex = swiper.activeIndex;
    },

    changeSettingSwiper(swiper) {
      this.scrollSwiper = swiper
    },
  }
}
</script>

<style scoped lang="scss">


.swiper-slide {
  display: block !important;
}

.slider-scroll-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  position: relative;

  &.active-gradient {
    .swiper {
      &:before {
        opacity: .8;
      }
    }
  }


  .swiper {
    width: 100%;
    height: 100%;

    &:before {
      transition: opacity .6s ease;
      opacity: 0;
      visibility: visible;
      content: "";
      position: absolute;
      width: 100%;
      height: 30%;
      z-index: 3;
      top: 0;
      left: 0;
      pointer-events: none;
      background: linear-gradient(0deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 90%);
    }
  }


  .swiper-button-top {
    right: 0;
    z-index: 100;
    position: absolute;
    cursor: pointer;
  }

  .swiper-button-bottom {
    right: 0;
    z-index: 100;
    position: absolute;
    cursor: pointer;
    bottom: 0;
  }
}

@media (max-width: 3024px){
  .slider-scroll-container  {

    .swiper {
      padding-right: 114px;
    }

  }
}



@media (max-width: 1920px) {
  .slider-scroll-container {
    .swiper {
      padding-right: 86px;
    }
  }
}

@media (max-width: 1600px) {
  .slider-scroll-container {
    .swiper {
      padding-right: 66px;
    }
  }
}
@media (max-height: 800px) {
  .slider-scroll-container {
    .swiper {
      padding-right: 52px;
    }

    .swiper-button-bottom {
      bottom: 1%;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .slider-scroll-container {
    .swiper {
      padding-right: 66px;
    }
  }
}

@media (max-width: 1133px) {
  .slider-scroll-container {
    .swiper {
      padding-right: 52px;
    }

    .swiper-button-bottom {
      bottom: 1%;
    }
  }
}

@media (max-width: 769px) {
  .slider-scroll-container {
    .swiper {
      padding-right: 16px;
    }

    .swiper-button-bottom {
      bottom: 1%;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .slider-scroll-container {

    .swiper {
      padding-right: 128px;
    }
  }
}

</style>