<template>
  <div class="breadcrumbs-info-container" v-if="activeBreadcrumbLang" :class="{'eng' : activeEng}">
    <transition-group name="fade-opacity-lazy">
      <sub-paragraph v-for="(item, index) in activeBreadcrumb"
                     @click="routTo(item)"
                     :key="index">
          <span class="text-container"
                :class="{'clickable' : index !== activeBreadcrumb.length - 1, 'not-clickable': !item?.activeClick}">
           <transition name="fade-opacity-lazy" mode="out-in">
            <span :key="item">
               {{ item.text }}
            </span>
             </transition>
          </span>
          <span class="href-container"
                :class="{'active-href' : activeBreadcrumb.length !== 1 && index !== activeBreadcrumb.length - 1}">
             \
          </span>
      </sub-paragraph>
    </transition-group>

  </div>
</template>

<script>
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import {mapActions} from "vuex";

export default {
  name: "Breadcrumbs",
  components: {SubParagraph},
  data() {
    return {
      // activeBreadcrumb: [this.$t('breadCrumb.staticTitle')]
      activeBreadcrumb: [
        {
          text:  this.$t('breadCrumb.staticTitle'),
          link: 'home-navigation-page',
          activeClick: true
        }
      ],
      activeBreadcrumbLang: true
    }
  },
  mounted() {
    this.checkActiveRoute()
  },

  computed: {
    infoBreadCrumb() {
      return this.$tm('breadCrumb.dynamicTitle')
    },

    activeRouteMetaBreadCrumb() {
      return this.$route.meta?.breadCrumbParam;
    },

    activeRouteProps() {
      return this.$route.params;
    },

    activeBreadCrumbType() {
      return this.activeRouteMetaBreadCrumb?.type;
    },

    activeSlugTitle() {
      return this.$store.state.activeSlugTitle;
    },


    activeIndexPage() {
      return this.$store.state.activeIndexPage;
    },

    typeProps() {
      return this.activeRouteProps[this.activeRouteMetaBreadCrumb?.typeProps]
    },

    breadCrumbDynamicTitle() {
      return this.infoBreadCrumb[this.activeBreadCrumbType]?.dynamicTitle[this.typeProps]
    }

  },

  watch: {
    '$route'() {
      this.checkActiveRoute();
      this.spliceBreadCrumb();
    },

    activeEng() {
      this.activeBreadcrumbLang = false
      this.activeBreadcrumb = [
        {
          text:  this.$t('breadCrumb.staticTitle'),
          link: 'home-page',
          activeClick: true
        }
      ]
      this.checkActiveRoute();
      setTimeout(() => {
        this.activeBreadcrumbLang = true
      }, 500)
      // this.spliceBreadCrumb();
    }
  },
  methods: {
    ...mapActions(['ACTIVE_INDEX_PAGE']),
    checkActiveRoute() {
      if(this.$route.name.includes('type-division')) {
        //Вариации для страницы дивизионов
        this.checkDivisionRoute()
      } else {
        //Проверка на то, что вторая это страница или нет, а затем, если она отличатеся, заменить вторую статическую крошку.
        if(this.activeIndexPage === 1) {
          let staticTitleSecond = {
            text: this.$tm('breadCrumb.staticTitleSecond'),
            link: false,
            activeClick: false
          }
          if(staticTitleSecond?.text !== this.activeBreadcrumb[1]?.text) {
            this.activeBreadcrumb[1] = staticTitleSecond
          }
        }

        //Активна первая изменяемая хлебная крошка

        let staticTitle = {
          text: this.infoBreadCrumb[this.activeBreadCrumbType]?.staticTitle,
          link: this.activeRouteMetaBreadCrumb?.linkStatic,
          activeClick: this.activeRouteMetaBreadCrumb?.activeClick
        }

        if(staticTitle?.text !== this.activeBreadcrumb[this.activeIndexPage + 1]?.text) {
          this.activeBreadcrumb[this.activeIndexPage + 1] = staticTitle
        }

        //Если активны пропсы

        if(this.activeRouteMetaBreadCrumb?.activeProps && this.typeProps !== undefined) {
          //Если крошек больше 2, то добавляет без времени
          if(this.activeBreadcrumb.length > 2) {
            this.changeDynamicBreadCrumb();
          } else {
            setTimeout(() => {
              this.changeDynamicBreadCrumb();
            }, 1000)
          }

        }
      }
    },


    routTo(item) {
      if(item.link !== undefined) {
        this.$router.push({name: item.link})
      }
    },


    spliceBreadCrumb() {
      let numSplice = this.activeRouteMetaBreadCrumb.activeLength - this.activeBreadcrumb.length
      if(numSplice < 0){
        this.activeBreadcrumb.splice(numSplice)
      }
    },

    changeDynamicBreadCrumb() {
      this.activeBreadcrumb[this.activeIndexPage + 2] =
          {
            text: this.breadCrumbDynamicTitle,
            link: this.activeRouteMetaBreadCrumb?.linkProps,
            activeClick: false
          }
    },

    checkDivisionService() {
      this.$nextTick(() => {
        if(this.activeBreadcrumb[3] === undefined) {
          this.activeBreadcrumb[3] = {
            text: this.infoBreadCrumb[this.activeBreadCrumbType]?.servicesTitle + ' ' + this.activeSlugTitle,
            link: false,
            activeClick: false
          }
        }
        setTimeout(() => {
          this.activeBreadcrumb[4] = {
            text: this.activeSlugTitle,
            link: false,
            activeClick: false
          }
        }, 500)
      })
    },

    checkDivisionTechnologies() {
      if(this.activeBreadcrumb[3] === undefined) {
        this.activeBreadcrumb[3] = {
          text: this.infoBreadCrumb[this.activeBreadCrumbType]?.technologiesTitle,
          link: false,
          activeClick: false
        }
      }
      this.$nextTick(() => {
        this.activeBreadcrumb[4] = {
          text: this.activeSlugTitle,
          link: false,
          activeClick: false
        }
      })
    },


    checkDivisionRoute() {
      let activeTechnologies = this.$route.name.includes('technologies')
      let activeServices = this.$route.name.includes('services')

      let staticTitle = {
        text: this.infoBreadCrumb[this.activeBreadCrumbType]?.staticTitle,
        link: 'main-division-page',
        activeClick: true
      }
      //Проверка, есть ли при монтировании или изменение графа с Дивизионами, если есть, то ставим, если нет заменяем параметры кликов и направление
       if(this.activeBreadcrumb[1] === undefined) {
         this.activeBreadcrumb[1] = staticTitle
       } else {
         this.activeBreadcrumb[1].link = 'main-division-page';
         this.activeBreadcrumb[1].activeClick = true
       }

      //Проверка, типа дивизиона
      if(this.activeBreadcrumb[2] === undefined) {
        this.activeBreadcrumb[2] = {
          text:  this.breadCrumbDynamicTitle,
          link: false,
          activeClick: false
        }
      }

      if(activeTechnologies || activeServices) {
        this.activeBreadcrumb[2].link = 'main-type-division-page';
        this.activeBreadcrumb[2].activeClick = true

        if(activeTechnologies) {
          this.checkDivisionTechnologies();
        }
        if(activeServices) {
            this.checkDivisionService();
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumbs-info-container {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  z-index: 899;
  position: absolute;
  left: calc(var(--controller-padding) + var(--breadcrumb-padding));
  top: calc(var(--main-padding-top) / 2);
  --breadcrumb-padding: 160px;


  .sub-paragraph-container {
    display: flex;
    position: relative;
    transition: all .6s ease;
    color: var(--color-main-title);

    .href-container {
      opacity: 0;
      transition: opacity .6s ease;

      &.active-href {
        opacity: .3;
      }
    }

    .text-container {
      transition: opacity .6s ease;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      opacity: .3;
      &.not-clickable {
        &:hover {
          opacity: .3 !important;
          cursor: text !important;
        }

        &:last-child {
          opacity: 1 !important;
          &:hover {
            opacity: 1 !important;
          }
        }
      }

      &.clickable {
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    & span {
      position: relative;
    }


    &:last-child {
      .text-container {
        opacity: 1;
        color: var(--color-main-visioners);

        &.not-clickable {
          opacity: 1 !important;

          &:hover {
            opacity: 1 !important;
          }
        }
      }
    }

    &:first-child {
      .text-container {
        color: var(--color-main-title) !important;
      }
    }

  }
}

@media (max-width: 3840px){
  .breadcrumbs-info-container {
    column-gap: 4px;
    --breadcrumb-padding: 160px;

    .sub-paragraph-container {
      .text-container {
        max-width: 450px;
      }
    }
  }
}

@media (max-width: 3024px){
  .breadcrumbs-info-container {
    column-gap: 4px;
    --breadcrumb-padding: 64px;

    .sub-paragraph-container {
      .text-container {
        max-width: 350px;
      }
    }
  }
}

@media (max-width: 2240px){
  .breadcrumbs-info-container {
    .sub-paragraph-container {
      .text-container {
        max-width: 320px;
      }
    }
  }
}



@media (max-width: 1920px) {
  .breadcrumbs-info-container {
    column-gap: 4px;
    --breadcrumb-padding: 48px;

    .sub-paragraph-container {
      .text-container {
        max-width: 300px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .breadcrumbs-info-container {
    column-gap: 2px;
    --breadcrumb-padding: 32px;

    .sub-paragraph-container {
      .text-container {
        max-width: 170px;
      }
    }
  }
}
@media (max-height: 800px) {
  .breadcrumbs-info-container {
    column-gap: 2px;
    --breadcrumb-padding: 24px;
    .sub-paragraph-container {
      .text-container {
        max-width: 125px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .breadcrumbs-info-container {
    column-gap: 2px;
    --breadcrumb-padding: 32px;
    .sub-paragraph-container {
      .text-container {
        max-width: 150px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .breadcrumbs-info-container {
    column-gap: 2px;
    --breadcrumb-padding: 24px;
    .sub-paragraph-container {
      .text-container {
        max-width: 125px;
      }
    }
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3841px) {
  .breadcrumbs-info-container {
    column-gap: 4px;
    --breadcrumb-padding: 160px;

    .sub-paragraph-container {
      .text-container {
        max-width: 750px;
      }
    }
  }
}

</style>